import { useState } from 'react';
import DataGridUI from '../UI/DataGridUI';
import copy from 'copy-to-clipboard';
import Checkbox from '@mui/material/Checkbox';

const SmartSheetGrid = (props) => {
    const [cellCopied, setCellCopied] = useState([]);


    const cellClickedHandler = (cellDetails) => {

        setCellCopied((prevState) => {
            const i = prevState.findIndex((row) => {
                return row.rowId === cellDetails.rowId && row.field === cellDetails.field;
            });
            if (i > -1)
                prevState.splice(i, 1);
            else
                prevState.push(cellDetails);
            return prevState;
        });
        let textToCopy = cellCopied.map((item) => {
            return item.value;
        }).join(",");

        copy(textToCopy);
        props.reloadGrid();
    }


    const removeCellSelection = () => {
        //setCellCopied([]);
        props.reloadGrid();
    }
    const columns = [



        {
            field: 'orderDate',
            headerName: 'Order Date',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'fromEmail',
            headerName: 'From Email',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'customerEmail',
            headerName: 'Customer Email',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.15,
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'emailSubject',
            headerName: 'Email Subject',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
           
        },
        {
            field: 'orderType',
            headerName: 'Order Type',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
            renderCell: (params) => {
               return params.row.orderType;
            },
            renderEditCell: (params) => {
                let orderType = props.orderType;
                if (props.orderType.length < 1) {
                    orderType = params.row.orderType;
                }
                const orderTypeSelectionChanged = (event) => {
                    //console.log(event.target.value);
                    props.onOrderTypeSelectionChange(params.id, event.target.value);
                }

                return <div>
                    <select onChange={orderTypeSelectionChanged} defaultValue={orderType}  >
                        <option value="" ></option>
                        <option value="Link Insert"  >Link Insert</option>
                        <option value="New Post"  >New Post</option>
                        <option value="Post Fix"  >Post Fix</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            },
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'postTitle',
            headerName: 'Post Title',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.2,
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'orderLink',
            headerName: 'Order Link',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.15,
            renderCell: (params) => {
                const divId = params.id + '_orderLink';
                return <div id={divId}> {params.row.orderLink}</div>;
            }
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'orderNotes',
            headerName: 'Notes',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.2,
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'sent',
            headerName: 'Sent',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.01,
            renderCell: (params) => {
                return <div>
                    {params.row.sent == true || params.row.sent == 1?
                        <input key="" type="checkbox" checked disabled /> : <input type="checkbox" disabled />
                    }

                </div>;
            },
            renderEditCell: (params) => {
                let isChecked = false;

                if (props.sentChecked === 2)
                    isChecked = params.row.sent;
                else
                    isChecked = props.sentChecked;

                const markAsSent = (e) => {
                    props.onMarkAsSend(params.id);
                }
                const id = params.row.id + "_sent";
                return <div>
                    {
                        // <input id={id} type="checkbox" checked={isChecked === 1 ? true : false } onChange={markAsSent} /> 
                        <Checkbox
                            checked={isChecked === 1 ? true : false}
                            onChange={markAsSent}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }

                </div>;
            },
            /*cellClassName: (params) => {
                let i = cellCopied.findIndex((item) => {
                    return params.row.id === item.rowId && params.field === item.field;
                });
                return i > -1 ? "cell_selected" : "";
            }*/
        },
        {
            field: 'pp',
            headerName: 'PP',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.01,
            renderCell: (params) => {
                
                return <div>
                    {params.row.pp == "true" || params.row.pp == 1 ?
                        <input type="checkbox" checked disabled /> : <input type="checkbox" disabled />
                    }

                </div>;
            },
            renderEditCell: (params) => {
                let isChecked = false;

                if (props.ppChecked === 2)
                    isChecked = params.row.pp;
                else
                    isChecked = props.ppChecked;

                const markAsPP = (e) => {
                    props.onMarkAsPP(params.id);
                }

                return <div>
                    {
                        //<input type="checkbox" checked={isChecked == 1 ? true : false} onChange={markAsPP} />
                        <Checkbox
                            checked={isChecked === 1 ? true : false}
                            onChange={markAsPP}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }

                </div>;
            },

        },
        {
            field: 'paid',
            headerName: 'Paid',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.01,
            renderCell: (params) => {


                return <div>
                    {params.row.paid == true ?
                        <input type="checkbox" checked disabled /> : <input type="checkbox" disabled />
                    }

                </div>;
            },
            renderEditCell: (params) => {
                let isChecked = false;
                isChecked = props.paidChecked === 2 ? params.row.paid : props.paidChecked

                const markAsPaid = (e) => {
                    props.onMarkAsPaid(params.id);
                }

                return <div>
                    {
                        //<input type="checkbox" checked={isChecked == 1 ? true : false} onChange={markAsPaid} /> 
                        <Checkbox
                            checked={isChecked === 1 ? true : false}
                            onChange={markAsPaid}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }

                </div>;
            },
          
        },
        {
            field: 'followUpStatus',
            headerName: 'Follow Up Status',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
            renderCell: (params) => {
                return params.followUpStatus;
            },
            renderEditCell: (params) => {
                let followUpStatus = props.followUpStatus;

                if (props.followUpStatus.length < 1) {
                    followUpStatus = params.row.followUpStatus;
                }
                const followUpStatusChange = (event) => {
                    //console.log(event.target.value);
                    props.onFollowUpStatusChange(params.id, event.target.value)
                }
                return <div>
                    <select onChange={followUpStatusChange} defaultValue={followUpStatus} >
                        <option value=""></option>
                        <option value="First Follow Up"  >First Follow Up</option>
                        <option value="Second Follow Up"  >Second Follow Up</option>
                        <option value="Warned">Warned</option>
                    </select>
                </div>
            },
            
        },
        {
            field: 'indexing',
            headerName: 'Indexing',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.05,
           
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
          
        },

         

    ];

    const onRowSelectionChanged = (ids) => {

        props.RowSelectionIds(ids);
    }

    const onHandleEdit = (row) => {
        props.onGridRowEdit(row);
    }

    const handleNewClick = () => {
        props.onNewItemClick();
    }
    return (
        <DataGridUI columns={columns} data={props.orderData} onRowSelection={onRowSelectionChanged} onHandleEditClick={onHandleEdit} handleNewClick={handleNewClick} handleCellClick={cellClickedHandler} removeCellSelection={removeCellSelection} reloadGrid={removeCellSelection} />
    );
}


export default SmartSheetGrid;
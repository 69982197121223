import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import SimpleGrid from '../../UI/SimpleGrid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useEffect } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 150,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const ImageGrid = (props) => {
    const [viewImage, setViewImage] = React.useState(false);
    const [imageURL, setImageURL] = React.useState(false);
 
    const columns = [
        {
            field: 'url',
            headerName: 'URL',
            headerClassName: 'DataGridHeader',
            flex: .8,
            renderHeader: (params) => {
                const custom = props.custom_image;
                let header;
                if (custom == 1) {

                    header = < React.Fragment > URL &nbsp;&nbsp; <span style={{ 'float': 'right' }}>  Create Custom Feature Image <CheckBoxIcon  ></CheckBoxIcon></span ></React.Fragment >;
                }
                else
                    header = < React.Fragment > URL &nbsp;&nbsp; <span style={{ 'float': 'right' }}>  Create Custom Feature Image <CheckBoxOutlineBlankIcon  ></CheckBoxOutlineBlankIcon></span ></React.Fragment >;
                return (
                    header
                    
                );
            },
            renderCell: (params) => {
                let url = params.row.url;
                url = url.replace("http://", "");
                url = url.replace("https://", "");
                //url = url.replace("www.", "");
                
                return (
                    
                    <React.Fragment>
                        
                        {params.row.notes.trim().length > 0 &&
                            <p data-html={true} data-tip={ReactDOMServer.renderToString(<div >{params.row.notes}</div>)}>
                                <InfoIcon>
                                </InfoIcon>
                            </p>}
                        <ReactTooltip place="right" className="tooltip" multiline={true} />
                        
                        {url/*url.slice(0, 20)}---{url.slice(url.length - 30, url.length) */}
                        
                        
                        
                    </React.Fragment>
                   
                );
            },
        },
        {
            field: 'featured',
            headerName: 'featured',
            headerClassName: 'DataGridHeader',
            flex: .1,
            renderCell: (params) => {
                //return params.row.featured == 1 ? "Yes" : "No";
                const markAsFeatured = () => {
                    props.changeFeatured(params.row.id);

                }

                
                return (<div>
                    
                    <input type="checkbox" onChange={markAsFeatured} checked={params.row.featured == 1 ? true : false} /> 
                    

                </div>);
            },
        },
        {
            field: 'updated_user',
            headerName: 'Updated By',
            headerClassName: 'DataGridHeader',
            flex: .1
        },
        {
            field: "action",
            headerName: "Actions",
            headerClassName: 'DataGridHeader',
            sortable: false,
            flex: .2,
            renderCell: (params) => {

                const onRemoveHandler = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();
                    props.removeImage(params.id);
                    return "";
                };

                /*
                 * <HtmlTooltip
                        title={
                            <React.Fragment>

                                <iframe src={params.row.url} width="500" height="400" style={{ float: 'right' }} ></iframe>
                                
                            </React.Fragment>
                        }
                    >
                        <Button><PreviewIcon ></PreviewIcon></Button>
                    </HtmlTooltip>
                 */
                return <div>
                  
                    <Tooltip title="Open" placement="top-start">
                        <a href={params.row.url} target="_blank"> <FileOpenIcon className="cursor" /></a>
                    </Tooltip>
                    <Tooltip title="Remove" placement="top-start">
                        <IconButton onClick={onRemoveHandler}>
                            <DeleteOutlineIcon className="cursor" />
                        </IconButton>
                    </Tooltip>
                    
                    

                </div>;
            },

        },
         
    ];

    const onClosePopUp = () => {
        setViewImage(false);
    }

    return (
        <>
            <SimpleGrid columns={columns} data={props.data} showPaging="0" />
            
        </>
    );
}

export default ImageGrid;
import { useEffect, useState } from "react";
import SimpleGrid from '../../UI/SimpleGrid';
import { ReactSession } from 'react-client-session';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const PostStatusReport = (props) => {

    const [report, setReport] = useState([]);
    const [FilteredReport, setFilteredReport] = useState([]);
    const roleId = ReactSession.get("RoleID");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isContentWritter, setIsContentWritter] = useState(false);
    const [isImageUploader, setIsImageUploader] = useState(false);
    const [viewSelected, setViewSelected] = useState(3);
    const [statusFilter, setStatusFilter] = useState([
        {
            label: 'All',
            value: 'all',
            type: '0'
        },
    ]);
    const statusAll = [
        {
            label: 'All',
            value: 'all',
            type: '0'
        },
    ];
    const [statusList, setStatusList] = useState([
        {
            label: 'All',
            value: 'all',
            type: '0'
        },
        
    ]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        siteName: true,
        existingNeedsEdited: true,
        existingContentRejected: true,
        existingNeedsReviewed: true,
        existingNeedsImages: true,
        existingNeedsImagesApproved:true,
        draftNeedsEdited: true,
        draftContentRejected: true,
        draftNeedsReviewed:true,
        draftNeedsImages: true,
        draftNeedsImagesApproved:true,
        linksNeedsEdit: true,
        linksContentRejected: true,
        linksContentRejectedEdit: true,
        linksNeedsReviewed: true,
        linksNeedImages: true,
        linksNeedsImagesApproved:true,
        needsImages: true,
        imagesSelected: true,
        approved: true,
        rejected: true,
        published:true
    });


    const Statuses = [
        {
            label: 'Needs Edited',
            value: 'existingNeedsEdited',
            type: '2'
        },
        {
            label: 'Content Rejected',
            value: 'existingContentRejected',
            type: '2'
        },
        {
            label: 'Needs Reviewed',
            value: 'existingNeedsReviewed',
            type: '2'
        },
        {
            label: 'Needs Images',
            value: 'existingNeedsImages',
            type: '2'
        },


        {
            label: 'Needs Edited',
            value: 'linksNeedsEdit',
            type: '1'
        },
        {
            label: 'Content Rejected',
            value: 'linksContentRejected',
            type: '1'
        },
        {
            label: 'Content Reject Edited',
            value: 'linksContentRejectedEdit',
            type: '1'
        },
        {
            label: 'Needs Reviewed',
            value: 'linksNeedsReviewed',
            type: '1'
        },
        {
            label: 'Needs Images',
            value: 'linksNeedImages',
            type: '1'
        },

        {
            label: 'Needs Images',
            value: 'needsImages',
            type: '3'
        },
        {
            label: 'Image Approval Pending',
            value: 'imagesSelected',
            type: '3'
        },
        {
            label: 'Rejected',
            value: 'rejected',
            type: '3'
        },
        {
            label: 'Approved',
            value: 'approved',
            type: '3'
        },
        {
            label: 'Content Posted',
            value: 'published',
            type: '3'
        },


    ];
    useEffect(() => {
        console.log(roleId);
        setCoulmnVisibility();
        fetchReport();
        
    }, []);

    useEffect(() => {
        filterReport('all');
    }, [report]);

    useEffect(() => {
        setColumnVisibilityModel({
            siteName: true,
            existingNeedsEdited: isContentWritter && viewSelected == 2,
            existingContentRejected: isContentWritter && viewSelected == 2,
            existingNeedsReviewed: isContentWritter && viewSelected == 2,
            existingNeedsImages: isContentWritter && viewSelected == 2,
            existingNeedsImagesApproved: isContentWritter && viewSelected == 2,
            draftNeedsEdited: isContentWritter && viewSelected == 2,
            draftContentRejected: isContentWritter && viewSelected == 2,
            draftNeedsReviewed: isContentWritter && viewSelected == 2,
            draftNeedsImages: isContentWritter && viewSelected == 2,
            draftNeedsImagesApproved: isContentWritter && viewSelected == 2,
            linksNeedsEdit: isContentWritter && viewSelected == 1,
            linksContentRejected: isContentWritter && viewSelected == 1,
            linksContentRejectedEdit: isContentWritter && viewSelected == 1,
            linksNeedsReviewed: isContentWritter && viewSelected == 1,
            linksNeedImages: isContentWritter && viewSelected == 1,
            linksNeedsImagesApproved: isContentWritter && viewSelected == 1,
            needsImages: isImageUploader && viewSelected == 3,
            imagesSelected: isImageUploader && viewSelected == 3,
            approved: isImageUploader && viewSelected == 3,
            rejected: isImageUploader && viewSelected == 3,
            published: isImageUploader && viewSelected == 3
            
        });

    }, [isAdmin, isContentWritter, isImageUploader, viewSelected]);

    const setCoulmnVisibility = () => {
        if (roleId == 1) {
            setIsAdmin(true);
            setIsContentWritter(true);
            setIsImageUploader(true);
        }
        if (roleId == 6) {
            setIsAdmin(false);
            setIsContentWritter(true);
            setIsImageUploader(false);
        }
        if (roleId == 4) {
            setIsAdmin(false);
            setIsContentWritter(false);
            setIsImageUploader(true);
        }

       
    }
    async function fetchReport() {
        let response = await fetch('post/getpoststatus');
        let data = await response.json();
        
        setReport(data);
    } 

    const filterReport = (filterValue) => {
        if (filterValue.includes("all")) {
            setFilteredReport(prevState => { return report });
        }
        else {
            const filteredReport = report.filter(item => {
                console.log(filterValue);
                console.log(item[filterValue]);
                return Number(item[filterValue]) > 0
            });
            setFilteredReport(prevState => { return filteredReport });
            console.log(filteredReport);
        }
    }
    const columns = [
        {
            field: 'siteName',
            headerName: 'Site',
            headerClassName: 'DataGridHeader',
            flex: .5,
            hide: true,
            
        },
        {
            field: 'existingNeedsEdited',
            headerName: 'Needs Edited',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'existingContentRejected',
            headerName: 'Content Rejected',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'existingNeedsReviewed',
            headerName: 'Needs Reviewed',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'existingNeedsImages',
            headerName: 'Needs Images',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'existingNeedsImagesApproved',
            headerName: 'Needs Images Approved',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'draftNeedsEdited',
            headerName: 'Needs Edited',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'draftContentRejected',
            headerName: 'Content Rejected',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'draftNeedsReviewed',
            headerName: 'Needs Reviewed',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'draftNeedsImages',
            headerName: 'Needs Images',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'draftNeedsImagesApproved',
            headerName: 'Needs Images Approved',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'linksNeedsEdit',
            headerName: 'Needs Edited',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'linksContentRejected',
            headerName: 'Content Rejected',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'linksContentRejectedEdit',
            headerName: 'Content Reject Edited',
            headerClassName: 'DataGridHeader',
            flex: .2,
        },
        {
            field: 'linksNeedsReviewed',
            headerName: 'Needs Reviewed',
            headerClassName: 'DataGridHeader',
            flex: .2,
        }, 
        {
            field: 'linksNeedImages',
            headerName: 'Needs Images',
            headerClassName: 'DataGridHeader',
            flex: .2,
        }, 
        {
            field: 'linksNeedsImagesApproved',
            headerName: 'Needs Images Approved',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'needsImages',
            headerName: 'Needs Images',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        
        {
            field: 'imagesSelected',
            headerName: 'Image Approval Pending',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'approved',
            headerName: 'Approved',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'rejected',
            headerName: 'Rejected',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
        {
            field: 'published',
            headerName: 'Content Posted',
            headerClassName: 'DataGridHeader',
            flex: .2,

        },
   
     
    ];

    const HeaderWithIconRoot = styled('div')(({ theme }) => ({
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        '& span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: theme.spacing(0.5),
        },
    }));

    function HeaderWithIcon(props) {
        const { icon, ...params } = props;

        return (
            <HeaderWithIconRoot>
                <span>{params.headerName ?? params.groupId}</span> 
            </HeaderWithIconRoot>
        );
    }

    const columnGroupingModel = [
     
        {
            groupId: 'Existing Posts',
            headerName: 'Existing Posts',
            description: '',
            headerClassName: 'group1',
            children: [{ field: 'existingNeedsEdited' }, { field: 'existingContentRejected' }, { field: 'existingNeedsReviewed' }, { field: 'existingNeedsImages' }, { field: 'existingNeedsImagesApproved' }],
        },
        {
            groupId: 'Draft Posts',
            headerName: 'Draft Posts',
            description: '',
            headerClassName: 'group2',
            children: [{ field: 'draftNeedsEdited' }, { field: 'draftContentRejected' }, { field: 'draftNeedsReviewed' }, { field: 'draftNeedsImages' }, { field: 'draftNeedsImagesApproved' }], 
        },
        {
            groupId: 'New Content',
            headerName: 'New Content',
            description: '',
            headerClassName: 'group3',
            children: [{ field: 'linksNeedsEdit' }, { field: 'linksContentRejected' }, { field: 'linksContentRejectedEdit' }, { field: 'linksNeedsReviewed' }, { field: 'linksNeedImages' }, { field: 'linksNeedsImagesApproved' }],
        },
        {
            groupId: 'Image Finding',
            headerName: 'Image Finding',
            headerClassName: 'group4',
            description: '',

            children: [{ field: 'needsImages' }, { field: 'imagesSelected' }, { field: 'approved' }, { field: 'rejected' }, { field: 'published' }],
        },
        
    ];

    const setViewForPage = (event) => {
        setViewSelected(prevState => {
            return event.target.value;
        });
        let newStatusList = Statuses.filter(item => item.type == event.target.value);
        setStatusList(prevState => { return statusAll.concat( newStatusList )});
        //setStatusFilter(newStatusList[0]);
        
     
    }

    const statusSelectionHandler = (e) => {
        
        let status = statusList.filter(item => item.value.includes(e.target.value));
        
        setStatusFilter(status);
        filterReport(status[0].value);
        
    }
    return (
        <>
            <div className="row">
                <div className="col">
                    <b>Select View :</b> {(roleId == 1 || roleId == 6 ) && <><input type="radio" name="viewOption" value="1" onClick={setViewForPage} ></input> Google Docs
                    &nbsp;&nbsp;&nbsp;
                        <input type="radio" name="viewOption" value="2" onClick={setViewForPage} ></input> Existing Posts &nbsp;&nbsp;&nbsp;</>}
                    {(roleId == 1 ) && <><input type="radio" name="viewOption" value="3" onClick={setViewForPage} ></input> Image Finding</>}
                </div>
                <div className="col">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={statusFilter[0].value}
                        label=""
                        onChange={statusSelectionHandler}
                        sx={{ width: 300 }}
                        
                    >
                        {statusList.map((item, index) => (
                            <MenuItem value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <SimpleGrid columns={columns} data={FilteredReport} columnVisibilityModel={columnVisibilityModel} columnGroupingModel={columnGroupingModel} />
        </>
    );
}


export default PostStatusReport;
import { useState } from 'react';
import '../UI/styles.css';
import * as React from 'react';
import Card from '../UI/Card';

const SearchFilter = (props) => {
    const [searchInput, setSearchInput] = useState('');
    const [searchFilter, setSearchFiler] = useState('All');
    const [bulkOption, setBulkOption] = useState('none');
    const searchChangeHandler = (event) => {
        setSearchInput(event.target.value);
        props.onFilteredList(event.target.value, searchFilter);
    }

    const searchFilterChangeHandler = (event) => {
        setSearchFiler(event.target.value);
        props.onFilteredList(searchInput, event.target.value);
    }

    const bulkOptionHandler = (event) => {
        setBulkOption(event.target.value);
        
    }

    const formSubmitButtonHandler = (e) => {
        e.preventDefault();
        props.onBulkOptionSubmit(bulkOption);
    }
    
    return (
        <React.Fragment>
            <form onSubmit={formSubmitButtonHandler}>
                    <div className="row mx-md-n5">
                        <div className="col-8"></div>
                        <div className="col-3">
                            <div >

                                <select onChange={bulkOptionHandler} className="form-select">
                                    <option value="none">Select Action</option>
                                    <option value="spam">Mart Selected as Spam</option>
                                    <option value="deleteSelected">Delete Selected</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-1">
                            <button className="btn btn-primary">Go</button>

                        </div>
                    </div>
                    <div className="row" style={{ display: 'none' }} >
                        <div className="col">
                            <label htmlFor="dateFrom">From</label>
                            <input className="form-control" name="dateFrom" type="Date"></input>
                        </div>
                        <div className="col">
                            <label htmlFor="dateFrom">To</label>
                            <input className="form-control" name="dateTo" type="Date"></input>
                        </div>



                    </div>
                    <br></br>

            </form>
          
            <Card>
                <form>
                    <div className="row mx-md-n5">
                        <div className="col-4">
                            <div className="form-group">
                            <label htmlFor="" >Search</label>
                                <input className="form-control" onChange={ searchChangeHandler} name="searchBy" placeholder="Search By Email, Name, Subject" ></input>
                                </div>
                        </div>
                  
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="" >Filter by</label>
                        
                                <select onChange={searchFilterChangeHandler} className="form-select">
                                    <option value="Not Replied">Not Replied</option>
                                    <option value="All">All</option>
                                    <option value="Replied">Replied</option>
                                    <option value="Spam">Spam</option>
                            
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </Card>
            
        </React.Fragment>
        );
}

export default SearchFilter;
import { useState, useEffect } from "react";
import DraftPostColumns from '../DraftPosts/DraftPostColumns';
import EditPostContent from "../ContentEditing/EditPostContent";
import Card from '../../UI/Card';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useSearchParams } from "react-router-dom";
import AddNewLinkPopUp from "./AddNewLinkPopUp";


const Links = (props) => {
    const [siteList, setSiteList] = useState([]);
    const [draftPosts, setDraftPosts] = useState([]);
    const [filteredDraftList, setFilteredDraftList] = useState([]);
    const [addPostImages, setAddPostImages] = useState(false);
    const [postSelected, setPostSelected] = useState([]);
    const [siteId, setSiteId] = useState({ id: 0, label: 'Select' });
    const [roleId, setRoleId] = useState(ReactSession.get("RoleID"));
    const [viewID, setViewId] = useState(ReactSession.get("RoleID"));
    const [statusFilter, setStatusFilter] = useState(0);
    const [autoCompleteSiteList, setAutoCompleteSiteList] = useState([{ label: "Select ", id: 0 }]);

    const [searchParams] = useSearchParams();
    const url_post_id = searchParams.get('postid');
    const url_site_id = searchParams.get('siteid');
    const navigate = useNavigate();
    const [siteIDSelected, setSiteIDSelected] = useState('');
    const [showAddNewLinkPopup, setShowAddNewLinkPopup] = useState(false);
 

    useEffect(() => {


        fetchSiteList();

        if (props.RoleId !== undefined) {
            setViewId(props.RoleId);
            ReactSession.set("view", props.RoleId);

        }
        else if (typeof ReactSession.get("view") != 'undefined') {
            setViewId(ReactSession.get("view"));
        }
        else {
            setViewId(4);
        }
           
       if (url_site_id !== null) {

            fetchDraftPosts(url_site_id);
            /*const site = autoCompleteSiteList.filter(site => site.id === url_site_id);
            setSiteId(site[0]);

            setAddPostImages(true);*/
        }
        
           
        

        
    }, []);

    
    useEffect(() => {
        if (draftPosts.length > 0) {
            filterList(statusFilter);
            if (url_post_id !== null) {
                console.log(draftPosts);
                let postfilter = draftPosts.filter(post => post.db_id == url_post_id);
                console.log(postfilter);
                setPostSelected(prevState => {
                    return postfilter;
                });
                const site = autoCompleteSiteList.filter(post => post.id == url_site_id);
                setSiteId(site[0]);
                setAddPostImages(true);
            }
            
            //setIsAllDataLoaded(true);
        }
    }, [draftPosts]);


    async function fetchSiteList() {
        let response = await fetch('sites/getsites');
        let data = await response.json();

        setSiteList(data);
        let sites = [{ label: "Select ", id: 0 }];
        data.forEach(site => {
            const option = { label: site.name, id: site.id };
            sites.push(option);
            
        });
        setAutoCompleteSiteList((prevState) => {
            return [...sites];
        });
        

    }

    const statusSelectionHandler = (e) => {

        let status = e.target.value;
        setStatusFilter(status);
        filterList(status);
    }
    const siteSelectionChanged = async (event, value) => {
        //setSiteName(value.label);
        setSiteId(value);

        await fetchDraftPosts(value.id);
    }

    const filterList = (status) => {
        let filteredList = draftPosts.filter(post => post.status < 6);;

        if (viewID == 5) {
            status = 2;
            filteredList = draftPosts.filter(post => ((post.status == 1 || post.status == 3)));
            //setFilteredDraftList(filteredList);
        }
        else if (roleId ==6) {
            filteredList = draftPosts.filter(post => post.status < 6);
            //setFilteredDraftList(filteredList);
        }
        else if (roleId == 4) {
            filteredList = draftPosts.filter(post => post.status > 6);
            //setFilteredDraftList(filteredList);
        }
        else if (status == 0) {
            filteredList = draftPosts;
        }
        else {
            filteredList = draftPosts.filter(post => post.status == status);

        }
        console.log(filteredList);
        console.log(filteredList.filter(post => post.is_google_doc == 1));
        let filteredList_new = filteredList.filter(post => post.is_google_doc == 1);
        console.log(filteredList_new);
        setFilteredDraftList(filteredList_new);
    }


    async function fetchDraftPosts(id) {
        const data = { 'id': id };
        //let response = await fetch('post/draftposts');
        //let post_data = await response.json();

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/draftposts', config)
            const json = await response.json()
            if (response.ok) {

                setDraftPosts(json);
            } else {

            }
        } catch (error) {
            console.log(error);
        }
        //setSiteList(data);
    }

    const selectPostHandler = (id) => {


        //console.log(draftPosts.filter(post => post.id == id));
        const post_selected = draftPosts.filter(post => post.id == id);
        setPostSelected(draftPosts.filter(post => post.id == id));
        setAddPostImages(true);
        //window.history.replaceState(null, "New Page Title", "/AddPostImages?postid=".id);
        //history.push('/AddPostImages?postid=' . id, { shallow: true });
        navigate('/EditLinkContent?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id);

    }

    const modalCloseHandler = () => {
        setAddPostImages(false);
        if (roleId == 5 || viewID == 5) {
            navigate('/ApproveLinks');
        }
        else {
            navigate('/Links');
        }
        setSiteId(siteId);
        console.log(siteId);
    }

    const onNextPostHandler = () => {

        let i = filteredDraftList.findIndex((row) => {
            return row.db_id == postSelected[0].db_id;
        });

        let new_post = [];
        if (filteredDraftList.length - 1 > i) {
            new_post[0] = filteredDraftList[i + 1];
        }
        else {
            new_post[0] = filteredDraftList[0];
        }

        setPostSelected(prevPostSelected => ({
            ...new_post
        }));
        /*setTimeout(() => {
           
        }, 100);*/
        //console.log("Post selection changed");
        //console.log(new_post);
        navigate('/EditLinkContent?postid=' + new_post[0].db_id + '&siteid=' + new_post[0].site_id);
    }

    const contentAddedHandler = () => {
        setShowAddNewLinkPopup(false);
        console.log(siteId);
        fetchDraftPosts(siteId.id);
    }

    const onPostListUpdate = () => {
        fetchDraftPosts(siteId.id);
    }

    const onPrevPostHandler = () => {
        let i = filteredDraftList.findIndex((row) => {
            return row.db_id == postSelected[0].db_id;
        });
        let new_post = [];
        if (i > 0) {
            new_post[0] = filteredDraftList[i - 1];
        }
        else {
            new_post[0] = filteredDraftList[filteredDraftList.length - 1];
        }

        setPostSelected(new_post);
        navigate('/EditLinkContent?postid=' + new_post[0].db_id + '&siteid=' + new_post[0].site_id);
    }

    const showAddNewLinkPopupHandler = () => {
        setShowAddNewLinkPopup(true);
    }
    const closeModalHandler = () => {
        setShowAddNewLinkPopup(false);
    }
    const siteOptions = siteList.map(site => <option value={site.id} >{site.name}</option>);

    return (
        
            
            <>
            {addPostImages == false &&
                <>
                    <Card>
                        <div className="container-fluid">
                            <h3>Google docs for Content Editing</h3>
                            <div className="row">
                                <div className="col">

                                    <Autocomplete
                                        autoSelect={true}
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo"
                                        value={siteId}
                                        inputValue={siteIDSelected}
                                        onInputChange={(event, newInputValue) => {
                                            setSiteIDSelected(newInputValue);
                                        }}
                                        //isOptionEqualToValue={(option, siteId) => option.id === siteId.id}
                                        options={autoCompleteSiteList}
                                        sx={{ width: 300 }}
                                        onChange={siteSelectionChanged}
                                        renderInput={(params) => <TextField {...params} label="Select Site" />}
                                    />
                                </div>
                                <div className="col">
                                    {roleId == 4 && <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={statusFilter}
                                        label=""
                                        onChange={statusSelectionHandler}
                                        sx={{ width: 300 }}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        <MenuItem value={1}>Not submitted</MenuItem>
                                        <MenuItem value={2}>Submitted</MenuItem>
                                        <MenuItem value={3}>Approved</MenuItem>
                                        <MenuItem value={4}>Rejected</MenuItem>
                                        <MenuItem value={5}>Completed</MenuItem>
                                    </Select>
                                    }
                                </div>
                                <div className="col">
                                    <button className="btn btn-primary" onClick={showAddNewLinkPopupHandler} >Add new link</button>
                                </div>

                            </div>

                        </div>
                    </Card>
                    <br></br>

                <DraftPostColumns data={filteredDraftList} onSelectPost={selectPostHandler} showDeleteButton={true} />
                </>
            }
            
            {addPostImages && <EditPostContent postDetails={postSelected} siteName={siteId.label} onNextPost={onNextPostHandler} onPrevPost={onPrevPostHandler} siteId={siteId.id} onCloseModal={modalCloseHandler} roleId={roleId} viewID={viewID} updatePostList={onPostListUpdate} ></EditPostContent>}
            {showAddNewLinkPopup && <AddNewLinkPopUp onCloseModal={closeModalHandler} siteSelected={ siteId} sites={autoCompleteSiteList} contentAdded={contentAddedHandler} />}
            </>
        
    );
}

export default Links;
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Editor } from 'react-draft-wysiwyg'; 
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw, draftToHtml } from 'draft-js';
import  ReactQuill  from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactSession } from 'react-client-session';




// Function Name Should be Our file name and we use this file  for showing diffrent email subject and body base on  email type.
//we have used TemplateController for this file
//We call two  web service method for this file
//1-Get -- this get web service method  is used to show data base on email type.
//2-Save -- this Save web service method is used to save subject and body .
//Bussiness logic for this page in short it
 // we save diffrent subject and body for mail base on diffrent email type  and we display scuess message on snack bar when data is added sucessfully.
function Template() {

    // This is MUI's Alert function which is encapsulated inside the Snakbar.
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    //We have used the state as a useState to prevent the Snakbars state and by this we can dynamically set the state properties
    //for Snakbars different -different menthod and its associated behavious like if we set "severity" as "error" then error
    //message will comes in red
    const [state, setState] = React.useState({
        snackopen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'success', //error//warning//info
        message: ''
    });

    const [reply, setReply] = React.useState('');
    //this method is call when we have to close snack bar.
    const handleClose = () => {
        setState({ ...state, snackopen: false, message: '' });
    };
    const { vertical, horizontal, snackopen, message, severity } = state;

    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

      // We have used formerros to prevent the error message on the form and based on users input we used to change the instance of the
    //formerrors properties
    const [formerros, setFormerros] = React.useState({});
    const styletrrd = {
        padding: '7px'
    };
    const styleNew = {
        width: 1200
    };

    //this method will call when OnChange of template field will be called and it will set the properties value accordingly
    //we are have made it dynamically using prop.
    const handleChangeEmailData = async(event) => {
       await getEmailData(event.target.value);        
    };

    //This is the Web API Get method  and we get data based on id.
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API.
    const getEmailData = async (id) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        fetch(`template/${id}`, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {
                    if (typeof (data) == "string") {
                        setState({ ...state, snackopen: true, message: data, severity: 'error' });
                    }
                    else {                       
                        if (data.body !== null) {
                            setValues({
                                ...values,
                                emailSubject: data.subject, emailType: data.emailType, emailBody:data.body
                            });                           
                            setFormerros({
                                ...formerros, emailSubject: "", emailType: ''
                            });              
                           // setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(data.body))));
                            setReply(data.body);
                        }
                        else {
                            setValues({
                                ...values,
                                emailSubject: '', emailType: id, emailBody: data.body
                            });                            
                            setFormerros({
                                ...formerros, emailSubject: '',  emailType: ''
                            });
                            //setEditorState(EditorState.createEmpty());
                            setReply('');
                        }                      
                    }
                }
            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }
    }
    // We have used the values as useState hooks to prevent the emailsubject & emailbody along with emailType
    //we will set the methd based on the users input and html component behaviour
    const [values, setValues] = React.useState({
        emailSubject: '',
        emailBody: '',
        emailType: -1,
        loading: false
    });

     //this method will call when OnChange of template field will be called and it will set the properties value accordingly
    //we are have made it dynamically using prop.
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };


    const AddData = async (EmailType) => {
        setValues({ ...values, loading: true });
        setFormerros(validate(values));
        if (EmailType > -1)
            if (Object.keys(formerros).length === 0) {
                await adddata();
            }
    }

    //This method will use for apply validation on Template field.
    const validate = (values) => {
        const errors = {};

        if (values.emailType === -1) {
            errors.emailType = "Email Type is required.";
        }

        if (!values.emailSubject) {
            errors.emailSubject = "Email Subject is required.";
        }

        //if (!values.emailBody) {
        //    errors.emailBody = "Email Body is required.";
        //}

        if (Object.values(errors).every(val => val === "")) {
            adddata();
        }
        return errors;
    }

    //This is the Web API Post method the call the Save method and we are sending the template information  as the parameter of the post
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const adddata = () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        if (Object.keys(values).length > 0) {
            const requestOptions = {
                method: 'POST',
                /* headers: { 'Content-Type': 'application/json' },*/
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ Subject: values.emailSubject, Body: reply, EmailType: values.emailType })
            };

            fetch(`template`, requestOptions)
                .then(async response => {
                    const data = await response.json();
                   
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                        setValues({ ...values, loading: false });
                        return Promise.reject(error);
                    }
                    else {

                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                        }
                        else {
                            setState({ ...state, snackopen: true, message: 'Save Successfully.', severity: 'success' });
                        }
                        setValues({ ...values, loading: false });
                    }
                })
                .catch(error => {
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    setValues({ ...values, loading: false });
                    console.error('There was an error!', error);
                });
        }
    }

    }
    //const onEditorStateChange = async (state) => {
    //    await setEditorState(state);


    //    const data = convertToRaw(editorState.getCurrentContent());

    //    const htmlPuri = draftToHtmlPuri(
    //        convertToRaw(editorState.getCurrentContent())
    //    );
    //    //const a = JSON.stringify(data);
    //    setValues({ ...values, emailBody: htmlPuri });
    //};

     //this method is call when we change editor
    const replyInputHandler = (editor) => {
        setReply(editor);
    } 

    //return is having the form controls which we can see when we run the page.
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '98%', mb: 2 }}>
                <Typography id="title" variant="h6" component="h2">
                    Email Template
                </Typography>
                <table style={{ "width": "95%" }} >
                    <TableBody>
                        <tr style={styletrrd}>
                            <td style={styletrrd}>
                                <FormControl fullWidth sx={{ m: 1 }} error={formerros.emailType}>
                                    <InputLabel id="demo-simple-select-helper-label">Email Type</InputLabel>
                                    <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                                        value={values.emailType} label="Email Type"
                                        onChange={(event) => handleChangeEmailData(event)}
                                    >
                                        <MenuItem value={-1} >Email Type</MenuItem>
                                        <MenuItem value={1} >Contact form Standard Response</MenuItem>
                                        <MenuItem value={2} >Order completed with PayPal</MenuItem>
                                        <MenuItem value={3} >Order completed without PayPal</MenuItem>
                                        <MenuItem value={4} >Order started</MenuItem>
                                        <MenuItem value={5} >First Late Payment Warning</MenuItem>
                                        <MenuItem value={6} >Second Late Payment Warning</MenuItem>
                                        <MenuItem value={7} >Order removed for non-payment</MenuItem>
                                    </Select>
                                    <FormHelperText>{formerros.emailType}</FormHelperText>
                                </FormControl>
                            </td>
                        </tr>
                        <tr style={styletrrd} >
                            <td style={styletrrd}>
                                <FormControl fullWidth sx={{ m: 1 }} error={formerros.emailSubject} >
                                    <InputLabel htmlFor="outlined-adornment-emailSubject">Email Subject</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-emailSubject"
                                        label="Email Subject"
                                        value={values.emailSubject}
                                        onChange={handleChange('emailSubject')} />
                                    <FormHelperText>{formerros.emailSubject}</FormHelperText>
                                </FormControl>
                            </td>
                        </tr>

                        <tr style={styletrrd}>
                            <td style={styletrrd}>   
                               {/* <FormControl fullWidth sx={{ m: 1 }} error={formerros.emailBody}>*/}                  
                                    <Box sx={styleNew}>                                        
                                        {/*<Editor*/}
                                        {/*    editorState={editorState}*/}
                                        {/*    onEditorStateChange={onEditorStateChange}*/}
                                        {/*    toolbarClassName="toolbarClassName"*/}
                                        {/*    wrapperClassName="wrapperClassName"*/}
                                        {/*    editorClassName="editorClassName"                                            */}
                                        {/*    editorStyle={{ border: "1.5px solid #C0C0C0" }}                                                                                */}
                                    {/*/>*/}

                                    <ReactQuill theme="snow" value={reply} onChange={replyInputHandler} />
                                    </Box>
                                {/*    <FormHelperText>{formerros.emailBody}</FormHelperText>*/}
                                {/*</FormControl>*/}
                            </td>
                        </tr>
                    </TableBody>
                </table>
                <Stack direction="row" align="center" spacing={2}>
                    {/* <Button color="success" variant="contained" onClick={(event) => AddData(values.emailType)}>Save</Button>*/}
                    <LoadingButton
                        loading={values.loading}
                        variant="contained"
                        color="success"
                        loadingPosition="start"
                        onClick={(event) => AddData(values.emailType)}
                    >
                        Save
                    </LoadingButton>
                </Stack>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={3000}
                    open={snackopen}
                    onClose={handleClose}
                    key={vertical + horizontal}
                >
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Paper>
        </Box>
    );
}

export { Template }
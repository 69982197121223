import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Modal from '@mui/material/Modal';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useEffect } from 'react'
import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Loading } from './Animation/Loading';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { ReactSession } from 'react-client-session';


// Function Name Should be Our file name and we use this file  for showing User Information.
//This  User page is used for display all user information and we can also add new user or update system user information like password,roles etc
//we use userinfo controller for this page
//We call four web service method for  this page
//1-Get -- this method is use for get all user data and show on page.
//2-Save-- this method is call when we add new user in system .
//3-Update -- this method is call when we update system user information.
//4-Delete -- this method is use when we have to delete user from system.
//Bussiness logic for this page in short it
// we add/edit user from this page and assign roles to user if all information set correctly then we add record  and if not we show error in snack bar

// style is use for setup look and feel for grid and modal pop up
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 50,
    p: 4,
};

//StyledTableCell is used for set Table cell  style .
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "skyblue",
        color: theme.palette.common.white,
        onmouseover: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

//StyledTableRow is used for set Table Row  style .
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


// HeadCells is use for showing Datagrid Column name
const headCells = [
    {
        id: 'userID',
        numeric: true,
        disablePadding: false,
        label: 'User ID',
    },
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        label: 'First Name',
    },
    {
        id: 'fastName',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'createdBy',
        numeric: true,
        disablePadding: false,
        label: 'CreatedBy',
    },

];

// EnhancedTableHead is use for Set data grid head property
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell></StyledTableCell>

                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        /* align={headCell.numeric ? 'right' : 'left'}*/
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    <h2>Users Data </h2>
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


 
function Users({ navigate }) {
    navigate = useNavigate(); // This function is used for navigation between the pages from one page to another page.
    const [apirows, setApirows] = React.useState([]);
    const [addbtntext, setAddbtntext] = React.useState("Add User");

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [searched, setSearched] = useState("");
     //we have used order as a usestate to set data as assesnding order.
    const [order, setOrder] = React.useState('asc');
    //we have used orderBy  as a usestate to apply shorting on userID column.
    const [orderBy, setOrderBy] = React.useState('userID');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
     //we have used rowsPerPage as a usestate to set how many rows we have to display on page.
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [role, setRole] = React.useState(0);
    //Model Popup Hooks

    const [open, setOpen] = React.useState(false);
    const [SelelctedID, SetSelelctedID] = React.useState(0);
    const [SelelctedUsername, SetSelelctedUsername] = React.useState("");
    const [erromessage, Seterromessage] = React.useState("");

     // We have used formerros to prevent the error message on the form and based on users input we used to change the instance of the
    //formerrors properties
    const [formerros, setFormerros] = React.useState({});


    // This is MUI's Alert function which is encapsulated inside the Snakbar
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    //We have used the state as a useState to prevent the Snakbars state and by this we can dynamically set the state properties
    //for Snakbars different -different menthod and its associated behavious like if we set "severity" as "error" then error
    //message will comes in red

    const [state, setState] = React.useState({
        snackopen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'success', //error//warning//info
        message: ''
    });


    // We have used the values as useState hooks to prevent the firstname,lastName,username,email,roleid & password along with showpassword and loading method
    //we will set the methd based on the users input and html component behaviour
    const [values, setValues] = React.useState({
        userID: '',
        password: '',
        showPassword: false,
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        roleid: 0,
        loading: false

    });

    const [disable, setDisable] = React.useState(false);

    const handleClose1 = () => {
        setState({ ...state, snackopen: false, message: '' });
    };

    
    const { vertical, horizontal, snackopen, message, severity } = state;

     //This method will call when user click on delete icon of grid we send user id,name for  delete user .
    const handleOpen = (event, userID, userName) => {
        setOpen(true);
        SetSelelctedID(userID);
        SetSelelctedUsername(userName);
           
    }

    //handleDeleteUser is use for  calling delete user method
    const handleDeleteUser = async (userID) => {
        await deleteUserData(userID);
        setOpen(false);
    }

    const handleClose = () => setOpen(false);


    //Fab ICON
    const [openaddedit, setOpenaddedit] = React.useState(false);
    const [SelelctedIDaddedit, SetValueaddedit] = React.useState([]);

    //This method will call when user click on Add/edit  user button to open modal popup.  .
    const handleOpenpopup = (event, userobject, flag) => {
        //alert(userobject.userID);

        if (flag === 0) {
            //alert("true");
            setAddbtntext("Add User");
            setOpenaddedit(true);
            setRole(0);
            console.log(userobject);
            setValues({
                ...values,
                userID: 0, firstName: "", lastName: "", email: "", username: "", password: "", roleid: 0
            });
            console.log(values);
            setFormerros({
                ...formerros, firstName: "", lastName: "", email: "", password: "", username: "", roleid: ""
            });

        } else {
            // alert("flase");
            setAddbtntext("Update User");
            setOpenaddedit(true);
            console.log(userobject);
            setValues({
                ...values,
                userID: userobject.userID, firstName: userobject.firstName, lastName: userobject.lastName, email: userobject.email, username: userobject.username, password: userobject.password, roleid: userobject.roleID
            });
            console.log(values);

            setFormerros({
                ...formerros, firstName: "", lastName: "", email: "", password: "", username: "", roleid: ""
            });
        }

    }


     //This method will call for close modal pop up.
    const handleClosepopup = () => setOpenaddedit(false);

    //useEffect - Hooks is used to handle the page rendering and it will call when ever the page loads for the first time
    //as we have restricted it with [].
    useEffect(() => {
        getUserData();
    }, [])

    //This is the web API Get method and we call Get method and show user information on grid.
    const getUserData = async () => {
       
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        var options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        } 
        try {
            const response = await fetch('userinfo', options);
            const data = await response.json();
            if (data) {
                setRows(data);
                setApirows(data);
                setLoading(false);
                setAddbtntext("Add User");
                setRole(0);
                setValues({
                    ...values,
                    userID: 0, firstName: "", lastName: "", email: "", username: "", password: "", roleid: 0
                });
                setOpenaddedit(false);
            }

        } catch (error) {
            setLoading(true);
            setOpenaddedit(true);
            setState({ ...state, snackopen: true, message: 'Error is coming inside the getUserData and the errir is - >' + error, severity: 'error' });

        }

    }
    }

    //This method will call the WEB API save/update method .
    const AddUser = async (event, userID) => {
        setValues({ ...values, loading: true });
        if (addbtntext == "Update User") {
            setFormerros(validate(values));
            
            //setValues({ ...values, loading: true });
            
            
           
            if (Object.keys(formerros).length === 0) {
                 //This method will call the WEB API Update method when user will click on Update User button after entering the user information.
                await edituserdata();                
            } 

        } else {
            /*alert("Entry point in add user condition");*/
            setFormerros(validate(values));
            
            //setValues({ ...values, loading: true });
            

            if (Object.keys(formerros).length === 0) {
                 //This method will call the WEB API Save method when user will click on Add User button after entering the user information.
                await adduserdata();
               
            } 
        }
       
    }




   //This is the web API Delete method and delete method remove user information base on userid.
    const deleteUserData = async (id) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        fetch(`userinfo/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {

                    if (typeof (data) == "object") {
                        setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                    }
                    else {
                        setState({ ...state, snackopen: true, message: 'Deleted Sucessfully.', severity: 'success' });
                        getUserData();
                    }
                }
            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }
    }
    //This method will use for apply validation on Add/edit user pop up.
    const validate = (values) => {
        
        const errors = {};
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!values.firstName) {
            errors.firstName = "First Name is required.";
        }

        if (!values.lastName) {
            errors.lastName = "Last Name is required.";
        }

        if (!values.email) {
            errors.email = "Email is required.";
        }
        else if (!regex.test(values.email)) {
            errors.email = "Invalid Email.";
        }

        if (!values.username) {
            errors.username = "UserName is required.";
        }

        //if (addbtntext == "Add User") {
        if (!values.password) {
            errors.password = "Password is required.";
        }
        //}

        if (!values.roleid) {
            errors.roleid = "Role is required.";
        }

        if (Object.values(errors).every(val => val === "")) {
            if (addbtntext == "Update User") {
                setDisable(true);
                edituserdata();
                setDisable(false);
            }
            else {
                setDisable(true);
                adduserdata();
                setDisable(false);
            }
        }

        return errors;
    }

    //This is the Web API Post method the call the Save method and we are sending the user information  as the parameter of the post
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const adduserdata = () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        if (Object.keys(values).length > 0) {
            const requestOptions = {
                method: 'POST',
                /* headers: { 'Content-Type': 'application/json' },*/
                headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${token}` },
                body: JSON.stringify({ FirstName: values.firstName, LastName: values.lastName, Username: values.username, Password: values.password, Email: values.email, RoleID: values.roleid })
            };

            fetch(`userinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status; 
                        //setValues({ ...values, loading: false });
                       
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                        setValues({ ...values, loading: false });
                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                            //setValues({ ...values, loading: false });
                            setValues({ ...values, loading: false });
                        }
                        else {
                            await getUserData();
                            
                            setState({ ...state, snackopen: true, message: 'Save Sucessfully', severity: 'success' });
                        }
                        setValues({ ...values, loading: false });
                    }
                })
                .catch(error => {
                    //Seterromessage('error');
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    //setValues({ ...values, loading: false });
                    setValues({ ...values, loading: false });
                    console.error('There was an error!', error);
                });
        }
        
    }
    }
     //This is the Web API Put method the call the Update and we are sending the user information  as the parameter of the put
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const edituserdata = () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        if (Number(values.userID) > 0) {

            const requestOptions = {
                method: 'PUT',
                /* headers: { 'Content-Type': 'application/json' },*/
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ FirstName: values.firstName, LastName: values.lastName, Username: values.username, Password: values.password, Email: values.email, UserID: values.userID, RoleID: values.roleid })
            };

            fetch(`userinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status; 
                        //setValues({ ...values, loading: false });
                        
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                        setValues({ ...values, loading: false });
                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                            //setValues({ ...values, loading: false });
                            setValues({ ...values, loading: false });
                        }
                        else {
                            await getUserData();
                            //Seterromessage('Record Updated successful');
                            setState({ ...state, snackopen: true, message: 'Updated successfully', severity: 'success' });
                          
                            setOpenaddedit(false);
                            
                        }
                        setValues({ ...values, loading: false });
                    }                   
                })
                .catch(error => {
                    setOpenaddedit(true);
                    //Seterromessage('error');
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    //setValues({ ...values, loading: false });
                    setValues({ ...values, loading: false });
                    console.error('There was an error!', error);
                });
        }
    }
    }
    
    //this method will call when OnChange of users field will be called and it will set the properties value accordingly
    //we are have made it dynamically using prop.
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        
    };


     //This method will call when user will click on the eye icon to see the entered password.
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    //We have prevented the OnMouseDown on the passowrd to prevent its event.
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangerole = (event) => {
        setRole(event.target.value);
    };

    //This method will call when user try to apply filter on user grid we fillter from grid base on entre value.
    const requestSearch = (searchedVal) => {

        console.log("Search value is => " + searchedVal.toLowerCase());

        if (searchedVal) {

            const filteredRows = rows.filter((row) => {
                return row.firstName.toLowerCase().includes(searchedVal.toLowerCase());
                //return row;
            });

            setRows(filteredRows);
        } else {
            setRows(apirows);
        }
    };

    //handleRequestSort is use for applying Sorting on data grid
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.userID);
            // setSelected(newSelected);

            return;
        }
        //setSelected([]);
    };

    const handleClick = (event, userID) => {
        const selectedIndex = selected.indexOf(userID);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, userID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        // setSelected(newSelected);
    };

    //This method will call when user will try to change page.
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

     //This method will call when user will try to change page Size.
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (userID) => selected.indexOf(userID) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

   

    if (loading) {

        return (
            <Loading />
        )
    }  

     //return is having the form controls which we can see when we run the page.
    return (
       
        <Box sx={{ width: '100%' }}>            
            <div>
                <Modal
                    open={openaddedit}
                    onClose={handleClosepopup}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add/ Edit User
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                            <FormControl fullWidth sx={{ m: 1 }} error={formerros.firstName} >
                                <InputLabel htmlFor="outlined-adornment-firstname">First Name</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-firstname"
                                    label="First Name"
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                />
                                <FormHelperText>{formerros.firstName}</FormHelperText>
                            </FormControl>

                            {/*<p style={{ color: 'red',fontWeight:'bold' }} >{ formerros.firstName }</p>*/}
                            <FormControl fullWidth sx={{ m: 1 }} error={formerros.lastName} >
                                <InputLabel htmlFor="outlined-adornment-lastname">Last Name</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-lastname"
                                    label="Last Name"
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                />
                                <FormHelperText>{formerros.lastName}</FormHelperText>
                            </FormControl>
                            {/*<p style={{ color: 'red', fontWeight: 'bold' }}>{formerros.lastName}</p>*/}


                            <FormControl fullWidth sx={{ m: 1 }} error={formerros.email}>
                                <InputLabel htmlFor="outlined-adornment-Email">Email</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-Email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                />
                                <FormHelperText>{formerros.email}</FormHelperText>
                            </FormControl>

                            {/*<p style={{ color: 'red', fontWeight: 'bold' }}>{formerros.email}</p>*/}

                            <FormControl fullWidth sx={{ m: 1 }} error={formerros.username}>
                                <InputLabel htmlFor="outlined-adornment-username">User Name</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-username"
                                    label="User Name"
                                    value={values.username}
                                    onChange={handleChange('username')}
                                />
                                <FormHelperText>{formerros.username}</FormHelperText>
                            </FormControl>
                            {/*<p style={{ color: 'red', fontWeight: 'bold' }}>{formerros.username}</p>*/}
                            <FormControl fullWidth sx={{ m: 1 }} variant="outlined" error={formerros.password}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                <FormHelperText>{formerros.password}</FormHelperText>
                            </FormControl>

                            {/*<p style={{ color: 'red', fontWeight: 'bold' }}>{formerros.password}</p>*/}
                            <FormControl fullWidth sx={{ m: 1 }} error={formerros.roleid}>
                                <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={values.roleid}
                                    label="Role"
                                    onChange={handleChange('roleid')}
                                >
                                    <MenuItem value={0} >
                                        Select
                                    </MenuItem>
                                    <MenuItem value={1}>Admin</MenuItem>
                                    <MenuItem value={2}>Guest Post Processor</MenuItem>
                                    <MenuItem value={3}>Writer</MenuItem>
                                    <MenuItem value={4}>Image Finder</MenuItem>
                                    <MenuItem value={5}>Image Reviewer</MenuItem>
                                    <MenuItem value={6}>Content Editor</MenuItem>
                                    
                                </Select>
                                <FormHelperText>{formerros.roleid}</FormHelperText>
                            </FormControl>
                            <Stack direction="row" align="center" spacing={2} >
                                {/*<Button color="success" variant="contained" disabled={disable} onClick={(event) => AddUser(event, 0)}>{addbtntext}</Button>*/}
                                <LoadingButton loading={values.loading} variant="contained" color="success" loadingPosition="start" onClick={(event) => AddUser(event, 0)} > {addbtntext}</LoadingButton>                         
                                <Button variant="contained" onClick={() => handleClosepopup()} color="success">
                                    Cancel
                                </Button>
                            </Stack>
                        </Typography>
                    </Box>
                </Modal>
            </div>

            <div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure want to delete {SelelctedUsername} ?
                        </Typography>
                        <Stack direction="row" spacing={2}></Stack>
                        <Stack direction="row" spacing={2} >
                            <Button variant="outlined" color="error" onClick={() => handleDeleteUser(SelelctedID)} startIcon={<DeleteIcon />}>
                                Delete
                            </Button>
                            <Button variant="contained" onClick={() => handleClose()} color="success">
                                Cancel
                            </Button>
                        </Stack>

                        <Stack direction="row" spacing={2}>
                            {/* {erromessage}*/}
                        </Stack>
                    </Box>
                </Modal>
            </div>


            {/*<Paper sx={{ width: '100%', mb: 2 }}>*/}

            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '5px' }} >
                <Button variant="contained" onClick={(event) => handleOpenpopup(event, values, 0)} color="success">
                    Add User
                </Button>

            </div>

            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '5px' }} >
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, border: 1 }}
                >
                    <IconButton sx={{ p: '10px' }} aria-label="menu">

                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search Users"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => requestSearch(e.target.value)}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>

                </Paper>
            </div>


            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:rows.slice().sort(getComparator(order, orderBy)) */}
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.userID);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <StyledTableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.userID)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.userID}
                                        selected={isItemSelected}
                                    >
                                        <StyledTableCell align="left">
                                            <Stack direction="row" spacing={2}>
                                                <DeleteForeverRoundedIcon cursor="pointer" onClick={(event) => handleOpen(event, row.userID, row.firstName + " " + row.lastName)} />
                                                <EditIcon cursor="pointer" onClick={(event) => handleOpenpopup(event, row, 1)}></EditIcon>
                                            </Stack>

                                        </StyledTableCell>
                                        <StyledTableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                        >
                                            {row.userID}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.firstName}</StyledTableCell>
                                        <StyledTableCell align="center">{row.lastName}</StyledTableCell>
                                        <StyledTableCell align="center">{row.email}</StyledTableCell>
                                        <StyledTableCell align="center">{row.createdBy}</StyledTableCell>
                                    </StyledTableRow >
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/*</Paper>*/}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000}
                open={snackopen}
                onClose={handleClose1}
                key={vertical + horizontal}
            >
                <Alert onClose={handleClose1} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );


}

export { Users }




import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import copy from 'copy-to-clipboard';
import {
    DataGrid, GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { useState } from 'react';



const SimpleGrid = (props) => {

    const [pageSize, setPageSize] = useState(10);
    return (
        <Box sx={{
             width: '100%',
            '& .cell_selected': {
                backgroundColor: 'rgba(39, 159, 255, 0.4)',
                color: '#1a3e72',
                fontWeight: '600',
            },

            '& .MuiDataGrid-cell': {
                padding: 0,
                paddingRight: 2,

            },
            '& .group1': {
                backgroundColor: 'rgba(163, 228, 215, 0.55)',
            },
            '& .group2': {
                backgroundColor: 'rgba(236, 112, 99, 0.55)',
            },
            '& .group3': {
                backgroundColor: 'rgba(0, 7, 255, 0.55)',
            },
            '& .group4': {
                backgroundColor: 'rgba(84, 153, 199, 0.55)',
            },

        }}>
            <DataGrid
                autoHeight
                getRowHeight={() => 'auto'}
                rows={props.data}
                columns={props.columns}
                columnVisibilityModel={props.columnVisibilityModel}
                columnGroupingModel={props.columnGroupingModel}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pagination
                hideFooter={props.showPaging == 0 ? true : false}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                checkboxSelection={false}
                onRowSelectionModelChange={(ids) => {
                    props.onRowSelection(ids);
                }}
                //rowSelectionModel={rowSelectionModel}
                
                experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
               
            //onCellClick={cellClickHandler}

            />
        </Box>
        );
}

export default SimpleGrid;
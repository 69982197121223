
const DateString = (props) => {
    const new_date = new Date(props.postDate);
    
    return (
    <>
            {new_date.toLocaleTimeString()}, {new_date.toLocaleDateString()} 
        </>
    );
}

export default DateString;
import { useState, useEffect } from 'react';
import Modal from '../UI/Modal';

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ReplyToContact = (props) => {
    
    const [subject, setsubject] = useState('Test');
    const [replyType, setReplyType] = useState('standard');
    //const [fromEmail, setFromEmail] = useState('');
    const [reply, setReply] = useState('');
    const orginalMessage = "<br /><br /><br />*** Original Contact Us Form message ***<br/>" + props.contactDetails[0].message;
    useEffect(() => {
        setsubject('Re: ' + props.contactDetails[0].subject);
        const replyText = props.emailTemplate + orginalMessage;
        setReply(replyText);

    }, []);

    
    const subjectInputHandler = (event) => {
        console.log(event.target.value);
        setsubject(event.target.value);
    }

 
    const replyInputHandler = (editor) => {
        setReply(editor);
    } 
    const replyTypeHandler = (event) => {
        
        
        const replyType = event.target.value;

        if (replyType === 'standard') {
            const replyText = props.emailTemplate + orginalMessage;
            setReply(replyText);
        }
        else
            setReply(orginalMessage);
        setReplyType(event.target.value);

    } 

    const onFormSubmitHandler = (event) => {
        event.preventDefault();
        let replySubmitted = props.contactDetails[0];
        replySubmitted.reply_Subject = subject; 
        replySubmitted.reply_Message = reply;
        updateReply(replySubmitted);
        props.onReply();
    }

    async function updateReply(replySubmitted) {
        try {
            const data = replySubmitted;
            console.log(data);
            const config = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('contact/updatereply', config)
            //const json = await response.json()
            if (response.ok) {

            } else {

            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal onCloseModel={props.onCloseModel}>
            <div className="container">
               
                <div className="row">
                    <div className="col">
                      
            <h5>Message</h5>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, maxWidth: '98%' },
                    
                    
                }}
                noValidate
                autoComplete="off"
            >
                <div>
                    <TextField
                        id="contactName"
                        label="Contact Name"
                        value={props.contactDetails[0].name}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="contactEmail"
                        label="Contact Email"
                        value={props.contactDetails[0].email}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        id="contactSubject"
                        label="Contact Subject"
                        value={props.contactDetails[0].subject}
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                    <TextField
                            id="contactMessage"
                            label="Contact Message"
                            value={props.contactDetails[0].message}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                            multiline
                    />
                </div>
            </Box>
                    </div>

                    <div className="col">    
            <h5>Reply To Contact</h5>
              
            
                        <form onSubmit={onFormSubmitHandler }>
                    
                    
                    <div className="form-group">
                                <TextField
                        id="subject"
                        label="Subject"
                        value={subject}
                        onChange={subjectInputHandler}
                        fullWidth
                        margin="normal"
                        error={subject === ""}
                        helperText={subject === "" ? 'Please provide subject!' : ' '}
                        required
                                />
                    </div>
                    
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Reply Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={replyType }
                        name="radio-buttons-group"
                        onChange={replyTypeHandler}
                        value={replyType }
                        row
                        >
                            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                            
                        </RadioGroup>
                    </FormControl>
                    <div className="form-group">
                 
                                <ReactQuill theme="snow" value={reply} onChange={replyInputHandler} />
                    </div>
               
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" className="btn btn-default" onClick={props.onCloseModal} >Cancel</button>
            
                </form>
</div>
                    </div>
            </div>
        </Modal>
    );
}
export default ReplyToContact;
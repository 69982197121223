import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ReactSession } from 'react-client-session';
import LogoutIcon from '@mui/icons-material/Logout';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    componentDidMount() {
        
        if (window.location.pathname != "/") {
            if (localStorage.getItem("userId") == null) {
                window.location.href = "/";
            }
            /*console.log(sessionStorage.getItem("UserID"));*/
            /*if (ReactSession.get("UserID") == null) {
                window.location.href = "/";
            }*/
            
        }

        if (typeof ReactSession.get("RoleID") != 'undefined') {
           /* if (ReactSession.get("RoleID") == 4) {
                if (window.location.pathname != "/DraftPosts") {
                    window.location.href = "/DraftPosts";
                }
            }
            else if (ReactSession.get("RoleID") == 5) {
                if (window.location.pathname != "/DraftPosts") {
                    window.location.href = "/DraftPosts";
                }
            }
            else*//* if (ReactSession.get("RoleID") != 1) {
                if (window.location.pathname != "/Sites") {
                    window.location.href = "/Sites";
                }
            }*/
          
             
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let navigate = "";
        ReactSession.setStoreType("localStorage");
        function changeLocation(placeToGo) {
            navigate(placeToGo, { replace: true });
            window.location.reload();
        }

        function Homepage() {
            /*if (sessionStorage.getItem("UserID") == null) {*/
            if (ReactSession.get("UserID") == null) {
                window.location.href = "/";
            }
            else {
                window.location.reload();
            }
        }

        function Logout() {
            /*sessionStorage.clear();*/
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");
            window.location.href = "/";
        }

        /*const FullName = (sessionStorage.getItem("UserName") !== null ? 'Welcome ' + sessionStorage.getItem("UserName") : '');*/
        const FullName = (typeof ReactSession.get("UserName") !== 'undefined' ? 'Welcome ' + ReactSession.get("UserName") + ' !' : '');

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 " light>
                    <NavLink tag={Link} className="text-dark" to="/PostsReport" onClick={() => changeLocation('/PostsReport')}>Dashboard</NavLink>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        {window.location.pathname === '/' ? ('') : (
                            <>
                                <NavLink tag={Link} className="text-dark" to="#" onClick={() => Logout()}> <LogoutIcon className="cursor" /></NavLink>

                                {ReactSession.get("RoleID") !== '1' ? ('') : (
                                    <>
                                       
                                        <NavItem className={"navbar-link-" + (window.location.pathname === '/Sites' ? 'active' : '')}>
                                            <NavLink tag={Link} className="text-dark" to="/Sites" onClick={() => changeLocation('/Sites')}>Manage Sites</NavLink>
                                        </NavItem>
                                        <NavItem className={"navbar-link-" + (window.location.pathname === '/Users' ? 'active' : '')}>
                                            <NavLink tag={Link} className="text-dark" to="/Users" onClick={() => changeLocation('/Users')}>Manage User</NavLink>
                                        </NavItem>
                                    
                                    </>
                                )}
                                {/*ReactSession.get("RoleID") == 1 &&
                                    <NavItem className={"navbar-link-" + (window.location.pathname === '/PostsReport' ? 'active' : '')}>
                                        <NavLink tag={Link} className="text-dark" to="/PostsReport" onClick={() => changeLocation('/PostsReport')}>Report</NavLink>
                                    </NavItem>*/
                                }
                               
                                {(ReactSession.get("RoleID") == 6 || ReactSession.get("RoleID") == 1) &&
                                   
                                    <NavItem className={"navbar-link-" + (window.location.pathname === '/ContentPosts' ? 'active' : '')}>
                                        <NavLink tag={Link} className="text-dark" to="/ContentPosts" onClick={() => changeLocation('/ContentPosts')}>Add New Content</NavLink>
                                    </NavItem>}
                                {(ReactSession.get("RoleID") == 1) && <NavItem className={"navbar-link-" + (window.location.pathname === '/ApproveContentPosts' ? 'active' : '')}>
                                    <NavLink tag={Link} className="text-dark" to="/ApproveContentPosts" onClick={() => changeLocation('/ApproveContentPosts')}>Review Content</NavLink>
                                </NavItem>}
                                
                                {/*(ReactSession.get("RoleID") == 6 || ReactSession.get("RoleID") == 1) &&
                                    <NavItem className={"navbar-link-" + (window.location.pathname === '/Links' ? 'active' : '')}>
                                        <NavLink tag={Link} className="text-dark" to="/Links" onClick={() => changeLocation('/Links')}>Add New Content</NavLink>
                                </NavItem>*/
                                }
                                {/*ReactSession.get("RoleID") == 1 &&
                                    <>
                                    <NavItem className={"navbar-link-" + (window.location.pathname === '/ApproveLinks' ? 'active' : '')}>
                                        <NavLink tag={Link} className="text-dark" to="/ApproveLinks" onClick={() => changeLocation('/ApproveLinks')}>Approve New Content</NavLink>
                                    </NavItem></>*/
                                }
                                {(ReactSession.get("RoleID") == 4 || ReactSession.get("RoleID") == 1 || ReactSession.get("RoleID") == 5) &&
                                    <NavItem className={"navbar-link-" + (window.location.pathname === '/DraftPosts' ? 'active' : '')}>
                                        <NavLink tag={Link} className="text-dark" to="/DraftPosts" onClick={() => changeLocation('/DraftPosts')}>Image Finding</NavLink>
                                    </NavItem>}
                                {(ReactSession.get("RoleID") == 5 || ReactSession.get("RoleID") == 1) && <NavItem className={"navbar-link-" + (window.location.pathname === '/ApproveDraftPosts' ? 'active' : '')}>
                                    <NavLink tag={Link} className="text-dark" to="/ApproveDraftPosts" onClick={() => changeLocation('/ApproveDraftPosts')}>Approve Images</NavLink>
                                </NavItem>}
                                {(ReactSession.get("RoleID") == 4 || ReactSession.get("RoleID") == 1 || ReactSession.get("RoleID") == 5) &&
                                    <NavItem className={"navbar-link-" + (window.location.pathname === '/search' ? 'active' : '')}>
                                        <NavLink tag={Link} className="text-dark" to="/search" onClick={() => changeLocation('/search')}>Search</NavLink>
                                    </NavItem>}
                                {/*<NavItem className={"navbar-link-" + (window.location.pathname === '/Order' ? 'active' : '')}>
                                    <NavLink tag={Link} className="text-dark" to="/Order" onClick={() => changeLocation('/Order')}>Manage Order</NavLink>
                                </NavItem> */
                                }

                                {/*<NavItem className={"navbar-link-" + (window.location.pathname === '/Contacts' ? 'active' : '')}>
                                            <NavLink tag={Link} className="text-dark" to="/Contacts" onClick={() => changeLocation('/Contacts')}>Contact US</NavLink>
                                        </NavItem>
                                        <NavItem className={"navbar-link-" + (window.location.pathname === '/Template' ? 'active' : '')}>
                                            <NavLink tag={Link} className="text-dark" to="/Template" onClick={() => changeLocation('/Template')}>Template</NavLink>
                                        </NavItem>

                                       
                                        <NavItem className={"navbar-link-" + (window.location.pathname === '/Customers' ? 'active' : '')}>
                                            <NavLink tag={Link} className="text-dark" to="/Customers" onClick={() => changeLocation('/Customers')}>Manage Customers</NavLink>
                                        </NavItem>*/}
                            </>
                        )}
                    </Collapse>
                </Navbar>
              
            </header>
        );
    }
}
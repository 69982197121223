import { Login } from "./components/Login";
import { Users } from './components/Users'
import ManageOrders from "./components/ManageOrders/ManageOrders";
import { Customers } from "./components/Customers";
import { Template } from "./components/Template";
import { AddEditCustomer } from './components/AddEditCustomer'; 
import { AddEditOrder } from './components/AddEditOrder';
import ManageContacts from "./components/ManageContactUs/ManageContacts";
import ManageSites from "./components/ContentPostingTool/Sites/ManageSites";
import DraftPosts from "./components/ContentPostingTool/DraftPosts/DraftPosts";
import ContentEditing from "./components/ContentPostingTool/DraftPosts/ContentEditing";
import Posts from "./components/ContentPostingTool/ContentEditing/Posts";
import Links from "./components/ContentPostingTool/GoogleLinks/Links";
import PostStatusReport from "./components/ContentPostingTool/DraftPosts/PostStatusReport";
import SearchPosts from "./components/ContentPostingTool/DraftPosts/SearchPosts";

import ExportPosts from "./components/ContentPostingTool/DraftPosts/ExportPosts";
const AppRoutes = [
  //{
  //  index: true,
  //  element: <Home />
  //},
  //{
  //  path: '/counter',
  //  element: <Counter />
  //},
  //{
  //  path: '/fetch-data',
  //  element: <FetchData />
  //  },
  // {
  //      path: '/greetings',
  //      element: <Greetings />
  //  }
  //  ,
  //  {
  //      path: '/dashboard',
  //      element: <Dahboard />
  //  },

  //  {
  //      path: '/matTable',
  //      element: <MatTable />
  //  }

    {
        index: true,
        /*path: '/greetings',*/
        element: <Login />
    },
    {
        path: '/Contacts',
        element: <ManageContacts />
    },
    {
        path: '/Users',
        element: <Users />
    },
    {
        path: '/Sites',
        element: <ManageSites />
    },
    {
        path: '/DraftPosts',
        element: <DraftPosts RoleId="4"/>
    },
    {
        path: '/ApproveDraftPosts',
        element: <DraftPosts RoleId="5" />
    },
    {
        path: '/ContentPosts',
        element: <ContentEditing RoleId="4" />
    },
    {
        path: '/EditPostContent',
        element: <ContentEditing RoleId="4" />
    },
    {
        path: '/ApproveContentPosts',
        element: <ContentEditing RoleId="5" />
    },
    {
        path: '/AddPostImages',
        element: <DraftPosts  />
    },
    {
        path: '/PostsReport',
        element: <PostStatusReport />
    },
    
    {
        path: '/Order',
        element: <ManageOrders />
    },
    {
        path: '/Customers',
        element: <Customers />
    },
    {
        path: '/Template',
        element: <Template />
    },
    {
        path: '/AddEditCustomer',
        element: <AddEditCustomer />
    },
    {
        path: '/AddEditOrder',
        element: <AddEditOrder />
    },
    {
        path: '/Links',
        element: <Links RoleId="4" />
    },
    {
        path: '/EditLinkContent',
        element: <Links RoleId="4" />
    },
    {
        path: '/ApproveContent',
        element: <Links RoleId="5" />
    },
    //ApproveLinks
    {
        path: '/ApproveLinks',
        element: <Links RoleId="5" />
    },
    {
        path: '/Search',
        element: <SearchPosts RoleId="5" />
    },
    {
        path: '/ExportPosts',
        element: <ExportPosts />
    },
    
];
export default AppRoutes;
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactSession } from 'react-client-session';
import Box from '@mui/material/Box';


// Function Name Should be Our file name and we use this file  for showing order Information.
//This  AddEditOrder page is used for Add/Edit new order.
//we use OrderInfoController,customerinfo controller for this page
//We call four web service method for  this page
//1-Save-- this method is call when we add new Order in system .
//2-Update -- this method is call when we update system Order information.
//3-GetCustomerandCompany -- this API menthod GetCustomerandCompany is used to get Customer Information on AddEdit order page.
//4-GetCustomerEmailandCompany -- this API menthod GetCustomerEmailandCompany is used to get Customer Email base on selected customer on AddEdit order page.
//Bussiness logic for this page in short it
// we use AddEditorder files to add or update order we can also add new customer for this file that why we use customerinfo controller also this page.
//when we select customer then on right side panel we show all customer information also show mail .
//we have few checkbox on AddEdit file and we use it for applying diffrent condtion on page like Need PayPal Invoice check box checked then we have to entre link.
//base on check box we send diffrent alert to customer.


 // This is MUI's Alert function which is encapsulated inside the Snakbar
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function createData(linkName, id) {
    return { linkName, id };
}

// Styling
const OVERLAY_STYLE = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
};

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 50,
    p: 4

};

const styletrrd = {
    padding: '10px'
};


const floatcontainer = {
    border: '2px solid #fff',
    padding: '1px',
};

const floatchild = {
    width: '50%',
    float: 'left',
    padding: '1px',
    border: '2px solid white'
}
//Styling

const AddEditOrder = ({ navigate }) => {

    //Navigation Start
    navigate = useNavigate();
    //Navigation end

    //Add/Edit Session Management

    //Session end here

    //Hooks UseState Declaration Start
    const [ecopen, setEcopen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [arrayofcompany, setArrayofcompany] = React.useState([]);
    const [emailandcompanys, setEmailandcompanys] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [arrayofemails, setArrayofemails] = React.useState([]);
    const loadingec = ecopen && emailandcompanys.length === 0;
    const loading = open && options.length === 0;
    const [completedLinks, setCompletedLinks] = React.useState([]);
    const [payPalInvoiceLinks, setpayPalInvoiceLinks] = React.useState([]);
    const [tempcompletedLinks, setTempcompletedLinks] = React.useState({ linkName: '', id: 0 });
    const [temppayPalInvoiceLinks, setTemppayPalInvoiceLinks] = React.useState({ linkName: '', id: 0 });
    const [openaddedit, setOpenaddedit] = React.useState(false);

     //We have used the state as a useState to prevent the Snakbars state and by this we can dynamically set the state properties
    //for Snakbars different -different menthod and its associated behavious like if we set "severity" as "error" then error
    //message will comes in red
    const [state, setState] = React.useState({
        snackopen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'success', //error//warning//info
        message: ''
    });
    const { vertical, horizontal, snackopen, message, severity } = state;
    const [formerros, setFormerros] = React.useState(
        {
            IsValidorderDetails: false,
            IsorderPaymentNotes: false,
            IsorderNotes: false,
            IsNotesToAdmin: false,
            IsCompany: false,
            IsCustomerEmail: false
        });

    const [cformerros, setCformerros] = React.useState({});

    const [valuesCust, setValuesCust] = React.useState({
        cName: '',
        cEmail: '',
        cStatus: 0,
        payPalEmailNotes: '',
        additionalEmails: '',
        pricingNotes: '',
        customerNotes: ''
    });

    // We have used the values as useState hooks to prevent the customer info along with  loading method
    //we will set the methd based on the users input and html component behaviour
    const [cvalues, setCvalues] = React.useState({
        customerID: '',
        cName: '',
        cEmail: '',
        cStatus: -1,
        payPalEmailNotes: '',
        additionalEmails: '',
        pricingNotes: '',
        customerNotes: ''
    });

    const [disable, setDisable] = React.useState(false);


    // We have used the values as useState hooks to prevent the order info along with  loading method
    //we will set the methd based on the users input and html component behaviour
    const [values, setValues] = React.useState({
        orderID: 0,
        customerID: '',
        customerName: '',
        custEmail: '',
        orderType: { linkInsert: 0, newPost: 0, other: 0 },
        orderDetails: '',
        orderPaymentNotes: '',
        completedLinks: [],
        payPalInvoiceLinks: [],
        orderNotes: '',
        orderAdminNotes: '',
        orderStatus: 0,
        checkBoxItem: { needPayPalinvoice: 0, paid: 0, indexing: 0, editRequired: 0, adminReviewRequired: 0, cancel:0 }
    });


    const [buttonLinks, setButtonLinks] = React.useState(
        {
            btnLinks: "Add",
            btnPPLInvoice: "Add",
            IsLinkTextEmpty: false,
            IsPPLInvoiceTxtEmpty: false
        });
    const [btnAddUpdate, setBtnAddUpdate] = React.useState("Save");
    //Hooks end

    //Handler declartion start
    const handleNavigate = (event) => {
        ReactSession.remove("orderObject");
        navigate('/Order');
    }

    const handleToCustomerPage = (event) => {
        //ReactSession.set("custid", 0);
        //ReactSession.set("RedirectPage", 1);
        //navigate('/AddEditCustomer');
        setOpenaddedit(true);

        setCvalues({
            ...cvalues,
            customerID: 0, cName: "", cEmail: "", cStatus: -1, payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
        });

        setCformerros({
            ...cformerros, cName: "", cEmail: "", cStatus: "", payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
        });

    }

    //this method is use for calling for add new customer .
    const AddCustomer = async (event, CustId) => {
        setCformerros(validateCustomer(cvalues));
        if (Object.keys(cformerros).length === 0) {
            await adduserdata();
        }
    }

       //This is the Web API Post method the call the Save method and we are sending the Customer information  as the parameter of the post
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const adduserdata = () => {

        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        if (Object.keys(cvalues).length > 0) {
            const requestOptions = {
                method: 'POST',
                /*headers: { 'Content-Type': 'application/json' },*/
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ CName: cvalues.cName, CEmail: cvalues.cEmail, CStatus: cvalues.cStatus, CustomerID: cvalues.customerID, payPalEmailNotes: cvalues.payPalEmailNotes, additionalEmails: cvalues.additionalEmails, pricingNotes: cvalues.pricingNotes, customerNotes: cvalues.customerNotes })
            };

            fetch(`customerinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                        }
                        else {
                            setState({ ...state, snackopen: true, message: "Add Custumer Sucessfully.", severity: 'success' });
                        }
                    }
                    setCvalues({
                        ...cvalues,
                        customerID: 0, cName: "", cEmail: "", cStatus: -1, payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
                    });
                    setOpenaddedit(false);
                    //Seterromessage('Save successful');
                })
                .catch(error => {
                    //Seterromessage('error');
                    setState({ ...state, snackopen: true, message: error });
                    console.error('There was an error!', error);
                });
        }
    }

    }
     //this method is used for Applying validation on customer Email we sholud selecte customer email while adding new order.
    const handleCustomerEmailValidation = (event, value, reason) => {

        if (reason === "clear") {
            formerros.IsCustomerEmail = false;
            setValues({ ...values, ['custEmail']: '' });
        }

        //if (event.target.value == '') {
        //    formerros.IsCustomerEmail = false;
        //    setValues({ ...values, ['custEmail']: '' });
        //}
    }


    //this method is used for Applying validation on customer means we sholud selecte customer while adding new order.
    const handleCustomerValidation = (event, value, reason) => {

        if (reason === "clear") {
            formerros.IsCompany = false;
            setValues({ ...values, ['customerName']: '' });
        }

        //if (event.target.value == '') {
        //    formerros.IsCompany = false;
        //    setValues({ ...values, ['customerName']: '' });
        //}
    }

    const handleCall = async (event, args) => {
        if (args !== null || args !== 'undefined') {

            var selctedObject = emailandcompanys.filter(function (element) {
                return (element.company === args);
            });


            if (selctedObject !== null) {

                if (!Number(selctedObject[0].customerID)) {

                    setOpen(false);
                    setValues({ ...values, ['customerName']: '' });

                } else {

                    if (Number(selctedObject[0].customerID) > 0) {
                        getCustomerByID(selctedObject[0].customerID,0);
                        await getCustomerEmail(selctedObject[0].customerID);
                        setValues({ ...values, ['customerName']: selctedObject[0].company });
                    } else {

                        setValues({ ...values, ['customerName']: '' });
                        setOpen(false);
                    }
                }
            }
        }
    }

    const getCustomerByID = async (id, id1) => {
        ReactSession.setStoreType("sessionStorage");
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        fetch(`customerinfo/GetCustomerByID/${id}/${id1}`, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    document.getElementById("bxCust").style.display = "none";
                }
                else {

                    if (typeof (data) == "string") {
                        document.getElementById("bxCust").style.display = "none";
                        setState({ ...state, snackopen: true, message: data, severity: 'error' });
                    }
                    else {
                        setValuesCust({
                            ...valuesCust,
                            cName: data.cName, cEmail: data.cEmail, cStatus: data.cStatus, payPalEmailNotes: data.payPalEmailNotes, additionalEmails: data.additionalEmails, pricingNotes: data.pricingNotes, customerNotes: data.customerNotes
                        });
                        document.getElementById("bxCust").style.display = "block";
                    }
                }
            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
                document.getElementById("bxCust").style.display = "none";
            });
    }
    }
    const handleCallCustomerEmail = async (event, args) => {

        if (args !== null || args !== 'undefined') {

            var selctedObject = options.filter(function (element) {
                return (element.email === args);
            });

            setValues({ ...values, custEmail: selctedObject[0].email });
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    //this method will call when OnChange of customer field will be called and it will set the properties value accordingly
    //we are have made it dynamically using prop.
    const handleChangeForCustomer = (prop) => (event) => {
        setCvalues({ ...cvalues, [prop]: event.target.value });
        //console.log(values);
    };

    const handleOnChangeCheckBox = (event, prop) => {
        var IsChecked = event.target.checked;
        //var value = event.target.value;
        // //console.log(IsChecked + " " + value);
        setValues({ ...values, checkBoxItem: { ...values.checkBoxItem, [prop]: IsChecked === true ? 1 : 0 } });
        //console.log(values);
    };

    const handleOnChangeCheckBoxorderType = (event, prop) => {
        var IsChecked = event.target.checked;
        setValues({ ...values, orderType: { ...values.orderType, [prop]: IsChecked === true ? 1 : 0 } });
    };

    const handlecompletedLinksAdd = (event) => {

        if (tempcompletedLinks.linkName == "") {
            setButtonLinks({ ...buttonLinks, ['IsLinkTextEmpty']: true });
            return;

        } else {
            setButtonLinks({ ...buttonLinks, ['IsLinkTextEmpty']: false });
        }

        if (buttonLinks.btnLinks.toLowerCase() == "add") {
            var date = new Date();
            var incrementalValue = date.getTime();
            var value = createData(tempcompletedLinks.linkName, incrementalValue);

            setCompletedLinks([...completedLinks, value]);
            const newArr = completedLinks;
            newArr.push(value);

            setValues({ ...values, ['completedLinks']: newArr });
            setTempcompletedLinks({ linkName: '', id: 0 });
            setButtonLinks({ ...buttonLinks, ['IsLinkTextEmpty']: false });
        } else {

            var OldArr = values.completedLinks;

            //Find the new link in old array if its exist or not

            if (tempcompletedLinks.id > 0 && tempcompletedLinks.linkName != "") {

                const isFound = OldArr.some(obj => {
                    if (obj.linkName === tempcompletedLinks.linkName && obj.id != tempcompletedLinks.id) {
                        return true;
                    }
                    return false;
                });

                if (isFound) {
                    setState({ ...state, snackopen: true, message: "Name is already exist...", severity: 'warning' });

                } else {

                    const arrayList = OldArr;
                    arrayList.forEach((element, index) => {

                        if (arrayList[index].id === tempcompletedLinks.id) {
                            arrayList[index].linkName = tempcompletedLinks.linkName;
                        }
                    });

                    setValues({ ...values, ['completedLinks']: arrayList });
                    setButtonLinks({ ...buttonLinks, ['btnLinks']: "Add" });
                    setTempcompletedLinks({ linkName: '', id: 0 });
                    setButtonLinks({ ...buttonLinks, ['IsLinkTextEmpty']: false });
                }
            } else {
                setState({ ...state, snackopen: true, message: "Please contact to admin", severity: 'warning' });
            }

        }

    }

    const handlePayPallinksAdd = (event) => {

        if (temppayPalInvoiceLinks.linkName == "") {
            setButtonLinks({ ...buttonLinks, ['IsPPLInvoiceTxtEmpty']: true });
            return;

        } else {
            setButtonLinks({ ...buttonLinks, ['IsPPLInvoiceTxtEmpty']: false });
        }

        if (buttonLinks.btnPPLInvoice.toLowerCase() == "add") {
            var date = new Date();
            var incrementalValue = date.getTime();
            var value = createData(temppayPalInvoiceLinks.linkName, incrementalValue);
            setpayPalInvoiceLinks([...payPalInvoiceLinks, value]);
            const newArr = payPalInvoiceLinks;
            newArr.push(value);
            setValues({ ...values, ['payPalInvoiceLinks']: newArr });
            setTemppayPalInvoiceLinks({ linkName: '', id: 0 });
            setButtonLinks({ ...buttonLinks, ['IsPPLInvoiceTxtEmpty']: false });
        } else {

            var OldArr = values.payPalInvoiceLinks;

            if (temppayPalInvoiceLinks.id > 0 && temppayPalInvoiceLinks.linkName != "") {
                const isFound = OldArr.some(obj => {
                    if (obj.name === temppayPalInvoiceLinks.linkName && obj.id != temppayPalInvoiceLinks.id) {
                        return true;
                    }
                    return false;
                });

                if (isFound) {
                    setState({ ...state, snackopen: true, message: "Name is already exist...", severity: 'warning' });
                } else {

                    const arrayList = OldArr;
                    arrayList.forEach((element, index) => {
                        if (arrayList[index].id === temppayPalInvoiceLinks.id) {

                            arrayList[index].linkName = temppayPalInvoiceLinks.linkName;
                        }
                    });

                    setValues({ ...values, ['payPalInvoiceLinks']: arrayList });
                    setButtonLinks({ ...buttonLinks, ['btnPPLInvoice']: "Add" });
                    setTemppayPalInvoiceLinks({ linkName: '', id: 0 });
                    setButtonLinks({ ...buttonLinks, ['IsPPLInvoiceTxtEmpty']: false });
                }

            } else {
                setState({ ...state, snackopen: true, message: "Please contact to admin):id set as zero", severity: 'warning' });

            }
        }
    }

    const handleChangeForcompletedLinks = (prop) => (event) => {

        setTempcompletedLinks({ ...tempcompletedLinks, [prop]: event.target.value });

    };

    const handleChangeForPaypalLinks = (prop) => (event) => {
        setTemppayPalInvoiceLinks({ ...temppayPalInvoiceLinks, [prop]: event.target.value });

    };

    //this method is used for Edit completed link.
    const handleCompletedLinkEdit = (event, id) => {
        event.preventDefault();
        if (id > 0) {

            var arrayData = completedLinks;
            var newArray = arrayData.filter(function (element) {
                return (element.id === id);
            });

            var name = newArray[0].linkName;

            setTempcompletedLinks({ ...tempcompletedLinks, ['linkName']: name, ['id']: id });

            setButtonLinks({ ...buttonLinks, ['btnLinks']: "Update" });
        }
    }

    //this method is used for delete completed link.
    const handleCompletedLinkDelete = (event, id) => {

        if (id > 0) {
            var arrayData = completedLinks;

            var newArray = arrayData.filter(function (element) {
                return (element.id === id);
            });

            var index = arrayData.findIndex(function (arr) {
                return arr.id === newArray[0].id;
            });
            ;
            arrayData.splice(index, 1);

            setValues({ ...values, ['completedLinks']: arrayData });
            setButtonLinks({ ...buttonLinks, ['btnLinks']: "Add" });
        }
    }

     //this method is used for Edit PayPal Invoice link.
    const handlePapPalInvoiceLinkEdit = (event, id) => {
        event.preventDefault();
        if (id > 0) {

            var arrayData = payPalInvoiceLinks;

            var newArray = arrayData.filter(function (element) {
                return (element.id === id);
            });

            var name = newArray[0].linkName;

            setTemppayPalInvoiceLinks({ ...temppayPalInvoiceLinks, ['linkName']: name, ['id']: id });
            setButtonLinks({ ...buttonLinks, ['btnPPLInvoice']: "Update" });
        }
    }

    //this method is used for Delet PayPal Invoice link.
    const handlePapPalInvoiceLinkDelete = (event, id) => {
        // //console.log("Hi");
        if (id > 0) {
            ////console.log("id  " + id);
            var arrayData = payPalInvoiceLinks;
            ////console.log("array len  " + arrayData.length);

            var newArray = arrayData.filter(function (element) {
                return (element.id === id);
            });
            ////console.log("new array len " + newArray.length + "  array prop " + newArray[0].id + " " + newArray[0].name);

            var index = arrayData.findIndex(function (arr) {
                return arr.id === newArray[0].id;
            });

            arrayData.splice(index, 1);

            setValues({ ...values, ['linkName']: arrayData });
            setButtonLinks({ ...buttonLinks, ['btnPPLInvoice']: "Add" });
            setTemppayPalInvoiceLinks({ linkName: '', id: 0 });
        }
    }

    const handleFinalSave = async (event) => {
        event.preventDefault();
        //console.log(formerros);

        if (validate(values) == true) {
            await addOrderData();
        } else {
            //console.log(formerros);
            // alert("Error");
        }
    }

    const handleClose = () => {
        setState({ ...state, snackopen: false, message: '' });
    };

    const handleClosepopup = () => setOpenaddedit(false);

    //Handler end

    //Hooks start

    React.useEffect(() => {

        ReactSession.setStoreType("localStorage");
        const orderClassObject = JSON.parse(ReactSession.get('orderObject'));
        //console.log("Session Value->");
        // console.log(orderClassObject);
        async function fetchCustomerData() {
            ReactSession.setStoreType("sessionStorage");
            if (orderClassObject.customerName !== '') {
                const a = await getCompanyName(1, 1);
                if (a) {

                    fetchCustomerEmails();
                }
                
            }

        }

        async function fetchCustomerEmails() {

            if (orderClassObject.custEmail !== '') {

                var selctedObject = emailandcompanys.filter(function (element) {
                    return (element.company === orderClassObject.customerName);
                });

                if (selctedObject.length > 0) {
                    const b = await getCustomerEmail(selctedObject[0].customerID);
                }                
            }

        }

        if (orderClassObject) {
            fetchCustomerData();
           

            if (orderClassObject.orderStatus === 1) {
                setDisable(true);
            } else {
                setDisable(false);
            }
            setBtnAddUpdate("Update");

            const _orderType = {
                linkInsert: orderClassObject.orderType.linkInsert,
                newPost: orderClassObject.orderType.newPost,
                other: orderClassObject.orderType.other
            };


            setCompletedLinks(orderClassObject.completedLinks);
            setpayPalInvoiceLinks(orderClassObject.payPalInvoiceLink);

            const _checkboxitem = {
                needPayPalinvoice: orderClassObject.checkboxitem.needpaypalinvoice,
                paid: orderClassObject.checkboxitem.paid,
                indexing: orderClassObject.checkboxitem.indexing,
                editRequired: orderClassObject.checkboxitem.editRequired,
                adminReviewRequired: orderClassObject.checkboxitem.adminReviewRequired,
                cancel: orderClassObject.checkboxitem.cancel
            };
            setValues({
                ...values,
                orderID: orderClassObject.orderID,
                customerName: orderClassObject.customerName,
                custEmail: orderClassObject.custEmail,
                orderDetails: orderClassObject.orderDetails,
                orderPaymentNotes: orderClassObject.orderPaymentNotes,
                orderNotes: orderClassObject.orderNotes,
                orderAdminNotes: orderClassObject.orderAdminNotes,
                orderType: _orderType,
                orderStatus: orderClassObject.orderStatus,
                completedLinks: orderClassObject.completedLinks,
                payPalInvoiceLinks: orderClassObject.payPalInvoiceLinks,
                checkBoxItem: _checkboxitem
            });

            getCustomerByID(0,orderClassObject.orderID);
        }
    }, []);

    React.useEffect(() => {
        let active = true;

        if (!loadingec) {
            return undefined;
        }

        (async () => {
            if (active) {
                await getCompanyName(1);
            }
        })();

        return () => {
            active = false;
        };
    }, [loadingec]);

    React.useEffect(() => {
        ReactSession.setStoreType("sessionStorage");
        if (!ecopen) {
            setEmailandcompanys([]);
        }
    }, [ecopen]);

    React.useEffect(() => {
        // console.log(values);
        ReactSession.setStoreType("sessionStorage");
    }, [values], [completedLinks], [tempcompletedLinks], [payPalInvoiceLinks], [temppayPalInvoiceLinks], [buttonLinks]);
    //Hooks end

    //this API menthod GetCustomerandCompany is used to get Customer Information on AddEdit order page.
    const getCompanyName = async (id) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        try {
            /* const response = await fetch(`orderinfo/GetCustomerandCompany/${id}`);*/
            const response = await fetch(`orderinfo/GetCustomerandCompany/${id}`, { headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }});
            const data = await response.json();
            if (data) {
                const onlyCustomeNames = data.map((option) => option.company);
                setArrayofcompany(onlyCustomeNames);
                setEmailandcompanys(data);
            } else {

                setState({ ...state, snackopen: true, message: "Issue while retrieving the customer information..", severity: 'error' });
            }
        } catch (error) {
            setState({ ...state, snackopen: true, message: error, severity: 'error' });
        }
    }
    }
    //this API menthod GetCustomerEmailandCompany is used to get Customer Email base on selected customer on AddEdit order page.
    const getCustomerEmail = async (id) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        try {
            const response = await fetch(`orderinfo/GetCustomerEmailandCompany/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
         });
            const data = await response.json();
            if (data) {
                const onlyEmails = data.map((option) => option.email);
                setArrayofemails(onlyEmails);
                setOptions(data);
                setOpen(true);

            } else {
                setState({ ...state, snackopen: true, message: "There is no email exist for selected company", severity: 'warning' });
                setOptions([]);
                setOpen(false);
            }

        } catch (error) {
            //alert("Error is coming inside the getUserData and the errir is - >" + error);
            setState({ ...state, snackopen: true, message: error, severity: 'error' });
        }
        }
    }

    const addOrderData = async () => {

        // if (Object.keys(values).length > 0) {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(
                {
                    orderID: values.orderID,
                    customerName: values.customerName,
                    custEmail: values.custEmail,
                    orderType: values.orderType,
                    orderDetails: values.orderDetails,
                    orderPaymentNotes: values.orderPaymentNotes,
                    completedLinks: values.completedLinks,
                    payPalInvoiceLink: values.payPalInvoiceLinks,
                    orderNotes: values.orderNotes,
                    orderAdminNotes: values.orderAdminNotes,
                    checkBoxItem: values.checkBoxItem,
                    createdby: 1,
                    updatedby: 1
                })
        };

        fetch(`orderinfo`, requestOptions)
            .then(async response => {
                const data = await response.json();
                //console.log(data);
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    return Promise.reject(error);
                } else {
                    setState({ ...state, snackopen: true, message: 'Data Saved Successfully..', severity: 'success' });
                    ReactSession.remove("orderObject");
                    navigate('/Order');
                }

            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
                //console.error('There was an error!', error);
            });
        //}
    }
    }
    //API end

    //Validation

    // this method is use for applying validation on orders field.
    const validate = (values) => {

        let IsAnyError = true;
        const errors = {};
        const regex1 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const regexcomma = /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/;


        values.orderDetails !== "" ? errors.IsValidorderDetails = false : errors.IsValidorderDetails = true;

        values.orderPaymentNotes !== "" ? errors.IsorderPaymentNotes = false : errors.IsorderPaymentNotes = true;

        values.orderNotes !== "" ? errors.IsorderNotes = false : errors.IsorderNotes = true;

        values.orderAdminNotes !== "" ? errors.IsNotesToAdmin = false : errors.IsNotesToAdmin = true;

        values.customerName !== "" ? errors.IsCompany = false : errors.IsCompany = true

        values.custEmail !== "" ? errors.IsCustomerEmail = false : errors.IsCustomerEmail = true


        setFormerros(errors);

        if (errors.IsCompany == true || errors.IsCustomerEmail == true || errors.IsValidorderDetails == true || errors.IsorderPaymentNotes == true || errors.IsorderNotes == true || errors.IsNotesToAdmin == true) {

            IsAnyError = false;
        }

        return IsAnyError;
    }

     // this method is use for applying validation on Customer field.
    const validateCustomer = (cvalues) => {
        /*alert("Entry Point in Validate function");*/

        const errors = {};
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const regexcomma = /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/;



        if (!cvalues.cName) {
            errors.cName = "Customer Name is required.";
        }



        if (!cvalues.cEmail) {
            errors.cEmail = "Email is required.";
        }
        else if (!regex.test(cvalues.cEmail)) {
            errors.cEmail = "Invalid Email.";
        }


        if (cvalues.cStatus == -1) {
            errors.cStatus = "Status is required.";
        }

        if (!cvalues.payPalEmailNotes) {
            errors.payPalEmailNotes = "PayPal Email Notes is required.";
        }

        if (!cvalues.additionalEmails) {
            errors.additionalEmails = "Additional Emails is required.";
        }
        else if (!regexcomma.test(cvalues.additionalEmails)) {
            errors.additionalEmails = "Invalid Email."
        }

        if (!cvalues.pricingNotes) {
            errors.pricingNotes = "Pricing Notes is required.";
        }

        if (!cvalues.customerNotes) {
            errors.customerNotes = "Customer Notes is required.";
        }




        if (Object.values(errors).every(val => val === "")) {
            adduserdata();
        }


        return errors;

    }

    //Validation end

    // this method is used for send alert to customer when order is completed.
    //Base on Email type we send alert to customer if email type is 2 then it means order completed with paypal and if it 3 then order completed without paypal invoice.
    const handleSendOrderCompletetionEmail = async (event) => {
        if (payPalInvoiceLinks.length > 0) {
            await SendOrderCompletetionEmail(values.orderID, values.custEmail, 2);
        }
        else {
            await SendOrderCompletetionEmail(values.orderID, values.custEmail, 3);
        }
    }

    //this is web api SendMailByEmailType method.
    const SendOrderCompletetionEmail = async (orderId, custEmail, emailType) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        fetch(`orderinfo/SendMailByEmailType/${orderId}/${custEmail}/${emailType}`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {

                    if (typeof (data) == "object") {
                        setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                    }
                    else {
                        /*setState({ ...state, snackopen: true, message: 'Send Order Completetion Email Successfully.', severity: 'success' });*/
                        if (emailType === 2) {
                            setState({ ...state, snackopen: true, message: 'Order Completed With PayPal Successfully.', severity: 'success' });
                            setDisable(true);
                        }
                        else if (emailType === 3) {
                            setState({ ...state, snackopen: true, message: 'Order Completed Without PayPal Successfully.', severity: 'success' });
                            setDisable(true);
                        }
                    }
                }
            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }
    }
   // const FullName = (typeof ReactSession.get("UserName") !== 'undefined' ? 'Welcome ' + ReactSession.get("UserName") + ' !' : '');

    //return is having the form controls which we can see when we run the page.
    return <>

        <div>
            <Modal
                open={openaddedit}
                onClose={handleClosepopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={OVERLAY_STYLE}
            >
                <Box sx={style1}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Customer
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <table style={{ "width": "100%" }} >
                            <tr style={styletrrd} >
                                <td style={styletrrd}>
                                    <FormControl fullWidth sx={{ m: 1 }} error={cformerros.cEmail} >
                                        <InputLabel htmlFor="outlined-adornment-lastname">Customer Primary Email</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-lastname"
                                            label="Email"
                                            value={cvalues.cEmail}
                                            onChange={handleChangeForCustomer('cEmail')}
                                        />
                                        <FormHelperText>{cformerros.cEmail}</FormHelperText>
                                    </FormControl>
                                </td>
                                <td rowSpan="2" style={styletrrd}>
                                    <FormControl fullWidth sx={{ m: 1 }} error={cformerros.pricingNotes} >
                                        <InputLabel htmlFor="outlined-adornment-firstname">Pricing Notes</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-firstname"
                                            label="Pricing Notes"
                                            multiline
                                            rows={6}
                                            value={cvalues.pricingNotes}
                                            onChange={handleChangeForCustomer('pricingNotes')}
                                        />
                                        <FormHelperText>{cformerros.pricingNotes}</FormHelperText>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr style={styletrrd}>
                                <td style={styletrrd}>
                                    <FormControl fullWidth sx={{ m: 1 }} error={cformerros.cName} >
                                        <InputLabel htmlFor="outlined-adornment-firstname">Company Name</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-firstname"
                                            label="Customer Name"
                                            value={cvalues.cName}
                                            onChange={handleChangeForCustomer('cName')}
                                        />
                                        <FormHelperText>{cformerros.cName}</FormHelperText>
                                    </FormControl>
                                </td>
                            </tr>

                            <tr style={styletrrd}>
                                <td style={styletrrd}>

                                    <FormControl fullWidth sx={{ m: 1 }} error={cformerros.payPalEmailNotes} >
                                        <InputLabel htmlFor="outlined-adornment-firstname">PayPal Email Notes</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-firstname"
                                            label="PayPal Email Notes"
                                            multiline
                                            rows={3}
                                            value={cvalues.payPalEmailNotes}
                                            onChange={handleChangeForCustomer('payPalEmailNotes')}
                                        />
                                        <FormHelperText>{cformerros.payPalEmailNotes}</FormHelperText>
                                    </FormControl>
                                </td>
                                <td rowSpan="2" style={styletrrd}>
                                    <FormControl fullWidth sx={{ m: 1 }} error={cformerros.customerNotes} >
                                        <InputLabel htmlFor="outlined-adornment-firstname">Customer Notes</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-firstname"
                                            label="Customer Notes"
                                            multiline
                                            rows={7}
                                            value={cvalues.customerNotes}
                                            onChange={handleChangeForCustomer('customerNotes')}
                                        />
                                        <FormHelperText>{cformerros.customerNotes}</FormHelperText>
                                    </FormControl>
                                </td>
                            </tr>

                            <tr style={styletrrd}>
                                <td style={styletrrd}>
                                    <FormControl fullWidth sx={{ m: 1 }} error={cformerros.additionalEmails} >
                                        <InputLabel htmlFor="outlined-adornment-firstname">Additional Emails</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-firstname"
                                            label="Additional Emails"
                                            value={cvalues.additionalEmails}
                                            onChange={handleChangeForCustomer('additionalEmails')}
                                        />
                                        <FormHelperText>{cformerros.additionalEmails}</FormHelperText>
                                    </FormControl>
                                </td>

                            </tr>

                            <tr style={styletrrd}>
                                <td style={styletrrd}>  <FormControl fullWidth sx={{ m: 1 }} error={cformerros.cStatus}>
                                    <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={cvalues.cStatus}
                                        label="Role"
                                        onChange={handleChangeForCustomer('cStatus')}
                                    >
                                        <MenuItem value={-1} >
                                            Select
                                        </MenuItem>
                                        <MenuItem value={1}>Active</MenuItem>
                                        <MenuItem value={0}>Banned</MenuItem>
                                    </Select>
                                    <FormHelperText>{cformerros.cStatus}</FormHelperText>
                                </FormControl></td>
                            </tr>
                        </table>
                        <Stack direction="row" align="center" spacing={2} >
                            <Button color="success" variant="contained" onClick={(event) => AddCustomer(event, 0)}>Save</Button>
                            <Button variant="contained" onClick={() => handleClosepopup()} color="success">
                                Cancel
                            </Button>
                        </Stack>
                    </Typography>
                </Box>
            </Modal>
        </div>

       


        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '5px' }} >
            <Button variant="contained" color="success" onClick={(event) => handleNavigate(event)}>Go back to Order dashboard</Button>
        </div>

      

        <div style={floatcontainer} >
            <div style={floatchild}>
                <table style={{ border: '1px solid white', width: '100%' }} >
                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Customer</Typography>
                        </td>
                        <td style={{ border: '1px solid white' }}> <Autocomplete
                            id="asynchronous-company"
                            sx={{ m: 1, width: '50ch' }}
                            /*value={values.customerName}*/

                            onChange={(event, newValue) => {
                                handleCall(event, newValue);
                            }}

                            onInputChange={(event, newValue, reason) => handleCustomerValidation(event, newValue, reason)}


                            open={ecopen}
                            onOpen={() => {
                                setEcopen(true);
                            }}
                            onClose={() => {
                                setEcopen(false);
                            }}
                            // isOptionEqualToValue={(emailandcompany, value) => emailandcompany.company === value.company}
                            // getOptionLabel={(emailandcompany) => emailandcompany.company}
                            options={arrayofcompany}
                            value={values && values.customerName && values.customerName.length ? values.customerName : ''}
                            loading={loadingec}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search on company"
                                    error={formerros.IsCompany}
                                    helperText={formerros.IsCompany && 'Company is required.'}

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingec ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        /></td>

                    </tr>
                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Customer Email</Typography></td>
                        <td style={{ border: '1px solid white' }}><Autocomplete
                            id="asynchronous-customer-email"
                            sx={{ m: 1, width: '50ch' }}
                            open={open}
                            //value={values.email}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}

                            onInputChange={(event, newValue, reason) => handleCustomerEmailValidation(event, newValue, reason)}

                            onChange={(event, newValue) => {

                                handleCallCustomerEmail(event, newValue);

                            }}
                            // isOptionEqualToValue={(option, value) => option.email === value.email}
                            //getOptionLabel={(option) => option.email}
                            options={arrayofemails}
                            value={values && values.custEmail && values.custEmail.length ? values.custEmail : ''}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search on email"
                                    error={formerros.IsCustomerEmail}
                                    helperText={formerros.IsCustomerEmail && 'Email is required.'}

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        /> </td>

                    </tr>
                    <tr style={{ border: '1px solid white' }}>

                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Order Type</Typography></td>

                        <td style={{ border: '1px solid white' }}>
                            <FormGroup row>
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBoxorderType(event, 'linkInsert')} value="Link Insert" control={<Checkbox checked={(values.orderType.linkInsert === 0) ? false : true} />} label="Link Insert" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBoxorderType(event, 'newPost')} value="New Post" control={<Checkbox checked={(values.orderType.newPost === 0) ? false : true} />} label="New Post" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBoxorderType(event, 'other')} value="Other" control={<Checkbox checked={(values.orderType.other === 0) ? false : true} />} label="Other" />
                            </FormGroup>
                        </td>

                    </tr>

                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Order Details</Typography></td>
                        <td style={{ border: '1px solid white' }}>
                            <TextField
                                id="outlined-textarea-orderDetails"
                                label=""
                                placeholder=""
                                multiline
                                sx={{ m: 1, width: '50ch' }}
                                value={values.orderDetails}
                                onChange={handleChange('orderDetails')}
                                error={formerros.IsValidorderDetails}
                                helperText={formerros.IsValidorderDetails && 'Order Details is required'}
                            />
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid white' }}>

                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Order Payment Notes</Typography></td>

                        <td style={{ border: '1px solid white' }}><TextField
                            id="outlined-textarea-orderPaymentNotes"
                            label=""
                            placeholder=""
                            multiline
                            sx={{ m: 1, width: '50ch' }}
                            value={values.orderPaymentNotes}
                            onChange={handleChange('orderPaymentNotes')}
                            error={formerros.IsorderPaymentNotes}
                            helperText={formerros.IsorderPaymentNotes && 'Order Payment Notes is Required'}
                        />
                        </td>

                    </tr>
                    <tr style={{ border: '1px solid white' }}>

                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Completed Links</Typography></td>

                        <td style={{ border: '1px solid white' }}><TextField
                            id="outlined-textarea-completedLinks"
                            label=""
                            placeholder=""
                            multiline
                            sx={{ m: 1, width: '50ch' }}
                            value={tempcompletedLinks.linkName}
                            onChange={handleChangeForcompletedLinks('linkName')}
                            error={buttonLinks.IsLinkTextEmpty}
                            helperText={buttonLinks.IsLinkTextEmpty && 'Value Can not be empty'}
                        /></td>

                    </tr>
                    <tr style={{ border: '1px solid white' }}>

                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom> &nbsp;</Typography></td>

                        <td style={{ border: '1px solid white' }}>
                            <Button variant="contained" color="success" onClick={(event) => handlecompletedLinksAdd(event)}>{buttonLinks.btnLinks}</Button>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell ><b>Link</b></TableCell>
                                            <TableCell align="right"><b>Edit</b></TableCell>
                                            <TableCell align="right"><b>Delete</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {completedLinks.map((completedlink) => (
                                            <TableRow
                                                key={completedlink.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {completedlink.linkName}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <EditIcon cursor="pointer" onClick={(event) => handleCompletedLinkEdit(event, completedlink.id)} ></EditIcon></TableCell>
                                                <TableCell align="right">
                                                    <DeleteForeverRoundedIcon onClick={(event) => handleCompletedLinkDelete(event, completedlink.id)} cursor="pointer" />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}><Typography variant="h6" gutterBottom>PayPal Invoice Link</Typography></td>
                        <td style={{ border: '1px solid white' }}><TextField
                            id="outlined-textarea-payPalInvoiceLink"
                            label=""
                            placeholder=""
                            multiline
                            sx={{ m: 1, width: '50ch' }}
                            onChange={handleChangeForPaypalLinks('linkName')}
                            value={temppayPalInvoiceLinks.linkName}
                            error={buttonLinks.IsPPLInvoiceTxtEmpty}
                            helperText={buttonLinks.IsPPLInvoiceTxtEmpty && 'Value Can not be empty'}
                        /></td>

                    </tr>
                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom> &nbsp;</Typography>
                        </td>
                        <td style={{ border: '1px solid white' }}>
                            <Button variant="contained" color="success" onClick={(event) => handlePayPallinksAdd(event)} >{buttonLinks.btnPPLInvoice}</Button>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell ><b>Link</b></TableCell>
                                            <TableCell align="right"><b>Edit</b></TableCell>
                                            <TableCell align="right"><b>Delete</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payPalInvoiceLinks.map((payPalInvoiceLink) => (
                                            <TableRow
                                                key={payPalInvoiceLink.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {payPalInvoiceLink.linkName}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <EditIcon cursor="pointer" onClick={(event) => handlePapPalInvoiceLinkEdit(event, payPalInvoiceLink.id)} ></EditIcon></TableCell>
                                                <TableCell align="right">
                                                    <DeleteForeverRoundedIcon onClick={(event) => handlePapPalInvoiceLinkDelete(event, payPalInvoiceLink.id)} cursor="pointer" />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Order Notes</Typography>
                        </td>
                        <td style={{ border: '1px solid white' }}><TextField
                            id="outlined-textarea-orderNotes"
                            label=""
                            placeholder=""
                            multiline
                            sx={{ m: 1, width: '50ch' }}
                            value={values.orderNotes}
                            onChange={handleChange('orderNotes')}
                            error={formerros.IsorderNotes}
                            helperText={formerros.IsorderNotes && 'Order Notes is Required'}
                        /></td>
                    </tr>
                    <tr style={{ border: '1px solid white' }}>
                        <td style={{ border: '1px solid white' }}>
                            <Typography variant="h6" gutterBottom>Notes to Admin</Typography>
                        </td>
                        <td style={{ border: '1px solid white' }}><TextField
                            id="outlined-textarea-NotestoAdmin"
                            label=""
                            placeholder=""
                            multiline
                            sx={{ m: 1, width: '50ch' }}
                            value={values.orderAdminNotes}
                            onChange={handleChange('orderAdminNotes')}
                            error={formerros.IsNotesToAdmin}
                            helperText={formerros.IsNotesToAdmin && 'Notes to Admin is Required'}
                        />
                        </td>
                    </tr>
                    <tr style={{ border: '1px solid white' }}>
                        <td colSpan="2" style={{ border: '1px solid white' }}>
                            <FormGroup row >
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBox(event, 'needPayPalinvoice')} value="Need PayPal Invoice" control={<Checkbox checked={(values.checkBoxItem.needPayPalinvoice === 0) ? false : true} />} label="Need PayPal Invoice" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBox(event, 'paid')} value="Paid" control={<Checkbox checked={(values.checkBoxItem.paid === 0) ? false : true} />} label="Paid" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBox(event, 'indexing')} value="Indexing" control={<Checkbox checked={(values.checkBoxItem.indexing === 0) ? false : true} />} label="Indexing" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBox(event, 'editRequired')} value="Edit Required" control={<Checkbox checked={(values.checkBoxItem.editRequired === 0) ? false : true} />} label="Edit Required" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBox(event, 'adminReviewRequired')} value="Admin Review Required" control={<Checkbox checked={(values.checkBoxItem.adminReviewRequired === 0) ? false : true} />} label="Admin Review Required" />
                                <FormControlLabel onChange={(event) => handleOnChangeCheckBox(event, 'cancel')} value="Cancel" control={<Checkbox checked={(values.checkBoxItem.cancel === 0) ? false : true} />} label="Cancel" />
                            </FormGroup>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <Button variant="contained" color="success" disabled={disable} onClick={(event) => handleFinalSave(event)}>{btnAddUpdate}</Button> &nbsp;
                            <Button variant="contained" color="success" disabled={disable} onClick={(event) => handleSendOrderCompletetionEmail(event)}>Send Order Completetion Email </Button>
                        </td>
                    </tr>
                </table>
            </div>
            <br></br>
            <div style={floatchild}>
                <div style={{ height: '61px', paddingTop: '6px' }} >
                    <Button variant="contained" color="success" onClick={(event) => handleToCustomerPage(event)}>Add New</Button>
                </div>
                <Box id="bxCust" style={{ display: 'none' }}
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        borderRadius: 4,
                        //p: 20,
                        minWidth: 400,
                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                        color: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                        border: '1px solid',
                        borderColor: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',                       
                    }}>
                    <table>
                        <tr>
                            <td>
                                <Typography variant="h6" gutterBottom>&nbsp; Customer : </Typography>
                            </td>
                            <td>
                                <Typography gutterBottom>{valuesCust.cName}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="h6" gutterBottom>&nbsp; Customer Primary Email : </Typography>
                            </td>
                            <td>
                                <Typography gutterBottom>{valuesCust.cEmail}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="h6" gutterBottom>&nbsp; PayPal Email Notes : </Typography>
                            </td>
                            <td>
                                <Typography gutterBottom>{valuesCust.payPalEmailNotes}</Typography>
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>*/}
                        {/*        <Typography variant="h6" gutterBottom>&nbsp; Additional Emails : </Typography>*/}
                        {/*    </td>*/}
                        {/*    <td>*/}
                        {/*        <Typography variant="h6" gutterBottom>{valuesCust.additionalEmails}</Typography>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>
                                <Typography variant="h6" gutterBottom>&nbsp; Pricing Notes : </Typography>
                            </td>
                            <td>
                                <Typography gutterBottom>{valuesCust.pricingNotes}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="h6" gutterBottom>&nbsp; Customer Notes : </Typography>
                            </td>
                            <td>
                                <Typography gutterBottom>{valuesCust.customerNotes}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="h6" gutterBottom>&nbsp; Status : </Typography>
                            </td>
                            <td>
                                <Typography gutterBottom>{(valuesCust.cStatus) == 1 ? ("Active") : ("Banned")}</Typography>
                            </td>
                        </tr>
                    </table>
                </Box>
            </div>
        </div>

        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={6000}
            open={snackopen}
            onClose={handleClose}
            key={vertical + horizontal}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    </>
}

export { AddEditOrder }
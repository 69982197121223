import { useState } from 'react';
import '../UI/styles.css';
import * as React from 'react';
import Card from '../UI/Card';

const SearchFilter = (props) => {
    const [filterFrom, setFilterFrom] = useState('');
    const [filterCustomerEmail, setFilterCustomerEmail] = useState('');
    const [paidChecked, setPaidChecked] = useState(2);
    const [sentChecked, setSentChecked] = useState(2);
    const [filterOrderLink, setFilterOrderLink] = useState('');
    const searchByFromEmail = async (event) => {
        setFilterFrom(event.target.value);
        //await props.filterByFrom(event.target.value);

    }

    /*React.useEffect(() => {
        props.onSearch('', '', 2, 2, '');
    }, []);*/
    const searchByCustomerEmail = (event) => {
        setFilterCustomerEmail(event.target.value);
        
        //props.filterByCustomerEmail(event.target.value);
    }

    const searchByOrderLinks = (event) => {
        setFilterOrderLink(event.target.value);
    }

    const searchPaidFilter = (event) => {
        
        const value = event.target.value;        
        setPaidChecked(value);
        //await props.filterByPaid(value);

    }
    const search = (event) => {
        event.preventDefault();
        props.onSearch(filterFrom, filterCustomerEmail, paidChecked, sentChecked, filterOrderLink);
    }
    const searchSentFilter = async (event) => {
               
        setSentChecked(event.target.value);
        //await props.filterBySend(event.target.value);
    }
    return (
        <>
            <Card>
                    <div className="row mx-md-n5">
                        <div className="col-2">
                            <div className="form-group">
                                
                            <input className="form-control" onInput={searchByFromEmail} name="searchBy" value={filterFrom} placeholder="From Email" ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                
                            <input className="form-control" onInput={searchByCustomerEmail} name="searchBy" value={filterCustomerEmail} placeholder="Customer Email" ></input>
                            </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">

                            <input className="form-control" onInput={searchByOrderLinks} name="searchByOrderLinks" value={filterOrderLink} placeholder="Order Link" ></input>
                        </div>
                    </div>
                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="" >Paid </label>&nbsp;
                                <select onChange={searchPaidFilter} defaultValue={ paidChecked} >
                                    <option value="1">Paid</option>
                                    <option value="0">Unpaid</option>
                                    <option value="2">All</option>
                                </select>
                                &nbsp;&nbsp;&nbsp;
                                <label htmlFor="" >Sent </label>&nbsp;

                                <select onChange={searchSentFilter} defaultValue={sentChecked} >
                                    <option value="1">Sent</option>
                                    <option value="0">Not Sent</option>
                                    <option value="2">All</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group">
                                <button onClick={search}>Search</button>
                            </div>
                        </div>
                    <div className="col-1">
                        </div>
                </div>
                
        </Card>
           
        </>
        
    );
}

export default SearchFilter;
import { useState, useEffect, Component } from "react";
import EditIcon from '@mui/icons-material/Edit';
import ImageGrid from "../DraftPosts/ImageGrid";
import Card from '../../UI/Card';
import DateString from '../../UI/DateString';
import Alert from '@mui/material/Alert';
import '../../UI/styles.css';
import LoadingImage from '../../UI/loading.gif';
import classes from '../../UI/Modal.module.css';
import { ReactSession } from 'react-client-session';
import RefreshIcon from '@mui/icons-material/Refresh';
import LinkColumns from './LinkColumns';
import { act } from "react";
 
const EdotPostContent = (props) => {

    const [imageURL, setImageURL] = useState('');
    const [imageNote, setImageNote] = useState('');
    const [link, setlink] = useState('');
    const [featured, setFeatured] = useState(false);
    const [postImages, setPostImages] = useState([]);
    const [imageCounter, setImageCounter] = useState(1);
    const [postNote, setPostNote] = useState('');
    const [postID, setPostID] = useState(props.postDetails[0].db_id);
    const [postNotes, setPostNotes] = useState([]);
    const [postStatus, setPostStatus] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const [postUpdating, setPostUpdating] = useState(false);
    const [postLinks, setPostlinks] = useState([]);
 
    const userId = ReactSession.get('UserID');
    useEffect(() => {
        console.log(props.viewID);
        if (props.postDetails[0].db_id > 0) {
            fetchImages(props.postDetails[0].db_id);
            fetchNotes(props.postDetails[0].db_id);
            fetchLinks();
        }

    }, []);


    useEffect(() => {
        
        setPostID(oldID => props.postDetails[0].db_id);
        fetchImages(props.postDetails[0].db_id);
        fetchNotes(props.postDetails[0].db_id);
        setAlertMessage('');
        setShowMessage(false);
        checkPostStatus();

    }, [props.postDetails]);
 

    const checkPostStatus = () => {
        
        if (props.postDetails[0].status == 0) { setPostStatus('Edit Pending'); }
        else if (props.postDetails[0].status == 1) { setPostStatus('Edit Approval Pending'); }
        else if (props.postDetails[0].status == 2) { setPostStatus('Submitted'); }
        else if (props.postDetails[0].status == 3) { setPostStatus('Approved'); }
        else if (props.postDetails[0].status == 4) { setPostStatus('Rejected'); }
        else if (props.postDetails[0].status == 5) { setPostStatus('Completed'); }
    }
    const imageURLInputHandler = (e) => {
        setImageURL(e.target.value);
    }
    const imageNoteInputHandler = (e) => {
        setImageNote(e.target.value);

    }
    const linkInputHandler = (e) => {
        setlink(e.target.value);
    }
    const onFeaturedImageClick = (e) => {
        if (e.target.checked)
            setFeatured(true);
        else
            setFeatured(false);
    }
    async function fetchNotes(post_id) {
        const data = { 'id': post_id };

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/getpostnotes', config)
            const json = await response.json()
            if (response.ok) {
                setPostNotes(json);
                setPostNotes((prevState) => {
                    return [...json];
                });
            } else {

            }
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchLinks() {
        const data = { 'id': postID };

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/getpostcontentlinks', config)
            const json = await response.json()
            if (response.ok) {
                
                setPostlinks((prevState) => {
                    return [...json];
                });
            } else {

            }
        } catch (error) {
            console.log(error);
        }
    }
    async function fetchImages(post_id) {

        const data = { 'id': post_id };

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/getpostimages', config)
            const json = await response.json()
            if (response.ok) {
                //setPostImages(json);
                setPostImages((prevState) => {

                    return [...json];
                });

            } else {

            }
        } catch (error) {
            console.log(error);
        }
    }
    const postNoteInputHandler = (e) => {
        //saveSite();
        setPostNote(e.target.value);
    }
    const onAddImageClick = async (e) => {
        console.log("Add Image");
        setPostUpdating(true);
        e.preventDefault();
        let post_id = postID;

        if (post_id != 0) {
            await updateImage(post_id);

            fetchImages(post_id);
            setImageCounter(imageCounter + 1);

            setImageURL('');
            setImageNote('');
            setFeatured(false);
            setShowMessage(true);
            setAlertMessage('Image added successfully');
        }

        setPostUpdating(false);
    }

 
    const updatePostStatus = async (event) => {
        const new_status = event.currentTarget.getAttribute("data-value");
        const action_note = event.currentTarget.getAttribute("data-note");

        

        console.log(new_status);
        setPostUpdating(true);
        
        let post = props.postDetails[0];
        if (post.status == 0 && post.status_text.toLowerCase().includes('rejected')) {
            post.status_text = "Content Reject Edited";
        }
        if (action_note.toLowerCase().includes('rejected')) {
            post.status_text = 'rejected';
        }
        post.status = new_status;
        post.updated_by = userId;
        post.content_Editing = 1;
        await updatePost(post);
        let newPostNote = postNote;
        if (action_note.length > 0) {
            newPostNote = newPostNote + "<br/>" + action_note;
        }
        updateNote(postID, newPostNote);
        fetchNotes(postID);

        setPostNote('');
        setShowMessage(true);
        setAlertMessage('Post is updated successfully.');
        props.updatePostList();
        setPostUpdating(false);

    }

 

 

    async function updatePost(post) {
        try {
            const data = post;

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/upadatepost', config)

            if (response.ok) {
                const json = await response.json()
                return json[0].id;
            } else {

            }
        } catch (error) {
            console.log(error);
            return -1;
        }
    }

    async function updateNote(post_id, post_note) {
        if (postNote.length < 1)
            return false;
        try {
            const data = {
                post_id: post_id, notes: post_note, updated_by: userId
            };

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/updatenotes', config)

            if (response.ok) {

            } else {

            }
        } catch (error) {
            console.log(error);
            return -1;
        }
    }



    async function updateImage(post_id) {
        try {
            const data = { id: 0, post_id: post_id, url: imageURL, notes: imageNote, featured: featured ? 1 : 0, updated_by: userId };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/updateimages', config)

            if (response.ok) {
                //const json = await response.json()
                //return json[0].id;
            } else {

            }
        } catch (error) {
            console.log(error);
            return -1;
        }
    }

    const onRemoveImageClick = async (id) => {
        setPostUpdating(true);
        await deleteImage(id);
        fetchImages(postID);
        setShowMessage(true);
        setAlertMessage('Image deleted.');
        setPostUpdating(false);
    }
    const onChangeFeatured = async (id) => {
        console.log(id);
        setPostUpdating(true);
        await changedFeaturedImage(id);
        fetchImages(postID);
        setShowMessage(true);
        setAlertMessage('Image deleted.');
        setPostUpdating(false);
    }

    async function changedFeaturedImage(id) {
        try {
            const data = { id: id };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/changedfeaturedimage', config)

            if (response.ok) {
                //const json = await response.json()
                //return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }


    async function deleteImage(id) {
        try {
            const data = { id: id };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/deleteimage', config)

            if (response.ok) {
                //const json = await response.json()
                //return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    const onNextPostHandler = () => {
        setPostUpdating(true);
        props.onNextPost();


        setPostNote('');

        //updatePost();
        setTimeout(() => {

        }, 100);
        setPostUpdating(false);
    }

    const onPrevPostHandler = () => {
        setPostUpdating(true);
        setPostNote('');
        props.onPrevPost();

        setPostUpdating(false);
    }

    const openAllImages = () => {
        let i = 0;
        for (i = 0; i < postImages.length; i++) {

            window.open(postImages[i].url, i);
        }


    }

    const addLinkHandler = async () => {
        setPostUpdating(true);
 
        await addLink();
        setPostUpdating(false);
    }

    async function addLink() {
        try {
            const data = {id:0, post_id: postID, link: link, created_by: userId };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/updatepostcontentlinks', config)

            if (response.ok) {
                fetchLinks();
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    let postNoteHTML = postNotes.map(item => (<div className="notesDiv" key={item.id} ><div dangerouslySetInnerHTML={{ __html: item.notes }} ></div> <br /> <span> Last Updated by: {item.updated_user}</span> on <DateString postDate={item.created_at}></DateString> </div>));
    let markup = { __html: props.postDetails[0].content };

    let status = "";

    if (props.postDetails[0].status == 0 && props.postDetails[0].status_text.toLowerCase().includes('rejected'))
        status = "Content Rejected";
    else if (props.postDetails[0].status == 0)
        status = "Needs Edited";
    if (props.postDetails[0].status == 1 && props.postDetails[0].status_text.toLowerCase().includes('content reject edited'))
        status = "Content Reject Edited";
    else if (status == 1)
        status = "Edit Approve Pending";
    if (props.postDetails[0].status == 2)
        status = "Image Finding";
    if (props.postDetails[0].status == 3)
        status = "Approval Pending";
    if (props.postDetails[0].status == 4)
        status = "Approved";
    if (props.postDetails[0].status == 5)
        status = "Rejected";
    if (props.postDetails[0].status == 6)
        status = "Published";

    return (
        <>

            <Card>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <b>Title</b> : {props.postDetails[0].post_title}
                        </div>
                        <div className="col-2">
                            <b>Site</b> : {props.siteName}
                        </div>
                        <div className="col-3">

                            <b>Date</b> : <DateString postDate={props.postDetails[0].post_date}></DateString>
                        </div>
                        <div className="col-2">
                            <b>Status</b> : {postStatus}
                        </div>
                        <div className="col-2">
                            <b>Open Edit</b> : <a href={props.postDetails[0].post_url} target="_blank"><EditIcon /></a>
                        </div>
                    </div>

                </div>
            </Card>
            <br></br>
            {showMessage && <Alert variant="filled" severity="success" onClose={() => { setShowMessage(false); }}>{alertMessage}</Alert>}
            <div className="row">
                <div className="col">

                    <div className="container">
                        <div className="row">
                            <div className="col-6">

                                <h5>Post Content <RefreshIcon/></h5>
                                <Card>
                                    <div style={{ whiteSpace: "pre-wrap", maxHeight: 500, overflowY: 'auto' }} dangerouslySetInnerHTML={markup}  >
                                    </div>
                                </Card>


                                <br/>
                                <h5>Links</h5>
                                    <div className="form-group">
                                        <input type="textbox" className="form-control" placeholder="link" value={link} onChange={linkInputHandler}></input>

                                    </div>
                                <button type="submit" className="btn btn-success" onClick={addLinkHandler} >Add Link</button><br />
                                <LinkColumns data={postLinks}></LinkColumns>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <form onSubmit={onAddImageClick}>
                                        <div className="col">
                                            <h5>Add/Remove Images</h5>

                                            <div className="form-group row">
                                                <div className=" col-sm-11">
                                                    <input type="textbox" className="form-control" placeholder="Image URL" value={imageURL} onChange={imageURLInputHandler} required></input>
                                                </div>

                                                <div className="col-sm-1">
                                                    <input type="checkbox" onChange={onFeaturedImageClick} checked={featured} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input type="textbox" className="form-control" placeholder="Image Note" value={imageNote} onChange={imageNoteInputHandler}></input>

                                            </div>
                                            <button type="submit" className="btn btn-success">Add Image</button><br /><br />
                                            <button type="button" className="btn btn-info" onClick={openAllImages} >Open all images</button>
                                            <ImageGrid data={postImages} removeImage={onRemoveImageClick} changeFeatured={onChangeFeatured} ></ImageGrid>
                                        </div>
                                    </form>
                                </div>
                                <div className="row" style={{ marginTop: 10 }} >
                                    <div className="col">
                                        <label>Post Note</label>
                                        <textarea aria-multiline="true" row={10} className="form-control" placeholder="Post Note" style={{ height: 120 }} value={postNote} onChange={postNoteInputHandler}></textarea >
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: 10 }} >
                                    <div className="col-6"><b>Updated By:</b> {props.postDetails[0].updated_user} </div>
                                    <div className="col-6"><b>Status:</b> {status} </div>
                                </div>

                                {props.viewID == 4 && (props.postDetails[0].status == 0 || props.postDetails[0].status == 6) &&
                                    <button type="button" className="btn btn-primary" data-value={1} data-note="" onClick={updatePostStatus} >Submit Content For Review</button>}
                                {props.viewID == 5 && (props.postDetails[0].status == 1) &&
                                    <>
                                        <button type="button" className="btn btn-primary" data-value={2} data-note="Content Approved" onClick={updatePostStatus} >Approve Post Content</button>
                                        <button type="button" className="btn btn-primary" data-value={0} data-note="Content Rejected" onClick={updatePostStatus} >Reject Post Content</button>
                                    </>
                                }

                                {props.viewID == 4 &&
                                    (props.postDetails[0].status == 2 || props.postDetails[0].status == 5) &&
                                    <button type="button" className="btn btn-primary" data-value={3} data-note="" onClick={updatePostStatus} >Update Post</button>}
                                {props.viewID == 5 && props.postDetails[0].status == 3 &&
                                    <><button type="button" className="btn btn-primary" data-value={4} data-note="Post Approved" onClick={updatePostStatus} >Approve Post</button>&nbsp;
                                        <button type="button" className="btn btn-danger" data-value={5} data-note="Post Rejected" onClick={updatePostStatus} >Reject Post</button></>
                                }
                                {props.viewID == 4 &&
                                    props.postDetails[0].status == 4 && <button type="button" className="btn btn-primary" data-value={6} data-note="" onClick={updatePostStatus} >Published</button>}

                                &nbsp;<button type="button" className="btn btn-secondary" onClick={props.onCloseModal} >Close</button>
                                &nbsp;<button type="button" className="btn btn-secondary" onClick={onNextPostHandler} >Next</button>
                                &nbsp;<button type="button" className="btn btn-secondary" onClick={onPrevPostHandler} >Prev</button>
                                <h5>Notes</h5>
                                <>
                                    {postNoteHTML}
                                </>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {postUpdating && <>
                <div className={classes.backdrop} ></div>
                <div className={classes.modalLoading}><img src={LoadingImage}></img></div></>
            }
        </>
    );
}

export default EdotPostContent;
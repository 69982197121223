import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SimpleGrid from '../../UI/SimpleGrid';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


 

const LinkColumns = (props) => {

    const columns = [
        {
            field: 'link',
            headerName: 'link',
            headerClassName: 'DataGridHeader',
            flex: .8,
            renderCell: (params) => {

                return (
                    <a href={params.row.link} >{params.row.link.slice(0, 40)}</a>
                     

                );
            },
        },
        
        {
            field: 'updated_user',
            headerName: 'Updated By',
            headerClassName: 'DataGridHeader',
            flex: .2
        },
        {
            field: "action",
            headerName: "Actions",
            headerClassName: 'DataGridHeader',
            sortable: false,
            flex: .4,
            renderCell: (params) => {

                const onRemoveHandler = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();
                    props.removeImage(params.id);
                    return "";
                };


                return <div>

                  
                    <Tooltip title="Remove" placement="top-start">
                        <IconButton onClick={onRemoveHandler}>
                            <DeleteOutlineIcon className="cursor" />
                        </IconButton>
                    </Tooltip>
                    


                </div>;
            },

        },

    ];

 

    return (
        <>
            <SimpleGrid columns={columns} data={props.data} showPaging="0" />

        </>
    );
}

export default LinkColumns;
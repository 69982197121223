import SimpleGrid from '../../UI/SimpleGrid';
import ModeIcon from '@mui/icons-material/Mode';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const SiteGridColumns = (props) => {

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'DataGridHeader',
            flex:1
        },
        {
            field: 'url',
            headerName: 'URL',
            headerClassName: 'DataGridHeader',
            flex: 1
        },
        {
            field: 'authentication_Token',
            headerName: 'Authentication Token',
            headerClassName: 'DataGridHeader',
            flex: 1
        },
        {
            field: "action",
            headerName: "Actions",
            headerClassName: 'DataGridHeader',
            sortable: false,
            flex: 0.15,
            renderCell: (params) => {

                const onEditHandler = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();
                    props.onEditClick(params.id);
                    return "";

                };
               

                return <div>
                    <Tooltip title="Edit" placement="top-start">
                        <IconButton onClick={onEditHandler}>
                            <ModeIcon className="cursor" />
                        </IconButton>
                    </Tooltip>
                </div>;
            },

        },


    ];

    return (
        <SimpleGrid columns={columns} data={props.data} />
        )
}


export default SiteGridColumns;
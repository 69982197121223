import { useState, useEffect } from 'react';
import ContactUSGrid from './ContactUSGrid';
import SpreadSheet from './SpreadSheet';
import SearchFilter from './SearchFilter';
import ReplyToContact from './ReplyToContact';
import PopUp from '../UI/PopUp';
import 'bootstrap/dist/css/bootstrap.css';
import { ReactSession } from 'react-client-session';

const ManageContacts = (props) => {
    const [contactUsList, setContactUsList] = useState([]);
    const [filteredList, setfilteredList] = useState([]);
    const [replyContactId, setReplyContactId] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [contactToReply, setContactToReply] = useState([]);
    const [searchBy, setSearchBy] = useState('');
    const [filterBy, setFilterBy] = useState('Not Replied');
    const [emailTemplate, setEmailTemplate] = useState('');
    /*Pop Up*/
    const [popUpMsg, setPopUpMsg] = useState('');
    const [popUpHeaderMsg, setPopUpHeaderMsg] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [popButtonText, setPopButtonText] = useState('');
    const [popUpButtonColor, setPopUpButtonColor] = useState('');
    const [popUpAction, setPopUpAction] = useState('');

    const [alertMessage, setAlertMessage] = useState('');
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    
    useEffect(() => {
        fetchContactList();
        fetchStandardTemplate();
    }, []);

    useEffect(() => {
        filterList();
    }, [filterBy, searchBy, contactUsList]);
    
    async function fetchContactList() {
        let response = await fetch('contact/list');
        let data = await response.json();
        
        setContactUsList(data);
        setReplyContactId(false);
        setfilteredList(data);
        filterList();
        
    };

    async function fetchStandardTemplate() {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        }
        let response = await fetch(`template/1`, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        //fetch(`template/1`, { method: 'GET' });
        
        let data = await response.json();
        
        setEmailTemplate(data.body);
        
    }
    const editButtonHandler = (id) => {

        setReplyContactId(true);
        const selectedRow = contactUsList.filter((contact) => {
            return contact.id == id;
        });
        setContactToReply(selectedRow);
        
    };

    const closeModalHandler = () => {
        setReplyContactId(false);
    }

    const markAsSpamHandler = (id) => {
       
        const data = { 'id' : id };
        
        //setSelectedRows(ids);
        callService(data, 'contact/markasspam');
        fetchContactList();
        setShowAlertMessage(true);
        setReplyContactId(false);
        setAlertMessage("Selected contact marked as spam.");
    }
    const deleteContactHandler = (id) => {
        const data = { 'id': id };
        callService(data, 'contact/deletecontacts');
        fetchContactList();

        setShowAlertMessage(true);
        setReplyContactId(false);
        setAlertMessage("Selected contact deleted successfully.");
    }
    const rowSelectionChanged = (ids) => {
        setSelectedRows(ids);

        
    }

    const filterListHandler = (searchKey, filterOption) => {
        setSearchBy(searchKey);
        setFilterBy(filterOption);
        filterList();
    }

    const filterList = () => {
        let newFilteredList = [];
        for (let i = 0; i < contactUsList.length; i++) {
            const contact = contactUsList[i];


            if (contact.name.toLowerCase().includes(searchBy) || contact.email.toLowerCase().includes(searchBy) || contact.subject.toLowerCase().includes(searchBy)) {

                if (filterBy === "Replied") {
                    if (contact.is_replied)
                        newFilteredList.push(contact);
                }
                else if (filterBy === "Spam") {
                    
                    if (contact.is_spam) {
                        newFilteredList.push(contact);
                    }
                }
                else if (filterBy === "Not Replied") {
                    if (!contact.is_replied && !contact.is_spam)
                        newFilteredList.push(contact);
                }
                else if (filterBy === "All") {

                    newFilteredList.push(contact);
                }
            }

        }
        setfilteredList(newFilteredList);
        if (newFilteredList.length > 0) {
            updateRowData(newFilteredList);
        }
    }
    const formSubmitButtonHandler = (bulkAction) => {
        if (bulkAction === "none") { }
        if (bulkAction === "spam") {
            
            setPopUpMsg('Are you sure want to mark selected contacts as spam?');
            setPopUpHeaderMsg('Mark Spam Contacts');
            setPopButtonText('Mark As Spam');
            setPopUpButtonColor('btn btn-danger');
            setPopUpAction('markSpamSelectedContacts');
            setShowPopUp(true);
        }
        if (bulkAction === "deleteSelected") {
            setPopUpMsg('Are you sure want to delete selected contacts?');
            setPopUpHeaderMsg('Delete Contacts');
            setPopButtonText('Delete');
            setPopUpButtonColor('btn btn-danger');
            setPopUpAction('deleteSelectedContacts');
            setShowPopUp(true);
        }
    };

    async function deleteSelectedContacts(){
        
        for (let i = 0; i < selectedRows.length; i++) {
            const data = { id: selectedRows[i] };
            callService(data, 'contact/deletecontacts');
            
        }
        fetchContactList();
        
        setShowAlertMessage(true);
        setReplyContactId(false);
        setAlertMessage("All selected contacts are deleted successfully.");
       
    }

    function markSpamSelectedContacts() {
        
        for (let i = 0; i < selectedRows.length; i++) {
            const data = { id: selectedRows[i] };
            
            callService(data, 'contact/markasspam');
        }

        fetchContactList();
        setShowAlertMessage(true);
        setReplyContactId(false);
        setAlertMessage("All selected contacts are marked as spam.");
        
    }

    async function callService(data, service) {
        try {
            const config = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch(service, config)
            //const json = await response.json()
            if (response.ok) {

            } else {

            }
        } catch (error) {
            console.log(error);
        }
    }

    const onReplyHandler=()=>{
        setShowAlertMessage(true);
        setReplyContactId(false);
        setAlertMessage("Reply sent successfully.");
        fetchContactList();
    }

    const popUpCloseButtonHandler = () => {
        
        setShowPopUp(false);
    }

    const popUpActionHandler = () => {
        setShowPopUp(false);
        if (popUpAction === 'deleteSelectedContacts')
            deleteSelectedContacts();
        if (popUpAction === 'markSpamSelectedContacts')
            markSpamSelectedContacts();
    }

    const gridRowEditHandler = (row) => {
    }

    const DropdownOptions = [
        { label: "Replied", value: "Replied" },
        { label: "Not Replied", value: "Not Replied" },
    ];
    //console.log(filteredList);
    const [rowData, setRowData] = useState([]);
    /*const rowData = filteredList.map((contact, i) =>
        [{
            rowId: i + 1,
            cells: [
                { type: "text", text: contact.name },
                { type: "text", text: contact.email },
                { type: "text", text: contact.subject },
                { type: "text", text: contact.message },
                { type: "text", text: contact.created_at },
                { type: "checkbox", checked: false, checkedText: "", uncheckedText: "" },
                { type: "dropdown", selectedValue: "Not Replied", values: DropdownOptions, isDisabled: false, isOpen: false },
            ]

        }]
    );*/

    
    const emptyRow = [{
        rowId: rowData.length + 1,
        cells: [
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "checkbox", checked: false, checkedText: "", uncheckedText: "" },
            { type: "dropdown", selectedValue: "Not Replied", values: DropdownOptions, isDisabled: false, isOpen: false },
            { type: "text", text: "<a href='#'>Reply</a>" }
        ]
    }];
    const headerRow = [{
        rowId: 0,
        cells: [
            { type: "header", text: "Name" },
            { type: "header", text: "Email" },
            { type: "header", text: "Subject" },
            { type: "header", text: "Message" },
            { type: "header", text: "Created At" },
            { type: "header", text: "Action" },
            { type: "header", text: "Status" },
            { type: "header", text: "Reply" }
        ]
    }];


    const [rows, setRows] = useState([
        ...headerRow,
        ...emptyRow
    ]);

    const updateRowData = (rowData) => {
        const newRows = rowData.map((contact, i) =>
            [{
                rowId: i + 1,
                cells: [
                    { type: "text", text: contact.name },
                    { type: "text", text: contact.email },
                    { type: "text", text: contact.subject },
                    { type: "text", text: contact.message },
                    { type: "text", text: contact.created_at },
                    { type: "checkbox", checked: false, checkedText: "", uncheckedText: "" },
                    { type: "dropdown", selectedValue: "Not Replied", values: DropdownOptions, isDisabled: false, isOpen: false },
                    { type: "text", text: "Reply"}
                ]

            }]
        );
         
        setRows([
            ...headerRow,
            ...newRows.map(singleRow => singleRow[0]),
            ...emptyRow]
        );
    }

    const spreadSheetEventHandler = (changes) => {

        setRows((prevRows) => {
            changes.forEach((change) => {
                const personIndex = change.rowId;
                const fieldName = change.columnId;
                console.log(change);
                //rows[1].cells[6].isOpen
                if (fieldName === "status") {
                    prevRows[personIndex].cells[6].selectedValue = change.newCell.selectedValue;
                    prevRows[personIndex].cells[6].isOpen = change.newCell.isOpen;
                }
                else if (fieldName === "name") {
                    prevRows[personIndex].cells[0].text = change.newCell.text;
                    alert("Change in text '" + change.newCell.text + "' from old text '" + change.previousCell.text + "'");
                }
                else if (fieldName === "email") {
                    prevRows[personIndex].cells[1].text = change.newCell.text;
                    alert("Change in text '" + change.newCell.text + "' from old text '" + change.previousCell.text + "'");
                }
                else if (fieldName === "subject") {
                    prevRows[personIndex].cells[2].text = change.newCell.text;
                    alert("Change in text '" + change.newCell.text + "' from old text '" + change.previousCell.text + "'");
                }
                else if (fieldName === "message") {
                    prevRows[personIndex].cells[3].text = change.newCell.text;
                    alert("Change in text '" + change.newCell.text + "' from old text '" + change.previousCell.text + "'");
                }
                else if (fieldName === "action") {
                    console.log(prevRows[personIndex].cells[5]);
                    prevRows[personIndex].cells[5].checked = change.newCell.checked;
                    //alert("Change in text '" + change.newCell.text + "' from old text '" + change.previousCell.text + "'");
                }


            });
            console.log(prevRows);
            return [...prevRows];
        });
    }
        
    return (
        <div className="container-fluid">
            <h3>Manage Contact Us</h3>
            {showAlertMessage && <div className="alert alert-success" role="alert">
                {alertMessage}
            </div>}
            <div >
                <SearchFilter onFilteredList={filterListHandler} onBulkOptionSubmit={formSubmitButtonHandler }></SearchFilter>
            </div>
            <br />
            

            <br />
            <br />
            <br />
            
            <ContactUSGrid data={filteredList} onRowSelection={rowSelectionChanged} onEditClick={editButtonHandler} onGridRowEdit={gridRowEditHandler} onMarkAsSpam={markAsSpamHandler} onDelete={deleteContactHandler} />
            {replyContactId && <ReplyToContact onCloseModal={closeModalHandler} onReply={ onReplyHandler} contactDetails={contactToReply}  emailTemplate={emailTemplate } />}
            {showPopUp && <PopUp popUpText={popUpMsg} headerText={popUpHeaderMsg} buttonText={popButtonText} buttonClass={popUpButtonColor} onClosePopUp={popUpCloseButtonHandler} onPopUpConfirm={popUpActionHandler } />}
        </div>
    );


};

export default ManageContacts;
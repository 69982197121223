import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom"
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ReactSession } from 'react-client-session';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style1 = {
    //position: 'absolute',
    //top: '50%',
    //left: '50%',
    //transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    /*border: '2px solid #000',*/
    /*boxShadow: 50,*/
    p: 4

};

const styletrrd = {
    padding: '10px'
};


const AddEditCustomer = () => {
    const navigate = useNavigate();
    ReactSession.setStoreType("localStorage");
    const [state, setState] = React.useState({
        snackopen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'success', //error//warning//info
        message: ''
    });
    const { vertical, horizontal, snackopen, message, severity } = state;
    
    //const id = sessionStorage.getItem("custid")
    const id = ReactSession.get("custid")
    const [formerros, setFormerros] = React.useState({});
    const [addbtntext, setAddbtntext] = React.useState("Save");
    const [values, setValues] = React.useState({
        customerID: '',
        cName: '',
        cEmail: '',
        cStatus: -1,
        payPalEmailNotes: '',
        additionalEmails: '',
        pricingNotes: '',
        customerNotes: ''

    });

    

    useEffect(() => {        
        
        if (id == 0) {
            setAddbtntext("Save");
            setValues({
                ...values,
                customerID: 0, cName: "", cEmail: "", cStatus: -1, payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
            });
        }
        else {
            setAddbtntext("Update");
            getCustomerByID(id);           
        }     
        setFormerros({
            ...formerros, cName: "", cEmail: "", cStatus: "", payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
        });

    }, [])


    const handleClose = () => {
        setState({ ...state, snackopen: false, message: '' });
    };

    const AddCustomer = async (event, CustomerID) => {

        if (addbtntext == "Update") {
            setFormerros(validate(values));
            if (Object.keys(formerros).length === 0) {

                await editcustomerdata();
            }

        } else {
            
            setFormerros(validate(values));
            if (Object.keys(formerros).length === 0) {
                await addcustomerdata();
            }

        }
    }


    const validate = (values) => {
        /*alert("Entry Point in Validate function");*/
       // debugger;
        const errors = {};
        const regex1 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const regexcomma = /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/;


        if (!values.cName) {
            errors.cName = "Customer Name is required.";
        }

        if (!values.cEmail) {
            errors.cEmail = "Email is required.";
        }
        else if (!regex1.test(values.cEmail)) {
            errors.cEmail = "Invalid Email.";
        }

        if (values.cStatus == -1) {
            errors.cStatus = "Status is required.";
        }

        if (!values.payPalEmailNotes) {
            errors.payPalEmailNotes = "PayPal Email Notes is required.";
        }
        
        if (!values.additionalEmails) {
            errors.additionalEmails = "Additional Emails is required.";
        }
        else if (!regexcomma.test(values.additionalEmails)) {
            errors.additionalEmails = "Invalid Email."
        }

        if (!values.pricingNotes) {
            errors.pricingNotes = "Pricing Notes is required.";
        }

        if (!values.customerNotes) {
            errors.customerNotes = "Customer Notes is required.";
            
        }

        if (Object.values(errors).every(val => val === "")) {
            if (addbtntext == "Update") {
                editcustomerdata();
            }
            else {
                addcustomerdata();
            }

        }
       

        return errors;

    }


    const addcustomerdata = () => {

        if (Object.keys(values).length > 0) {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ CName: values.cName, CEmail: values.cEmail, CStatus: values.cStatus, CustomerID: values.customerID, payPalEmailNotes: values.payPalEmailNotes, additionalEmails: values.additionalEmails, pricingNotes: values.pricingNotes, customerNotes: values.customerNotes })
            };

            fetch(`customerinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                        }
                        else {
                            if (ReactSession.get("RedirectPage") === 1) {
                                ReactSession.remove("custid");
                                ReactSession.remove("RedirectPage");
                                await navigate('/AddEditOrder');
                            } else {
                                ReactSession.remove("custid");
                                await navigate('/Customers');
                            }
                            
                        }
                    }
                    
                    
                })
                .catch(error => {
                    setState({ ...state, snackopen: true, message: error });
                });
        }
    }


    const editcustomerdata = () => {

        if (Number(values.customerID) > 0) {

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ CName: values.cName, CEmail: values.cEmail, CStatus: values.cStatus, CustomerID: values.customerID, payPalEmailNotes: values.payPalEmailNotes, additionalEmails: values.additionalEmails, pricingNotes: values.pricingNotes, customerNotes: values.customerNotes })
            };

            fetch(`customerinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();
                    
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });

                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                        }
                        else {
                            ReactSession.remove("custid");
                            await navigate('/Customers');
                        }
                    }

                    
                    
                })
                .catch(error => {
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                });

        }


    }


    const getCustomerByID = async (id) => {
        fetch(`customerinfo/GetCustomerByID/${id}`, { method: 'GET' })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {

                    if (typeof (data) == "string") {
                        setState({ ...state, snackopen: true, message: data, severity: 'error' });
                    }
                    else {
                        setValues({
                            ...values,
                            customerID: id, cName: data.cName, cEmail: data.cEmail, cStatus: data.cStatus, payPalEmailNotes: data.payPalEmailNotes, additionalEmails: data.additionalEmails, pricingNotes: data.pricingNotes, customerNotes: data.customerNotes
                        });
                    }                   
                }                
            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleNavigate = (event) => {
        ReactSession.remove("custid");
        navigate('/Customers');
    }

    return <>
        <Box sx={style1}>

            <Typography variant="h5" gutterBottom>Add/ Edit Customer</Typography>

            <table style={{ "width": "100%" }} >
                <TableBody>
                <tr style={styletrrd} >
                    <td style={styletrrd}>
                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.cEmail} >
                            <InputLabel htmlFor="outlined-adornment-firstname" >Customer Primary Email</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-firstname"
                                label="Email"
                                value={values.cEmail}
                                onChange={handleChange('cEmail')}
                            />
                            <FormHelperText>{formerros.cEmail}</FormHelperText>
                        </FormControl>
                    </td>
                    <td rowSpan="2" style={styletrrd}>
                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.pricingNotes} >
                            <InputLabel htmlFor="outlined-adornment-firstname">Pricing Notes</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-firstname"
                                label="Pricing Notes"
                                multiline
                                rows={5}
                                value={values.pricingNotes}
                                onChange={handleChange('pricingNotes')}
                            />
                            <FormHelperText>{formerros.pricingNotes}</FormHelperText>
                        </FormControl>
                    </td>
                </tr>
                <tr style={styletrrd}>
                    <td style={styletrrd}>
                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.cName} >
                            <InputLabel htmlFor="outlined-adornment-firstname">Company Name</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-firstname"
                                label="Customer Name"
                                value={values.cName}
                                onChange={handleChange('cName')}
                            />
                            <FormHelperText>{formerros.cName}</FormHelperText>
                        </FormControl>
                    </td>

                </tr>

                <tr style={styletrrd}>
                    <td style={styletrrd}>

                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.payPalEmailNotes}>
                            <InputLabel htmlFor="outlined-adornment-firstname">PayPal Email Notes</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-firstname"
                                label="PayPal Email Notes"
                                multiline
                                rows={2}
                                value={values.payPalEmailNotes}
                                onChange={handleChange('payPalEmailNotes')}
                            />
                            <FormHelperText>{formerros.payPalEmailNotes}</FormHelperText>
                        </FormControl>
                    </td>
                    <td rowSpan="2" style={styletrrd}>
                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.customerNotes}>
                            <InputLabel htmlFor="outlined-adornment-firstname">Customer Notes</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-firstname"
                                label="Customer Notes"
                                multiline
                                rows={7}
                                value={values.customerNotes}
                                onChange={handleChange('customerNotes')}
                            />
                            <FormHelperText>{formerros.customerNotes}</FormHelperText>
                        </FormControl>
                    </td>
                </tr>

                <tr style={styletrrd}>
                    <td style={styletrrd}>
                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.additionalEmails} >
                            <InputLabel htmlFor="outlined-adornment-firstname">Additional Emails</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-firstname"
                                label="Additional Emails"
                                value={values.additionalEmails}
                                multiline
                                rows={2}
                                onChange={handleChange('additionalEmails')}
                            />
                            <FormHelperText>{formerros.additionalEmails}</FormHelperText>
                        </FormControl>
                    </td>

                </tr>

                <tr style={styletrrd}>
                    <td></td>
                    <td style={styletrrd}>  <FormControl fullWidth sx={{ m: 1 }} error={formerros.cStatus}>
                        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.cStatus}
                            label="Role"
                            onChange={handleChange('cStatus')}
                        >
                            <MenuItem value={-1} >
                                Select
                            </MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Banned</MenuItem>
                        </Select>
                        <FormHelperText>{formerros.cStatus}</FormHelperText>
                    </FormControl></td>
                </tr>
                </TableBody>
            </table>


            <Stack direction="row" alignItems="center" spacing={2} >
                <Button color="success" variant="contained" onClick={(event) => AddCustomer(event, 0)}>{addbtntext}</Button>
                <Button variant="contained" onClick={(event) => handleNavigate(event)} color="success">
                    Cancel
                </Button>
            </Stack>

        </Box>

        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={3000}
            open={snackopen}
            onClose={handleClose}
            key={vertical + horizontal}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>



    </>
  

}

export { AddEditCustomer }
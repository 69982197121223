const PopUp = (props) => {
    const onCloseHandler = () => {
        console.log("close");
        props.onClosePopUp();
    }

    const onPopUpConfirm = () => {
        props.onPopUpConfirm();
    }
    return (
        <div className="modal" role="dialog" style={{ display: "block" }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.headerText}</h5>
                        <button type="button" className="close" aria-label="Close" onClick={onCloseHandler}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>{props.popUpText}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className={props.buttonClass} onClick={onPopUpConfirm } >{props.buttonText}</button>
                        <button type="button" className="btn btn-secondary" onClick={onCloseHandler}>Close</button>
                    </div>
                </div>
            </div>
        </div>);
};


export default PopUp;
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactSession } from 'react-client-session';
import VpnKeyIcon from '@mui/icons-material/VpnKey';


// Function Name Should be Our file name and we use this file  for showing Login Information.
//This is login page by default we show this page to user unless he is not logged in ...
//We call  web service method "employeeLogin" in this page for Login check 
//Bussiness logic for this page in short it 
 // we check if user is valid or not and if valid we resturn success and make him login and if not we show error in snack bar 

const Login = ({ navigate }) => {

    navigate = useNavigate(); // This function is used for navigation between the pages from one page to another page.

    // This is MUI's Alert function which is encapsulated inside the Snakbar
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    //We have used the state as a useState to prevent the Snakbars state and by this we can dynamically set the state properties
    //for Snakbars different -different menthod and its associated behavious like if we set "severity" as "error" then error
    //message will comes in red

    const [state, setState] = React.useState({
        snackopen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'success', //error//warning//info
        message: ''
    });

    const { vertical, horizontal, snackopen, message, severity } = state;

    // We have used formerros to prevent the error message on the form and based on users input we used to change the instance of the
    //formerrors properties

    const [formerros, setFormerros] = React.useState({});

    // We have used the values as useState hooks to prevent the username & password along with showpassword and loading method
    //we will set the methd based on the users input and html component behaviour
    const [values, setValues] = React.useState({
        username: '',
        password: '',
        showPassword: false,
        loading: false
    });

    const [disable, setDisable] = React.useState(false);

    //useEffect - Hooks is used to handle the page rendering and it will call when ever the page loads for the first time
    //as we have restricted it with [].
    //here we are updating the instance of the formerros state and called checkCookies function to see if user has enbaled th cookies
    //so we can retrive the cookies information

    useEffect(() => {
        
        setFormerros({
            ...formerros, username: "", password: ""
        });

        checkCookies();

    }, [])

    //This method will call the WEB API login method when user will click on Login button after entering the username and password.
    const loginSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
       
        setValues({ ...values, loading: true });
        
        setFormerros(validate(values));
        if (Object.keys(formerros).length === 0) {
            await loginUser();
        } else {
            setValues({ ...values, loading: false });
        }
    }

    //This method will check the ReactSession storage type as Cookies and if usernamRM,passwordRM and RememberMe key value is not null
    //then it will set the values useState reference with the cookies value.
    const checkCookies = async () => {
        ReactSession.setStoreType("cookie");

        if (ReactSession.get("usernamRM") !== null || ReactSession.get("passwordRM") !== null || ReactSession.get("RememberMe") !== null) {
            document.getElementById("RememberMe").checked = true
            setValues({ ...values, username: ReactSession.get("usernamRM"), password: ReactSession.get("passwordRM") });            
        }
        else {
            document.getElementById("RememberMe").checked = false
            setValues({ ...values, username: '', password: ''});
        }
    }

    //This is the Web API POST method the call the Login service and we are sending the username and password as the parameter of the post
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const loginUser = () => {
        if (Object.keys(values).length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ UserName: values.username, Password: values.password })
            };

            fetch(`login`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    console.log('data---', data);
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setValues({ ...values, loading: false });
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                        return Promise.reject(error);
                    }
                    else {
                        if (typeof (data) == "string") {
                            setState({ ...state, snackopen: true, message: data, severity: 'error' });
                            setValues({ ...values, loading: false });
                        }
                        else if (typeof (data) == "object") {
                            if (data.userid.length > 0) {  
                                setDisable(true);
                                /*sessionStorage.setItem("UserId", data.userid);
                                sessionStorage.setItem("UserName", data.username);
                                sessionStorage.setItem("RoleID", data.roleid);
                                sessionStorage.setItem("Token", data.token);*/
                               //When  user is able to login then we set user information in session 
                                localStorage.setItem("userId", data.userid);

                                ReactSession.setStoreType("localStorage");
                                ReactSession.set("UserID", data.userid);
                                ReactSession.set("UserName", data.username);
                                ReactSession.set("RoleID", data.roleid);
                                ReactSession.set("Token", data.token);
                                //alert(data.token);

                                if (document.getElementById("RememberMe").checked) {
                                    //checked me functionality is use to set/unset user information in cookie 
                                    //and if  it is checked  then we set user credential when user comes to site.
                                    ReactSession.setStoreType("cookie");
                                    ReactSession.set("usernamRM", values.username);
                                    ReactSession.set("passwordRM", values.password);
                                    ReactSession.set("RememberMe", document.getElementById("RememberMe").checked);
                                } else {
                                    //checked me functionality is use to set/unset user information in cookie
                                    //and if  it is not checked  then we have to entre user credentail at time login .
                                    ReactSession.setStoreType("cookie");
                                    ReactSession.set("usernamRM", null);
                                    ReactSession.set("passwordRM", null);
                                    ReactSession.set("RememberMe", null);
                                    ReactSession.set("Token", null);
                                   
                                }
                                if (data.roleid == 4)
                                    changeLocation('/DraftPosts');
                                else if (data.roleid == 5)
                                    changeLocation('/ApproveDraftPosts');
                                else
                                    changeLocation('/DraftPosts');
                            } else {
                                setState({ ...state, snackopen: true, message: "Some thing is wrong.", severity: 'error' });
                                setValues({ ...values, loading: false });
                            }
                        }
                        else {
                            setState({ ...state, snackopen: true, message: 'User Login successfully.', severity: 'success' });
                            changeLocation('/DraftPosts');
                        }
                    }
                })
                .catch(error => {
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    setValues({ ...values, loading: false });
                    //console.error('There was an error!', error);
                });
        }
    }

    //This function is called to redirect the page to manage order page if user login successfully.
    function changeLocation(placeToGo) {
        navigate(placeToGo, { replace: true });
        window.location.reload();
    }

   

    const handleClose = () => {
        setState({ ...state, snackopen: false, message: '' });
    };

    //this method is used for validating the form component on login page as both username and password is mandetory.
    const validate = (values) => {
       
        const errors = {};

        if (!values.username) {
            errors.username = "UserName is required.";
        }

        if (!values.password) {
            errors.password = "Password is required.";
        }

        if (Object.values(errors).every(val => val === "")) {
            loginUser();
        }
        return errors;
    }

    //this method will call when OnChange of username and password field will be called and it will set the properties value accordingly
    //we are have made it dynamically using prop.
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
       // console.log(values);
    };

    //This menthod will call when user will click on the eye icon to see the entered password.
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    //We have prevented the OnMouseDown on the passowrd to prevent its event.
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    //return is having the form controls which we can see when we run the page.

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                        </div>
                                       
                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.username}>
                                            <InputLabel htmlFor="outlined-adornment-username">User Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-username"
                                                label="User Name"
                                                value={values.username}
                                                onChange={handleChange('username')}
                                            />
                                            <FormHelperText>{formerros.username}</FormHelperText>
                                        </FormControl>
                                        <FormControl fullWidth sx={{ m: 1 }} variant="outlined" error={formerros.password}>
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange('password')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end">
                                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                            <FormHelperText>{formerros.password}</FormHelperText>
                                        </FormControl>
                                         <FormControl fullWidth sx={{ m: 1 }}>     
                                        <div>
                                            <input 
                                                type="checkbox"
                                                className="form-check-input"
                                                id="RememberMe"
                                                name="checkbox"  
                                            /> Remember Me                    
                                        </div>
                                        </FormControl>
                                        <Stack direction="row" align="center" spacing={2}>
                                            &nbsp;&nbsp;
                                                                                                                                  
                                            <LoadingButton
                                                loading={values.loading}
                                                variant="contained"
                                                color="success"
                                                loadingPosition="start"
                                                startIcon={<VpnKeyIcon />}
                                                disabled={disable}
                                                onClick={(e) => loginSubmit(e)}
                                            >
                                                Login
                                            </LoadingButton>
                                        </Stack>
                                        <Snackbar
                                            anchorOrigin={{ vertical, horizontal }}
                                            autoHideDuration={3000}
                                            open={snackopen}
                                            onClose={handleClose}
                                            key={vertical + horizontal}
                                        >
                                            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                                                {message}
                                            </Alert>
                                        </Snackbar>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Login } 
import { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import SmartSheet from '../SmartSheet/SmartSheet';
import OrdersGrid from './OrdersGrid';

 

const ManageOrders = (props) => {
    const [orderData, setOrderData] = useState([]);

    const [sentChecked, setSentChecked] = useState(2);
    const [paidChecked, setPaidChecked] = useState(2);
    const [ppChecked, setPpChecked] = useState(2);
    const [followUpStatus, setFollowUpStatus] = useState('');
    const [orderType, setOrderType] = useState('');
    useEffect(() => {
        getOrderData();
    }, []);

    //this is web api get method and we use this method to show order information.
    const getOrderData = async () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;
       

        if (jwtPayload * 1000 < Date.now()) {
           
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {

            try {

                var options = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }

                const response = await fetch('orderinfo', options);
                const data = await response.json();
                if (data) {
                    setOrderData(data);
                    
                }
            }
            catch (error) {
                console.log(error);
            }

        }

    }

    
    const markAsPaid = (id) => {
        const i = orderData.findIndex((row) => {
            return row.id == id;
        });
        
        if (paidChecked === 2) {
            if (orderData[i].isPaid === 0)
                setPaidChecked(1);
            else
                setPaidChecked(0);
        } else if (paidChecked === 1) {
            
            setPaidChecked(0);
        }
        else {
            //prevState[i].sent = 1;
            setPaidChecked(1);
        }
        
    }
   
    const markAsSend = (id) => {
        const i = orderData.findIndex((row) => {
            return row.id == id;
        });
        if (sentChecked === 2) {
            if (orderData[i].sent === 0)
                setSentChecked(1);
            else
                setSentChecked(0);
        }
        else if (sentChecked === 1) {
            setSentChecked(0);
        }
        else {
            setSentChecked(1);
        }
       
    }
 


    const markAsPP = (id) => {
        const i = orderData.findIndex((row) => {
            return row.id == id;
        });
        if (ppChecked === 2) {
            if (orderData[i].pp === 0)
                setPpChecked(1);
            else
                setPpChecked(0);
        } else if (ppChecked === 1) {
            //prevState[i].sent = 0;
            setPpChecked(0);
        }
        else {
            //prevState[i].sent = 1;
            setPpChecked(1);
        }
      
    }
 
    const followUpStatusChangeHandler = (id, value) => {
       
        setFollowUpStatus(value);
    }

    const orderTypeSelectionChangeHandler = (id, value) => {
        
        setOrderType(value);
    }
        
    const gridRowEditHandler = (row) => {
        let newRow = row;
        
        setOrderData((prevState) => {
            const i = prevState.findIndex((oldRow) => {
                return oldRow.id == row.id;
            });
            newRow.orderType = orderType.length < 1 ? prevState[i].orderType : orderType;
            newRow.sent = sentChecked == 2 ? prevState[i].sent : sentChecked;//prevState[i].sent;
            newRow.pp = ppChecked == 2 ? prevState[i].pp : ppChecked; //prevState[i].pp;
            newRow.isPaid = paidChecked == 2 ? prevState[i].isPaid : paidChecked; //prevState[i].isPaid;
            newRow.followUpStatus = followUpStatus.length < 1 ? prevState[i].followUpStatus : followUpStatus;
            //data= prevState.data.map((x, key) => (key === i ? { ...x, starIcon: !x.starIcon } : x))
            //prevState[i] = { ...newRow };
            prevState[i].sent = newRow.sent;
            prevState[i].pp = newRow.pp;
            prevState[i].isPaid = newRow.isPaid;
            prevState[i].customerName = newRow.customerName;
            prevState[i].custEmail = newRow.custEmail;
            prevState[i].emailSubject = newRow.emailSubject; 
            //prevState[i].orderType = newRow.orderType;
            prevState[i].orderNotes = newRow.orderNotes;
            prevState[i].postTitle = newRow.postTitle;
            prevState[i].orderLink = newRow.orderLink;
            prevState[i].checkboxitem.index = newRow.checkboxitem.index;
            prevState[i].followUpStatus = newRow.followUpStatus;
            prevState[i].orderType = newRow.orderType;
            newRow = prevState[i];
            return [...prevState];
        });
        //console.log(newRow);
        addOrderData(newRow);
        setSentChecked(2);
        setPaidChecked(2);
        setPpChecked(2);
        setFollowUpStatus('');
        setOrderType('');
    }

    const handleNewItemClick = () => {
        
        const id = Math.floor(Math.random()) + 100;
        
        setOrderData((prevState) => [...prevState, {
            id: id,
            orderId:0,
            customerID: '',
            customerName: '',
            custEmail: '',
            orderType: '',
            orderDetails: '',
            orderPaymentNotes: '',
            completedLinks: [],
            payPalInvoiceLinks: [],
            orderNotes: '',
            orderAdminNotes: '',
            orderStatus: 0,
            checkboxitem: { needPayPalinvoice: 0, paid: 0, indexing: 0, editRequired: 0, adminReviewRequired: 0, cancel: 0 },
            emailSubject: '',
            postTitle: '',
            orderLink: '',
            sent: '',
            pp: '',
            followUpStatus: ''
        }]);
        
    }

    const addOrderData = async (row) => {

        // if (Object.keys(values).length > 0) {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(
                    {
                        orderId: row.orderID,
                        customerName: row.customerName,
                        custEmail: row.custEmail,
                        orderType: row.orderType,
                        orderDetails: row.orderDetails,
                        orderPaymentNotes: row.orderPaymentNotes,
                        completedLinks: [],
                        payPalInvoiceLinks: [],
                        orderNotes: row.orderNotes,
                        orderAdminNotes: row.orderAdminNotes,
                        orderStatus: row.orderStatus,
                        checkboxitem: { needPayPalinvoice: 0, paid: 0, indexing: 0, editRequired: 0, adminReviewRequired: 0, cancel: 0 },
                        emailSubject: row.emailSubject,
                        postTitle: row.postTitle,
                        orderLink: row.orderLink,
                        sent: row.sent,
                        pp: row.pp,
                        followUpStatus: row.followUpStatus,
                        createdby: 1,
                        updatedby: 1,
                        isPaid: row.isPaid,
                        indexing:row.indexing
                    })
            };

            fetch(`orderinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();
                    
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        getOrderData();
                       
                    } else {
                        console.log(response);    
                    }

                })
                .catch(error => {
                    console.log(error);
                    //console.error('There was an error!', error);
                });
            //}
        }
    }

    const gridReloadHandler = () => {
        
        setOrderData((prevState) => {
            return [...prevState];
        });
    };
    return <>
        <SmartSheet></SmartSheet>
        {/*<OrdersGrid orderData={orderData} sentChecked={sentChecked} paidChecked={paidChecked} ppChecked={ppChecked} orderType={orderType}
            followUpStatus={followUpStatus} onMarkAsPaid={markAsPaid} onGridRowEdit={gridRowEditHandler}
            onMarkAsSend={markAsSend} onFollowUpStatusChange={followUpStatusChangeHandler}
            onOrderTypeSelectionChange={orderTypeSelectionChangeHandler} onMarkAsPP={markAsPP} onNewItemClick={handleNewItemClick} reloadGrid={gridReloadHandler} />*/}
        </>

};


export default ManageOrders;
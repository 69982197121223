import Modal from '../../UI/Modal';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import SimpleGrid from '../../UI/SimpleGrid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const ImageGridPopUp = (props) => {

    const columns = [
        {
            field: 'url',
            headerName: 'url',
            headerClassName: 'DataGridHeader',
            flex: .4,
         
        },
        {
            field: 'notes',
            headerName: 'notes',
            headerClassName: 'DataGridHeader',
            flex: .4,
          
        }, 
        {
            field: 'featured',
            headerName: 'featured',
            headerClassName: 'DataGridHeader',
            flex: .1,
            renderCell: (params) => {
                //return params.row.featured == 1 ? "Yes" : "No";
                const markAsFeatured = () => {
                    props.changeFeatured(params.row.id);

                }


                return (<div>

                    <input type="checkbox" onChange={markAsFeatured} checked={params.row.featured == 1 ? true : false} />


                </div>);
            },
        },
        {
            field: 'updated_user',
            headerName: 'Updated By',
            headerClassName: 'DataGridHeader',
            flex: .1
        },
        {
            field: "action",
            headerName: "Actions",
            headerClassName: 'DataGridHeader',
            sortable: false,
            flex: .2,
            renderCell: (params) => {

                const onRemoveHandler = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();
                    props.removeImage(params.id);
                    return "";
                };

              
                return <div>

                    <Tooltip title="Open" placement="top-start">
                        <a href={params.row.url} target="_blank"> <FileOpenIcon className="cursor" /></a>
                    </Tooltip>
                    <Tooltip title="Remove" placement="top-start">
                        <IconButton onClick={onRemoveHandler}>
                            <DeleteOutlineIcon className="cursor" />
                        </IconButton>
                    </Tooltip>



                </div>;
            },

        },
    ];

    return (
        <Modal >
            <div className="container" style={{'overflow':'auto', 'maxHeight':'500px'}}>
                <button type="button" className="btn btn-default" onClick={props.onCloseModal} >Back</button><br></br>
                <SimpleGrid columns={columns} data={props.data} showPaging="0" />
            </div>
        </Modal>
    );
}

export default ImageGridPopUp;
import DraftPostColumns from "../DraftPosts/DraftPostColumns";
import Modal from '../../UI/Modal';
import { useState } from "react";
import { ReactSession } from 'react-client-session';

const SelectionPopUp = (props) => {

    const [searchPostTitle, setSearchPostTitle] = useState('');
    const [posts, setPosts] = useState([]);
    const userId = ReactSession.get('UserID');
    const [roleId, setRoleId] = useState(ReactSession.get("RoleID"));

    const searchPostTitleHandler = (event) => {
        setSearchPostTitle(event.target.value);
        
        //filterList(statusFilter);

    }

    const showPostHandler = (event) => {
        event.preventDefault();
        fetchDraftPosts(props.siteId);
        
        
    }

    async function fetchDraftPosts(id) {
        const data = { 'id': id };
        //let response = await fetch('post/draftposts');
        //let post_data = await response.json();

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/draftposts', config)
            const json = await response.json()
            if (response.ok) {

                setPosts(json);
                filterList(json);
                
            } else {

            }
        } catch (error) {
            console.log(error);
        }
        //setSiteList(data);
    }


    const filterList = (draftPosts) => {
        let filteredList = draftPosts.filter(post => ((post.content_Editing == 0 && post.is_google_doc == 0)));
        console.log(draftPosts);
   
        let filteredList_new = filteredList.filter(post => post.post_title.toLowerCase().includes(searchPostTitle));
        //filteredList.filter(post => post.content_Editing == 1);
        setPosts(filteredList_new);
       
    }

    const searchPostHandler = (event) => {
        event.preventDefault();
        if (props.siteId > 0) {
            getPostsfromSite(searchPostTitle);
        }
        else {
            alert("Please select site to search");
        }
    }

    async function getPostsfromSite(searchValue) {
        const data = { id: 0, post_id: 0, post_url: '', link: '', post_title: searchValue, site_id: props.siteId, wp_id: 0, content: '', updated_by: 0, is_google_doc: 0 };

        try {

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/searchposts', config)

            if (response.ok) {
                const json = await response.json();
                //console.log(json);

                setPosts(json);
                
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }
    async function addLink(post_selected) {
        try {
            const data = { id: 0, post_url: post_selected[0].post_url, post_title: post_selected[0].post_title, site_id: props.siteId, wp_id: post_selected[0].wp_id, content: '', content_Editing: 1, status: 1, updated_by: userId, is_google_doc: 0, is_draft:0 };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/upadatepost', config)

            if (response.ok) {
                const json = await response.json();
                console.log(json[0].id);
                return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    const selectPostHandler = async (id) => {


        //console.log(draftPosts.filter(post => post.id == id));
        const post_selected = posts.filter(post => post.id == id);

        if (post_selected[0].db_id == 0) {
            const newPostId = await addLink(post_selected);
            post_selected[0].db_id = newPostId;
            setPosts((prevState) => {
                const i = prevState.findIndex((oldRow) => {
                    return oldRow.id == id;
                });
                prevState[i].db_id = newPostId;
                return [...prevState];
            });
            updateNote(newPostId, "Content Selected.");
            //window.location.href = '/EditPostContent?postid=' + newPostId + '&siteid=' + props.siteId;
        }
        else {
            console.log(post_selected[0]);
            post_selected[0].content_Editing = 1;
            if (roleId == 1)
                post_selected[0].status = 2;
            else
                post_selected[0].status = 1;

            post_selected[0].is_draft = 1;
            await updatePost(post_selected[0]);
            //window.location.href = '/EditPostContent?postid=' + post_selected[0].db_id + '&siteid=' + props.siteId;
            if (roleId == 1)
                updateNote(post_selected[0].db_id, "Content Selected By Admin and Approved.");
            else
                updateNote(post_selected[0].db_id, "Content Selected.");
        }
        
        props.contentAdded();
        //window.location.reload(); 
    }
    async function updateNote(post_id, post_note) {
        
        try {
            const data = {
                post_id: post_id, notes: post_note, updated_by: userId
            };

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/updatenotes', config)

            if (response.ok) {

            } else {

            }
        } catch (error) {
            console.log(error);
            return -1;
        }
    }
    async function updatePost(post) {
        try {
            const data = post;

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/upadatepost', config)

            if (response.ok) {
                const json = await response.json()
                return json[0].id;
            } else {

            }
        } catch (error) {
            console.log(error);
            return -1;
        }
    }

    return (
        <Modal >
            <div className="container">

                <div className="row">
                    <div className="col">
                        <input type="textbox" className="form-control" placeholder="Search Post Title" value={searchPostTitle} onChange={searchPostTitleHandler}></input>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-primary" onClick={searchPostHandler} >Search For Published sites</button>&nbsp;
                        <button type="button" className="btn btn-primary" onClick={showPostHandler} >Show Posts In Tools</button>
                        <button type="button" className="btn btn-default" onClick={props.onCloseModal} >Back</button><br></br>
                    </div>
                </div>
                <div style={{ height: "500px" }}>
                    <DraftPostColumns data={posts} onSelectPost={selectPostHandler} showDeleteButton={ false} />
                </div>
            </div>
        </Modal>
    );
}

export default SelectionPopUp;


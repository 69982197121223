import { useEffect, useState } from 'react';
import SimpleGridColumns from '../Sites/SiteGridColumns';
import AddSite from '../Sites/AddSite';


const empty_site = {
    id: 0,
    name: '',
    url: '',
    authentication_Token: ''
};

const ManageSites = (props) => {
    const [siteList, setSiteList] = useState([]);
    const [closeModal, setCloseModal] = useState(false);
    const [siteDetails, setSiteDetails] = useState(empty_site);
    

    useEffect(() => {
        fetchSiteList();
    }, []);

    async function fetchSiteList() {
        let response = await fetch('sites/getsites');
        let data = await response.json();

        setSiteList(data);
        
    }

    const closeModalHandler = () => {
        setCloseModal(false);
    }
    const addSiteButtonHandler = () => {
        setSiteDetails(empty_site);
        setCloseModal(true);
    }
    const editClickHandler = (id) => {
        const edit_details = siteList.filter((item) => item.id == id);
        setSiteDetails(edit_details[0]);
        setCloseModal(true);
    }

    const onSaveHandler = () => {
    }
    return (
        <>
            <div className="container-fluid">
                <h3>Manage Sites</h3>
                <button className="btn btn-success" value="Add Site" onClick={addSiteButtonHandler} >Add Site</button>

            </div>
            
            {closeModal && <AddSite onCloseModal={closeModalHandler} onSave={onSaveHandler} siteDetails={siteDetails}  />}<br/>
            <SimpleGridColumns data={siteList} onEditClick={ editClickHandler} />
        </>
    );
}


export default ManageSites;
import { useState, useEffect } from "react";
import DraftPostColumns from '../DraftPosts/DraftPostColumns';
import AddImagesToPost from "./AddImagesToPost";
import Card from '../../UI/Card';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useSearchParams } from "react-router-dom";


const DraftPosts = (props) => {
    
    const [siteList, setSiteList] = useState([]);
    const [draftPosts, setDraftPosts] = useState([]);
    const [filteredDraftList, setFilteredDraftList] = useState([]);
    const [addPostImages, setAddPostImages] = useState(false);
    const [postSelected, setPostSelected] = useState([]);
    const [siteId, setSiteId] = useState({id:0, label:'Select'});
    const [roleId, setRoleId] = useState(ReactSession.get("RoleID"));
    const [viewID, setViewId] = useState(ReactSession.get("RoleID"));
    
    const [autoCompleteSiteList, setAutoCompleteSiteList] = useState([{ label: "Select ", id: 0 }]);

    const [searchParams] = useSearchParams();
    const url_post_id = searchParams.get('postid');
    const url_site_id = searchParams.get('siteid');
    const navigate = useNavigate(); 
    const [siteIDSelected, setSiteIDSelected] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [statusFilter, setStatusFilter] = useState([
        {
            label: 'Select',
            value: 'select',
            type: '0'
        },
    ]);
    const Statuses = [
        {
            label: 'Select',
            value: 'select',
            type: '0'
        },
        {
            label: 'All',
            value: 'all',
            type: '0'
        },
        {
            label: 'Needs Images',
            value: 'needsImages',
            type: '3'
        },
        {
            label: 'Image Approval Pending',
            value: 'imagesSelected',
            type: '3'
        },
        {
            label: 'Rejected',
            value: 'rejected',
            type: '3'
        },
        {
            label: 'Approved',
            value: 'approved',
            type: '3'
        },
        {
            label: 'Content Posted',
            value: 'published',
            type: '3'
        },


    ];
 
    useEffect(() => {
        if (props.RoleId !== undefined) {
            setViewId(props.RoleId);
            ReactSession.set("view", props.RoleId);
            
        }
        else if (typeof ReactSession.get("view") != 'undefined') {
            setViewId(ReactSession.get("view"));
        }
        else {
            setViewId(4);    
        }
        
        fetchSiteList();
        if (url_site_id !== null) {
            fetchDraftPosts(url_site_id);
            const site = autoCompleteSiteList.filter(post => post.id == url_site_id);
            setSiteId(site[0]);
            
        }
    }, []);

    //useEffect(() => { console.log("post selection changed !!")}, [postSelected]);
    useEffect(() => {
        if (draftPosts.length > 0) {
            filterList(statusFilter[0].value);
            if (url_post_id !== null) {
                setPostSelected(draftPosts.filter(post => post.db_id == url_post_id));
                setAddPostImages(true);
                const site = autoCompleteSiteList.filter(post => post.id == url_site_id);
                setSiteId(site[0]);
            }
            
        }
    }, [draftPosts]); 

    useEffect(() => {
        filterList(statusFilter[0].value);
    }, [searchInput]);

    const searchChangeHandler = (event) => {
        setSearchInput(prevState => { return event.target.value; });
        
    }
    async function fetchSiteList() {
        let response = await fetch('sites/getsites');
        let data = await response.json();

        setSiteList(data);
        let sites = [{ label: "Select ", id: 0 }];
        data.forEach(site => {
            
            const option = { label: site.name, id: site.id };
            sites.push(option);
        });
        setAutoCompleteSiteList(sites);
    }   

    const statusSelectionHandler = (e) => {
        
        
        let status = Statuses.filter(item => item.value.includes(e.target.value));
        setStatusFilter(status);
        filterList(e.target.value);
    }
    const siteSelectionChanged = async (event, value) => {
        //setSiteName(value.label);
        setSiteId(value);
        
        await fetchDraftPosts(value.id);
    }

    const filterList = (status) => {
        console.log(draftPosts);
        let filteredList_1 = draftPosts.filter(post => post.status > 1

            //((post.content_Editing == 0 && post.is_google_doc == 0) && post.status > 1) && post.status < 6
        );
        filteredList_1 = filteredList_1.filter(post => post.post_title.toLowerCase().includes(searchInput.toLowerCase())); 
        console.log(status);
        if (status == 'select') {
            
            if (viewID == 5) {
                status = 2;
                let filteredList = filteredList_1.filter(post => (post.status == 3));
                setFilteredDraftList(filteredList);
            }

            else if (roleId == 4 || viewID == 4) {
                let filteredList = filteredList_1.filter(post => post.status < 6 && post.status > 1);
                setFilteredDraftList(prevState => { return filteredList; });
            }

            else {
                setFilteredDraftList(prevState => { return filteredList_1; });

            }
        }
        else if (status == 'all') {
            setFilteredDraftList(prevState => { return filteredList_1; });
        }
        else if (status == 'needsImages') {
            let filteredList = filteredList_1.filter(post =>  post.status == 2);
            setFilteredDraftList(prevState => { return filteredList; });
        }
        else if (status == 'imagesSelected') {
            let filteredList = filteredList_1.filter(post => post.status == 3);
            setFilteredDraftList(prevState => { return filteredList; });
        }
        else if (status == 'rejected') {
            let filteredList = filteredList_1.filter(post =>  post.status == 5);
            setFilteredDraftList(prevState => { return filteredList; });
        }
        else if (status == 'approved') {
            let filteredList = filteredList_1.filter(post => post.status == 4);
            setFilteredDraftList(prevState => { return filteredList; });
        }
        else if (status == 'published') {
            let filteredList = filteredList_1.filter(post =>  post.status == 6);
            setFilteredDraftList(prevState => { return filteredList; });
        }
    }
 

    async function fetchDraftPosts(id) {
        const data = { 'id': id };
        //let response = await fetch('post/draftposts');
        //let post_data = await response.json();
        
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/draftposts', config)
            const json = await response.json()
            if (response.ok) {
                
                setDraftPosts(json);
                
            } else {

            }
        } catch (error) {
            console.log(error);
        }
        //setSiteList(data);
    }

    const selectPostHandler = (id) => {
        
        
        //console.log(draftPosts.filter(post => post.id == id));
        const post_selected = draftPosts.filter(post => post.id == id);
        setPostSelected(draftPosts.filter(post => post.id == id));
        setAddPostImages(true);
        //window.history.replaceState(null, "New Page Title", "/AddPostImages?postid=".id);
        //history.push('/AddPostImages?postid=' . id, { shallow: true });
        navigate('/AddPostImages?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id);

    }

    const modalCloseHandler = () => {
        setAddPostImages(false);
        if (roleId == 5 || viewID == 5) {
            navigate('/ApproveDraftPosts');
        }
        else {
            navigate('/DraftPosts');
        }
        setSiteId(siteId);
        console.log(siteId);
    }

    const onNextPostHandler = () => {
        
        let i = filteredDraftList.findIndex((row) => {
            return row.db_id == postSelected[0].db_id;
        }); 
        
        let new_post = [];
        if (filteredDraftList.length-1 > i) {
            new_post[0] = filteredDraftList[i + 1];
        }
        else {
            new_post[0] = filteredDraftList[0];
        }
        
        setPostSelected(prevPostSelected => ({
            ...new_post
        }));
        /*setTimeout(() => {
           
        }, 100);*/
        //console.log("Post selection changed");
        //console.log(new_post);
        navigate('/AddPostImages?postid=' + new_post[0].db_id + '&siteid=' + new_post[0].site_id);
    }

    const onPostListUpdate=() => {
        fetchDraftPosts(siteId.id);
    }

    const onPrevPostHandler = () => {
        let i = filteredDraftList.findIndex((row) => { 
            return row.db_id == postSelected[0].db_id;
        }); 
        let new_post = [];
        if (i > 0) {
            new_post[0] = filteredDraftList[i - 1];
        }
        else {
            new_post[0] = filteredDraftList[filteredDraftList.length - 1];
        }
        
        setPostSelected(new_post);
        navigate('/AddPostImages?postid=' + new_post[0].db_id + '&siteid=' + new_post[0].site_id);
    }

    const siteOptions = siteList.map(site => <option value={site.id} >{site.name}</option>);

    return (
        <>
            {addPostImages == false && 
                <>
                    <Card>
                        <div className="container-fluid">
                            <h3>Draft Posts</h3>
                            <div className="row">
                                <div className="col">

                                <Autocomplete
                                    autoSelect={true}
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    value={siteId}
                                    inputValue={siteIDSelected}
                                    onInputChange={(event, newInputValue) => {
                                        setSiteIDSelected(newInputValue);
                                    }}
                                        //isOptionEqualToValue={(option, siteId) => option.id === siteId.id}
                                        options={autoCompleteSiteList}
                                        sx={{ width: 300 }}
                                        onChange={siteSelectionChanged}
                                        renderInput={(params) => <TextField {...params} label="Select Site" />}
                                    />
                                </div>
                                <div className="col">
                                Status: <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={statusFilter[0].value}
                                    label=""
                                    onChange={statusSelectionHandler}
                                    sx={{ width: 300 }}

                                >
                                    {Statuses.map((item, index) => (
                                        <MenuItem value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                    
                                </div>
                        </div>
                            <br></br>
                             <div className="row">
                                <div className="col">
                                    <input className="form-control" onChange={searchChangeHandler} name="searchBy" placeholder="Search By Title" ></input>
                                </div>
                             </div>
                        </div>
                    </Card>
                    <br></br>
                
                <DraftPostColumns data={filteredDraftList} onSelectPost={selectPostHandler} updatePostList={onPostListUpdate} />
                </>
            }
            
            {addPostImages && <AddImagesToPost postDetails={postSelected} siteName={siteId.label} onNextPost={onNextPostHandler} onPrevPost={onPrevPostHandler} siteId={siteId.id} onCloseModal={modalCloseHandler} roleId={roleId} viewID={viewID} updatePostList={ onPostListUpdate} ></AddImagesToPost> }
            
        </>
    );
}

export default DraftPosts;
import { useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import copy from 'copy-to-clipboard';
import {
    DataGrid, GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
     } from '@mui/x-data-grid';
 
 


const DataGridUI = (props) => {

    
    const [pageSize, setPageSize] = useState(5);
    const [cellCopied, setCellCopied] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const handleEdit = (newRow) => {
        
        props.onHandleEditClick(newRow);
        return newRow;
    }
    const handleProcessRowUpdateError = (error) => {
        console.log(error);
    }

    const handleClick = () => {
        props.handleNewClick();
    };
    
    const cellClickHandler = (params, event, details) => {
        console.log(params);
        if (params.field === "__check__") {
            return false;
            //props.onRowSelection(ids);
        }
        //event.defaultMuiPrevented = true;
        if (params.cellMode === "edit") { return false; }
        if (event.ctrlKey || event.metaKey) {
            let cellDetails = {
                field: params.field,
                value: params.value,
                rowId: params.id,
            };
            cellClickedHandler(cellDetails, params);
        }
        else {
            removeCellSelection();
        }
        //console.log("In cell selection");
    }
    
    const cellClickedHandler = (cellDetails, params) => {

        setCellCopied((prevState) => {
            //let newState = [prevState, cellDetails];
            const i = prevState.findIndex((row) => {
                return row.rowId === cellDetails.rowId && row.field === cellDetails.field;
            });
            
            const divId = cellDetails.rowId + "_" + cellDetails.field;
            const divLink = document.getElementById(divId); 
            if (i > -1) {
                prevState.splice(i, 1);
                divLink.className = '';
            }
            else {
                prevState.push(cellDetails);
                divLink.className = 'cell_selected';
            }
            return prevState;
        });
       
        let textToCopy = cellCopied.map((item) => {
            return item.value;
        }).join("\r\n");
        copy(textToCopy);
        //props.reloadGrid();
    }
    

    const removeCellSelection = () => {
        if (cellCopied.length > 0) {
            cellCopied.forEach((row, i) => {
                const divId = row.rowId + "_" + row.field;
                const divLink = document.getElementById(divId);
                divLink.className = '';
            });
            setCellCopied([]);
            //props.reloadGrid();
        }
        
    }
    const cellMouseDownHandler = (params, event, details) => {
        console.log(params);
    }
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
                    <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                        Add record
                    </Button>
                
            </GridToolbarContainer>
        );
    }

    const handleEvent: GridEventListener<'cellMouseDown'> = (
        params,  // GridCellParams<any>
        event,   // MuiEvent<React.MouseEvent<HTMLElement>>
        details, // GridCallbackDetails
    ) => { console.log(params); }


    return (
        <Box sx={{
            height: 500, width: '100%',
            '& .cell_selected': {
                backgroundColor: 'rgba(39, 159, 255, 0.4)',
                color: '#1a3e72',
                fontWeight: '600',
            },
            
            '& .MuiDataGrid-cell': {
                padding: 0,
                paddingRight: 2,
                
            },
            
        }}>
            <DataGrid
                autoHeight
                getRowHeight={() => 'auto'}
                editMode="row"
                rows={props.data}
                columns={props.columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                disableRowSelectionOnClick
                checkboxSelection={true}
                onRowSelectionModelChange={(ids) => {
                    props.onRowSelection(ids);
                }}
                //rowSelectionModel={rowSelectionModel}

                experimentalFeatures={{ newEditingApi: true }}
                initialState={{
                    sorting: {
                        sortModel: [
                            {
                                field: 'rating',
                                sort: 'desc',
                            },
                        ],
                    },
                }}
                processRowUpdate={handleEdit}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{ toolbar: CustomToolbar }}
                //onCellClick={cellClickHandler}
                
            />
        </Box>
    );
}

export default DataGridUI;
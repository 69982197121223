import { useState } from "react";
import Modal from '../../UI/Modal';

import TextField from '@mui/material/TextField';


const AddSite = (props) => {
    const [siteName, setSiteName] = useState(props.siteDetails.name);
    const [siteUrl, setSiteUrl] = useState(props.siteDetails.url);
    const [authenticationToken, setAuthenticationToken] = useState(props.siteDetails.authentication_Token);
    const [username, setUsername] = useState(props.siteDetails.username);
    const [site, setSite] = useState(props.siteDetails);

    const siteNameInputHandler = (e) => {
        setSiteName(e.target.value);
        setSite({ ...site, ['name']: e.target.value });
        
        
    }
    const siteUrlInputHandler = (e) => {
        setSiteUrl(e.target.value);
        setSite({ ...site, ['url']: e.target.value });
    }

    const usernameInputHandler = (e) => {
        setUsername(e.target.value);
        setSite({ ...site, ['username']: e.target.value });
    }
    const authenticationTokenInputHandler = (e) => {
        setAuthenticationToken(e.target.value);
        setSite({ ...site, ['authentication_Token']: e.target.value });
    }

    const onFormSubmitHandler = (event) => {
        saveSite();
    }

    async function saveSite() {
        try {
            const data = site;
            console.log(data);
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('sites/savesite', config)
            //const json = await response.json()
            if (response.ok) {
                
            } else {

            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal onCloseModel={props.onCloseModel}>
            <div className="container">

                <div className="row">
                   

                    <div className="col">
                        <h5>Add/Edit Site</h5>


                        <form onSubmit={onFormSubmitHandler}>


                            <div className="form-group">
                                <TextField
                                    id="siteName"
                                    label="Name"
                                    value={siteName}
                                    onChange={siteNameInputHandler}
                                    fullWidth
                                    margin="normal"
                                    error={siteName === ""}
                                    helperText={siteName === "" ? 'Please provide Site Name!' : ' '}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <TextField
                                    id="siteUrl"
                                    label="URL"
                                    value={siteUrl}
                                    onChange={siteUrlInputHandler}
                                    fullWidth
                                    margin="normal"
                                    error={siteUrl === ""}
                                    helperText={siteUrl === "" ? 'Please provide Site URL!' : ' '}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <TextField
                                    id="username"
                                    label="Username"
                                    value={username}
                                    onChange={usernameInputHandler}
                                    fullWidth
                                    margin="normal"
                                    error={username === ""}
                                    helperText={username === "" ? 'Please provide username!' : ' '}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <TextField
                                    id="authenticationToken"
                                    label="Authentication Token"
                                    value={authenticationToken}
                                    onChange={authenticationTokenInputHandler}
                                    fullWidth
                                    margin="normal"
                                    error={authenticationToken === ""}
                                    helperText={authenticationToken === "" ? 'Please provide authentication token!' : ' '}
                                    required
                                />
                            </div>

                          
                          

                            <button type="submit" className="btn btn-primary">Submit</button>
                            <button type="button" className="btn btn-default" onClick={props.onCloseModal} >Cancel</button>

                        </form>
                    </div>
                </div>
            </div>
        </Modal>
        );
}

export default AddSite;
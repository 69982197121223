import SimpleGrid from '../../UI/SimpleGrid';
import ModeIcon from '@mui/icons-material/Mode';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PopUp from '../../UI/PopUp';
import { useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DraftPostColumns = (props) => {

    const [alertMessage, setAlertMessage] = useState('');
    const [showAlertMessage, setShowAlertMessage] = useState(false);

    const [showPopUp, setShowPopUp] = useState(false);
    const [popButtonText, setPopButtonText] = useState('');
    const [popUpButtonColor, setPopUpButtonColor] = useState('');
    const [popUpAction, setPopUpAction] = useState('');
    const [popUpMsg, setPopUpMsg] = useState('');
    const [popUpHeaderMsg, setPopUpHeaderMsg] = useState('');

    
    let showSite = false;
    if (props.showSiteColumn == true) {
        showSite = true;
    }
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        site: showSite,
        
    });
    

    const columns = [
        {
            field: 'site',
            headerName: 'Site',
            headerClassName: 'DataGridHeader',
            flex: .5,
            renderCell: (params) => {
                console.log(params);
                const post_site = props.SiteList.filter(site => site.id == params.row.site_id)
                console.log(post_site[0].name);
                return post_site[0].name;
            },
        },
        {
            field: 'post_title',
            headerName: 'Title',
            headerClassName: 'DataGridHeader',
            flex: 1
        },
        {
            field: 'post_url',
            headerName: 'URL',
            headerClassName: 'DataGridHeader',
            flex: 1.7,
            renderCell: (params) => {
                return <a href={params.row.post_url} target="_blank">{params.row.post_url}</a>;
            },
        },
        {
            field: 'post_date',
            headerName: 'Post Date',
            headerClassName: 'DataGridHeader',
            flex: .5,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'DataGridHeader',
            flex: .5,
            renderCell: (params) => {
                
                const status = params.row.status;
                const status_text = params.row.status_text;
                if (params.row.content_Editing == 1) {

                    if (status == 0) {
                        if (status_text != null && status_text.toLowerCase().includes('rejected'))
                            return "Content Rejected";
                        else 
                            return "Needs Edited";
                    }
                    if (status == 1 && status_text.toLowerCase().includes('content reject edited')  )
                        return "Content Reject Edited";
                    else if (status == 1 )  
                        return "Edit Approve Pending";
                }
                if (params.row.is_google_doc == 1) {
                   /* if (status == 0 && status_text.toLowerCase().includes('rejected'))
                        return "Content Rejected";
                    else if (status == 0)
                        return "Content Rejected";*/
                    if (status == 0) {
                        if (status_text != null && status_text.toLowerCase().includes('rejected'))
                            return "Content Rejected";
                        else
                            return "Needs Edited";
                    }
                    if (status == 1 && status_text.toLowerCase().includes('content reject edited'))
                        return "Content Reject Edited";
                    else if (status == 1)
                        return "Edit Approve Pending";
                }
                if (status == 2)
                    return "Needs Images";
                if (status == 3)
                    return "Images Selected";
                if (status == 4)
                    return "Images Approved";
                if (status == 5)
                    return "Images Rejected";
                if (status == 6)
                    return "Content Posted";
                return "";
            },

        },
        {
            field: "action",
            headerName: "Actions",
            headerClassName: 'DataGridHeader',
            sortable: false,
            flex: 0.3,
            renderCell: (params) => {

                const onPostSelect = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();
                    props.onSelectPost(params.id);
                    return "";

                };

                const onPostDelete = (e) => {
                    
                    setPopUpMsg('Are you sure want to delete post ' + params.row.post_title + '?');
                    setPopUpHeaderMsg('Delete Post');
                    setPopButtonText('Delete');
                    setPopUpButtonColor('btn btn-danger');
                    console.log(params.row.db_id);
                    
                    
                    setPopUpAction(params.row.db_id);
                    setShowPopUp(true);
                    
                    e.stopPropagation();
                    e.preventDefault();

                }

                return <div>
                    <button className="btn btn-success" value="Select" onClick={onPostSelect}>Select</button> &nbsp;
                    {props.showDeleteButton && <Tooltip title="Remove" placement="top-start">
                        <IconButton onClick={onPostDelete}>
                            <DeleteOutlineIcon className="cursor" />
                        </IconButton>
                    </Tooltip>
                    }
                </div>;
            },

        },


    ];

    const popUpActionHandler = () => {
        //popUpAction 
        console.log(popUpAction);
        deleteImage(popUpAction);
        setPopUpAction(0);
        props.updatePostList();
        setShowPopUp(false);
    }

    async function deleteImage(id) {
        try {
            const data = { id: id };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/deletepost', config)

            if (response.ok) {
                //const json = await response.json()
                //return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    const popUpCloseButtonHandler = () => {

        setShowPopUp(false);
    }

    return (
        <>
            <SimpleGrid columns={columns} data={props.data} columnVisibilityModel={columnVisibilityModel} />
            {showPopUp && <PopUp popUpText={popUpMsg} headerText={popUpHeaderMsg} buttonText={popButtonText} buttonClass={popUpButtonColor} onClosePopUp={popUpCloseButtonHandler} onPopUpConfirm={popUpActionHandler} />}
        </>
    )
}

export default DraftPostColumns
import * as React from 'react';
import { useNavigate } from "react-router-dom"
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Modal from '@mui/material/Modal';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Loading } from './Animation/Loading';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ReactSession } from 'react-client-session';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';


// Function Name Should be Our file name and we use this file  for showing Customer Information.
//This  User page is used for display all Customer information and we can also add new Customer or update system Customer information like PrimaryEmail,status etc
//we use customerinfo controller for this page
//We call six web service method for  this page
//1-Get -- this method is use for get all customer data and show on page.
//2-Save-- this method is call when we add new customer in system .
//3-Update -- this method is call when we update system customer information.
//4-Delete -- this method is use when we have to delete customer from system.
//5-ChangeCustStatus -- this method is use when we have to change customer  status.
//6-SaveAudit -- this method is use to save audit information.
//Bussiness logic for this page in short it
// we add/edit customer from this page and assign status to customer if all information set correctly then we add record  and if not we show error in snack bar


// This is MUI's Alert function which is encapsulated inside the Snakbar
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// style is use for setup look and feel for grid and modal pop up
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 50,
    p: 4,
};


//StyledTableCell is used for set Table cell  style .
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "skyblue",
        color: theme.palette.common.white,
        onmouseover: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

//StyledTableRow is used for set Table Row  style .
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 50,
    p: 4
};

const styletrrd = {
    padding: '10px'
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// HeadCells is use for showing Datagrid Column name
const headCells = [
    {
        id: 'custNumber',
        numeric: false,
        disablePadding: false,
        label: 'Customer #',
    },
    {
        id: 'cName',
        numeric: false,
        disablePadding: false,
        label: 'Customer Name',
    },
    {
        id: 'cEmail',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'cStatus',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    //{
    //    id: 'createdBy',
    //    numeric: true,
    //    disablePadding: false,
    //    label: 'CreatedBy',
    //},

];

// EnhancedTableHead is use for Set data grid head property
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell></StyledTableCell>

                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        /* align={headCell.numeric ? 'right' : 'left'}*/
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden} >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    <h2>Customers Data </h2>
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const Customers = ({ navigate }) => {
    navigate = useNavigate(); // This function is used for navigation between the pages from one page to another page.
    //ReactSession.setStoreType("localStorage");
    const id = 0;//ReactSession.get("custid")


     //We have used the state as a useState to prevent the Snakbars state and by this we can dynamically set the state properties
    //for Snakbars different -different menthod and its associated behavious like if we set "severity" as "error" then error
    //message will comes in red
    const [state, setState] = React.useState({
        snackopen: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'success', //error//warning//info
        message: ''
    });
    const { vertical, horizontal, snackopen, message, severity } = state;

    const [apirows, setApirows] = React.useState([]);

    const [addbtntext, setAddbtntext] = React.useState("Add Customer");
    const [check, setCheck] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    //we have used order as a usestate to set data as assesnding order.
    const [order, setOrder] = React.useState('asc');
    //we have used orderBy  as a usestate to apply shorting on custnumber column.
    const [orderBy, setOrderBy] = React.useState('custNumber');
    const [selected, setSelected] = React.useState([]);
     //we have used page as a usestate to set page order.
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    //we have used rowsPerPage as a usestate to set how many rows we have to display on page.
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    //Model Popup Hooks

    const [open, setOpen] = React.useState(false);
    const [SelelctedID, SetSelelctedID] = React.useState(0);
    const [SelelctedUsername, SetSelelctedUsername] = React.useState("");

    const [statusOpen, setStatusOpen] = React.useState(false);
    const [SelelctedIDCust, SetSelelctedIDCust] = React.useState(0);
    const [SelelctedIDStatus, SetSelelctedIDStatus] = React.useState(0);

    //we have used btntext as a usestate to set button text base on customer status.
    const [btntext, setBtntext] = React.useState("");

    // We have used formerros to prevent the error message on the form and based on users input we used to change the instance of the
    //formerrors properties
    const [formerros, setFormerros] = React.useState({});

    // We have used caddbtntext to set button text  based on users input 
    const [caddbtntext, setCaddbtntext] = React.useState("Save");


    //this method is called for open status filter pop up.
    const handleOpenStatus = (event, customerID, customerStatus) => {
        setStatusOpen(true);
        if (customerStatus == 0) {
            setBtntext("Active");
            SetSelelctedIDStatus(1);
        }
        else {
            setBtntext("Banned");
            SetSelelctedIDStatus(0);
        }

        SetSelelctedIDCust(customerID);

    }

    const handleOpen = (event, customerID, customerName) => {
        setOpen(true);
        SetSelelctedID(customerID);
        SetSelelctedUsername(customerName);
    }

    const handleDeleteUser = async (customerID) => {

        await deleteUserData(customerID);
        setOpen(false);

    }

    //this method is used for call customer status change method.
    const handleChangeStatusCust = async (customerID, StatusID) => {

        await changecutStatus(customerID, StatusID);
        setStatusOpen(false);

    }

    //this method is used for call to close snack bar.
    const handleCloseSnack = () => {
        setState({ ...state, snackopen: false, message: '' });
    };

     //this method is used for call to close customer pop up.
    const handleClose = () => setOpen(false);

     //this method is used for call to close status pop up.
    const handleCloseStatus = () => setStatusOpen(false);

      //We have used the values as useState hooks to prevent the customer values
    //we will set the method based on the users input and html component behaviour
    const [values, setValues] = React.useState({
        customerID: '',
        cName: '',
        cEmail: '',
        cStatus: -1,
        payPalEmailNotes: '',
        additionalEmails: '',
        pricingNotes: '',
        customerNotes: '',
        custNumber: '',
        loading: false
    });

    const [cvalues, setCvalues] = React.useState({
        customerID: '',
        cName: '',
        cEmail: '',
        cStatus: -1,
        payPalEmailNotes: '',
        additionalEmails: '',
        pricingNotes: '',
        customerNotes: ''

    });

    const getCustomerByID = async (id) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
/* fetch(`customerinfo/GetCustomerByID/${id}`, { method: 'GET' })*/
        fetch(`customerinfo/GetCustomerByID/${id}`, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {

                    if (typeof (data) == "string") {
                        setState({ ...state, snackopen: true, message: data, severity: 'error' });
                    }
                    else {
                        setValues({
                            ...values,
                            customerID: id, cName: data.cName, cEmail: data.cEmail, cStatus: data.cStatus, payPalEmailNotes: data.payPalEmailNotes, additionalEmails: data.additionalEmails, pricingNotes: data.pricingNotes, customerNotes: data.customerNotes
                        });
                    }
                }
            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }
    }
    const OVERLAY_STYLE = {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0, .8)",
        zIndex: "1000",
        overflowY: "auto"
    };

     //This is the Web API Put method the call the Update and we are sending the customer information  as the parameter of the put
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const editcustomerdata = () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        if (Number(cvalues.customerID) > 0) {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
               /* headers: { 'Content-Type': 'application/json' },*/
                body: JSON.stringify({ CName: cvalues.cName, CEmail: cvalues.cEmail, CStatus: cvalues.cStatus, CustomerID: cvalues.customerID, payPalEmailNotes: cvalues.payPalEmailNotes, additionalEmails: cvalues.additionalEmails, pricingNotes: cvalues.pricingNotes, customerNotes: cvalues.customerNotes })
            };

            fetch(`customerinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                        setValues({ ...values, loading: false });
                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                            setValues({ ...values, loading: false });
                        }
                        else {
                            setOpenaddedit(false);
                            setState({ ...state, snackopen: true, message: "Update sucessfully", severity: 'success' });
                            getUserData();
                            //ReactSession.remove("custid");
                            //await navigate('/Customers');
                        }
                        setValues({ ...values, loading: false });
                    }
                })
                .catch(error => {
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                    setValues({ ...values, loading: false });
                });
        }
    }
    }
    //This is the Web API Post method the call the Save method and we are sending the customer information  as the parameter of the post
    //method
    //This method will update the state and values useState properties based on the response we'll get by the Web API. 
    const addcustomerdata = () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        if (Object.keys(cvalues).length > 0) {
            const requestOptions = {
                method: 'POST',
                /* headers: { 'Content-Type': 'application/json' },*/
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ CName: cvalues.cName, CEmail: cvalues.cEmail, CStatus: cvalues.cStatus, CustomerID: cvalues.customerID, payPalEmailNotes: cvalues.payPalEmailNotes, additionalEmails: cvalues.additionalEmails, pricingNotes: cvalues.pricingNotes, customerNotes: cvalues.customerNotes })
            };
            fetch(`customerinfo`, requestOptions)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        setState({ ...state, snackopen: true, message: error, severity: 'error' });
                        setValues({ ...values, loading: false });
                    }
                    else {
                        if (typeof (data) == "object") {
                            setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                            setValues({ ...values, loading: false });
                        }
                        else {
                            setOpenaddedit(false);
                            setState({ ...state, snackopen: true, message: "Save sucessfully", severity: 'success' });
                            getUserData();
                            //if (ReactSession.get("RedirectPage") === 1) {
                            //    ReactSession.remove("custid");
                            //    ReactSession.remove("RedirectPage");
                            //    await navigate('/AddEditOrder');
                            //} else {
                            //    ReactSession.remove("custid");
                            //    await navigate('/Customers');
                            //}
                        }
                        setValues({ ...values, loading: false });
                    }
                })
                .catch(error => {
                    setState({ ...state, snackopen: true, message: error });
                    setValues({ ...values, loading: false });
                });
        }
    }
    }
      //This is the web API Get method and we call Get method and show customer information on grid.
    const getUserData = async () => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        var options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        } 
        try {
            const response = await fetch('customerinfo', options);
            const data = await response.json();
            if (data) {
                setRows(data);
                setApirows(data);
                setLoading(false);

                setAddbtntext("Add Customer");
                setValues({
                    ...values,
                    customerID: 0, cName: "", cEmail: "", cStatus: -1, payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
                });
            }
        } catch (error) {
            setLoading(true);
        }
    }
    }
     //This is the web API Delete method and delete method remove customer information base on customerid.
    const deleteUserData = async (id) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        fetch(`customerinfo/${id}`, {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {

                    if (typeof (data) == "object") {
                        setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                    }
                    else {
                        setState({ ...state, snackopen: true, message: 'Deleted Sucessfully.', severity: 'success' });
                        getUserData();
                    }

                }


            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }
    }
    //this method is call when we change customer status .
     //we call web API ChangeCustStatus method and send customerid and status and update status.
    const changecutStatus = async (id, status) => {
        var token = ReactSession.get("Token");
        const jwtPayload = (JSON.parse(atob(token.split('.')[1]))).exp;

        if (jwtPayload * 1000 < Date.now()) {
            alert("Token is expired please login again.");
            ReactSession.remove("UserName");
            ReactSession.remove("UserID");
            ReactSession.remove("RoleID");

            window.location.href = "/";

        } else {
        fetch(`customerinfo/ChangeCustStatus/${id}/${status}`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            } })
            .then(async response => {
                const data = await response.json();
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    setState({ ...state, snackopen: true, message: error, severity: 'error' });
                }
                else {

                    if (typeof (data) == "object") {
                        setState({ ...state, snackopen: true, message: data.error, severity: 'error' });
                    }
                    else {
                        setState({ ...state, snackopen: true, message: 'Status Updated Successfully.', severity: 'success' });
                        getUserData();
                    }


                }

            })
            .catch(error => {
                setState({ ...state, snackopen: true, message: error, severity: 'error' });
            });
    }
    }
     //This method will call the WEB API save/update method .
    const AddCustomer = async (event, CustomerID) => {
        setValues({ ...values, loading: true });
        if (caddbtntext == "Update") {
            
            setFormerros(validate(cvalues));
            if (Object.keys(formerros).length === 0) {
                await editcustomerdata();
            }

        } else {
            setFormerros(validate(cvalues));
            if (Object.keys(formerros).length === 0) {
                await addcustomerdata();
            }
        }
    }

    useEffect(() => {

        getUserData();

    }, [])


     //useEffect - Hooks is used to handle the page rendering and it will call when ever the page loads for the first time
    //as we have restricted it with [].
    useEffect(() => {

        if (id == 0) {
            setCaddbtntext("Save");
            setCvalues({
                ...cvalues,
                customerID: 0, cName: "", cEmail: "", cStatus: -1, payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
            });
        }
        else {
            setCaddbtntext("Update");
            getCustomerByID(id);
        }
        setFormerros({
            ...formerros, cName: "", cEmail: "", cStatus: "", payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
        });

    }, [])

    const [openaddedit, setOpenaddedit] = React.useState(false);

     //This method will call for close modal pop up.
    const handleClosepopup = () => setOpenaddedit(false);

     //This method will call when user click on Add/edit  customer button to open modal popup.  .
    const handleOpenpopup = (event, userobject, flag) => {
        //alert(userobject.userID);

        if (flag === 0) {
            //alert("true");
            setCaddbtntext("Add Customer");
            setOpenaddedit(true);
            //console.log(userobject);
            setCvalues({
                ...cvalues,
                customerID: 0, cName: "", cEmail: "", cStatus: -1, payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
            });
            //console.log(values);
            setFormerros({
                ...formerros, cName: "", cEmail: "", cStatus: "", payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
            });

        } else {
            // alert("flase");
            // console.log(userobject);
            setCaddbtntext("Update Customer");
            setOpenaddedit(true);
            console.log(userobject);
            setCvalues({
                ...cvalues,
                customerID: userobject.customerID, cName: userobject.cName, cEmail: userobject.cEmail, cStatus: userobject.cStatus, payPalEmailNotes: userobject.payPalEmailNotes, additionalEmails: userobject.additionalEmails, pricingNotes: userobject.pricingNotes, customerNotes: userobject.customerNotes
            });
            //console.log(values);

            setFormerros({
                ...formerros, cName: "", cEmail: "", cStatus: "", payPalEmailNotes: "", additionalEmails: "", pricingNotes: "", customerNotes: ""
            });
        }
    }

    //this method is used for applying validation on user input if any condtion is not match then we show warning on control
    const validate = (cvalues) => {    
        const errors = {};
        const regex1 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const regexcomma = /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/;


        if (!cvalues.cName) {
            errors.cName = "Customer Name is required.";
        }

        if (!cvalues.cEmail) {
            errors.cEmail = "Email is required.";
        }
        else if (!regex1.test(cvalues.cEmail)) {
            errors.cEmail = "Invalid Email.";
        }

        if (cvalues.cStatus == -1) {
            errors.cStatus = "Status is required.";
        }

        if (!cvalues.payPalEmailNotes) {
            errors.payPalEmailNotes = "PayPal Email Notes is required.";
        }

        if (!cvalues.additionalEmails) {
            errors.additionalEmails = "Additional Emails is required.";
        }
        //else if (!regexcomma.test(cvalues.additionalEmails)) {
        //    errors.additionalEmails = "Invalid Email."
        //}

        if (!cvalues.pricingNotes) {
            errors.pricingNotes = "Pricing Notes is required.";
        }

        if (!cvalues.customerNotes) {
            errors.customerNotes = "Customer Notes is required.";

        }

        if (Object.values(errors).every(val => val === "")) {
            if (caddbtntext == "Update") {
                editcustomerdata();
            }
            else {
                addcustomerdata();
            }
        }
        return errors;
    }

    //this method is use for applying filter on grid value here we apply filter on grid base on customer name or customer email
    const requestSearch = (searchedVal) => {
        console.log("Search value is => " + searchedVal.toLowerCase());

        if (searchedVal) {
            const filteredRows = rows.filter((row) => {
                return row.cName.toLowerCase().includes(searchedVal.toLowerCase()) || row.cEmail.toLowerCase().includes(searchedVal.toLowerCase());
                //return row;
            });
            setRows(filteredRows);
        } else {
            setRows(apirows);
        }
    };

     //this method is use for applying filter on grid value here we apply filter on grid base on customer number 
    const requestSearchByCustomerId = (searchedVal) => {

        

        if (searchedVal) {

            const filteredRows = rows.filter((row) => {
                return String(row.custNumber).toLowerCase().includes(searchedVal.toLowerCase())
                //return row;
            });
            setRows(filteredRows);
        } else {
            setRows(apirows);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.customerID);
            // setSelected(newSelected);

            return;
        }
        //setSelected([]);
    };

    //this method is use for redirect user to customer add/edit page
    const handleNavigate = (event, orderobject, flag) => {
        if (flag == 1) {
            navigate('/AddEditCustomer');
            ReactSession.setStoreType("localStorage");
            ReactSession.set("custid", orderobject.customerID);
        }
        else {
            navigate('/AddEditCustomer');
            ReactSession.setStoreType("localStorage");
            ReactSession.set("custid", 0);
        }
    }

    //this method will call when OnChange of customer field will be called and it will set the properties value accordingly
    //we are have made it dynamically using prop.
    const handleChange = (prop) => (event) => {
        setCvalues({ ...cvalues, [prop]: event.target.value });
    };

    const handleClick = (event, customerID) => {
        const selectedIndex = selected.indexOf(customerID);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, customerID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        // setSelected(newSelected);
    };

    //this method is call when we change page.
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (customerID) => selected.indexOf(customerID) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    if (loading) {
        return (
            <Loading />
        )
    }

    //return is having the form controls which we can see when we run the page.
    return <>
        <Box sx={{ width: '100%' }}>

            <div>
                <Modal
                    open={openaddedit}
                    onClose={handleClosepopup}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={OVERLAY_STYLE}
                >
                    <Box sx={style1}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Add/ Edit Customer
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <table style={{ "width": "100%" }} >                          
                                <tr style={styletrrd} >
                                    <td style={styletrrd}>
                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.cEmail} >
                                            <InputLabel htmlFor="outlined-adornment-firstname" >Customer Primary Email</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname"
                                                label="Email"
                                                value={cvalues.cEmail}
                                                onChange={handleChange('cEmail')}
                                            />
                                            <FormHelperText>{formerros.cEmail}</FormHelperText>
                                        </FormControl>
                                    </td>
                                    <td rowSpan="2" style={styletrrd}>
                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.pricingNotes} >
                                            <InputLabel htmlFor="outlined-adornment-firstname">Pricing Notes</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname"
                                                label="Pricing Notes"
                                                multiline
                                                rows={5}
                                                value={cvalues.pricingNotes}
                                                onChange={handleChange('pricingNotes')}
                                            />
                                            <FormHelperText>{formerros.pricingNotes}</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={styletrrd}>
                                    <td style={styletrrd}>
                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.cName} >
                                            <InputLabel htmlFor="outlined-adornment-firstname">Company Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname"
                                                label="Customer Name"
                                                value={cvalues.cName}
                                                onChange={handleChange('cName')}
                                            />
                                            <FormHelperText>{formerros.cName}</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>

                                <tr style={styletrrd}>
                                    <td style={styletrrd}>

                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.payPalEmailNotes}>
                                            <InputLabel htmlFor="outlined-adornment-firstname">PayPal Email Notes</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname"
                                                label="PayPal Email Notes"
                                                multiline
                                                rows={2}
                                                value={cvalues.payPalEmailNotes}
                                                onChange={handleChange('payPalEmailNotes')}
                                            />
                                            <FormHelperText>{formerros.payPalEmailNotes}</FormHelperText>
                                        </FormControl>
                                    </td>
                                    <td rowSpan="2" style={styletrrd}>
                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.customerNotes}>
                                            <InputLabel htmlFor="outlined-adornment-firstname">Customer Notes</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname"
                                                label="Customer Notes"
                                                multiline
                                                rows={7}
                                                value={cvalues.customerNotes}
                                                onChange={handleChange('customerNotes')}
                                            />
                                            <FormHelperText>{formerros.customerNotes}</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>

                                <tr style={styletrrd}>
                                    <td style={styletrrd}>
                                        <FormControl fullWidth sx={{ m: 1 }} error={formerros.additionalEmails} >
                                            <InputLabel htmlFor="outlined-adornment-firstname">Additional Emails</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname"
                                                label="Additional Emails"
                                                value={cvalues.additionalEmails}
                                                multiline
                                                rows={2}
                                                onChange={handleChange('additionalEmails')}
                                            />
                                            <FormHelperText>{formerros.additionalEmails}</FormHelperText>
                                        </FormControl>
                                    </td>

                                </tr>

                                <tr style={styletrrd}>                                   
                                    <td style={styletrrd}>  <FormControl fullWidth sx={{ m: 1 }} error={formerros.cStatus}>
                                        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={cvalues.cStatus}
                                            label="Role"
                                            onChange={handleChange('cStatus')}
                                        >
                                            <MenuItem value={-1} >
                                                Select
                                            </MenuItem>
                                            <MenuItem value={1}>Active</MenuItem>
                                            <MenuItem value={0}>Banned</MenuItem>
                                        </Select>
                                        <FormHelperText>{formerros.cStatus}</FormHelperText>
                                    </FormControl></td>
                                </tr>                            
                        </table>
                        <Stack direction="row" alignItems="center" spacing={2} >
                                {/* <Button color="success" variant="contained" onClick={(event) => AddCustomer(event, 0)}>{caddbtntext}</Button>*/}
                                <LoadingButton loading={values.loading} variant="contained" color="success" loadingPosition="start" onClick={(event) => AddCustomer(event, 0)}  >
                                    {caddbtntext}
                                </LoadingButton>
                                                               
                                    
                              
                            <Button variant="contained" onClick={() => handleClosepopup()} color="success">
                                Cancel
                            </Button>
                        </Stack>
                        </Typography>
                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure want to delete {SelelctedUsername} ?
                        </Typography>
                        <Stack direction="row" spacing={2}></Stack>
                        <Stack direction="row" spacing={2} >
                            <Button variant="outlined" color="error" onClick={() => handleDeleteUser(SelelctedID)} startIcon={<DeleteIcon />}>
                                Delete
                            </Button>
                            <Button variant="contained" onClick={() => handleClose()} color="success">
                                Cancel
                            </Button>
                        </Stack>


                    </Box>
                </Modal>
            </div>

            <div>
                <Modal
                    open={statusOpen}
                    onClose={handleCloseStatus}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Change Status
                        </Typography>
                        <Stack direction="row" spacing={2}></Stack>
                        <Stack direction="row" spacing={2} >
                            <Button variant="outlined" color="error" onClick={() => handleChangeStatusCust(SelelctedIDCust, SelelctedIDStatus)} >
                                {btntext}
                            </Button>
                            <Button variant="contained" onClick={() => handleCloseStatus()} color="success">
                                Cancel
                            </Button>
                        </Stack>


                    </Box>
                </Modal>
            </div>

            {/*<Paper sx={{ width: '100%', mb: 2 }}>*/}

            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '5px' }} >
                <Button variant="contained" onClick={(event) => handleOpenpopup(event, values, 0)} color="success">
                    Add Customer
                </Button>

            </div>


            <div style={{ display: 'inline-flex', justifyContent: 'right', alignItems: 'center', padding: '5px' }} >
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300, border: 1 }}
                >

                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search By Customer#"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => requestSearchByCustomerId(e.target.value)}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>

                </Paper>
            </div>


            <div style={{ display: 'inline-flex', justifyContent: 'right', alignItems: 'center', padding: '5px' }} >
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300, border: 1 }}
                >

                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search By Company, Email"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => requestSearch(e.target.value)}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>

                </Paper>
            </div>


            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:rows.slice().sort(getComparator(order, orderBy)) */}
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.customerID);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <StyledTableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.customerID)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.customerID}
                                        selected={isItemSelected}
                                    >
                                        <StyledTableCell align="left">
                                            <Stack direction="row" spacing={2}>
                                                <DeleteForeverRoundedIcon cursor="pointer" onClick={(event) => handleOpen(event, row.customerID, row.cName)} />
                                                {/*<EditIcon cursor="pointer" onClick={(event) => handleNavigate(event, row, 1)}></EditIcon>*/}
                                                <EditIcon cursor="pointer" onClick={(event) => handleOpenpopup(event, row, 1)}></EditIcon>
                                            </Stack>

                                        </StyledTableCell>
                                        <StyledTableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                        >
                                            {row.custNumber}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.cName}</StyledTableCell>
                                        <StyledTableCell align="center">{row.cEmail}</StyledTableCell>
                                        <StyledTableCell align="center">  <Link
                                            component="button"
                                            variant="body2"
                                            onClick={(event) => handleOpenStatus(event, row.customerID, row.cStatus)}
                                        >
                                            {(row.cStatus) == 1 ? ("Active") : ("Banned")}
                                        </Link> </StyledTableCell>
                                        {/*<StyledTableCell align="center">{row.createdBy}</StyledTableCell>*/}
                                    </StyledTableRow >
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/*</Paper>*/}

        </Box>

        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={3000}
            open={snackopen}
            onClose={handleCloseSnack}
            key={vertical + horizontal}
        >
            <Alert onClose={handleCloseSnack} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>

    </>


}


export { Customers };
import { useState, useEffect } from "react";
import DraftPostColumns from '../DraftPosts/DraftPostColumns';
import AddImagesToPost from "./AddImagesToPost";
import Card from '../../UI/Card';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useSearchParams } from "react-router-dom";


const SearchPosts = (props) => {

    const [siteList, setSiteList] = useState([]);
    const [draftPosts, setDraftPosts] = useState([]);
    const [filteredDraftList, setFilteredDraftList] = useState([]);
    const [addPostImages, setAddPostImages] = useState(false);
    const [postSelected, setPostSelected] = useState([]);
    const [siteId, setSiteId] = useState({ id: 0, label: 'Select' });
    const [roleId, setRoleId] = useState(ReactSession.get("RoleID"));
    const [viewID, setViewId] = useState(ReactSession.get("RoleID"));
    const [statusFilter, setStatusFilter] = useState(0);
    const [autoCompleteSiteList, setAutoCompleteSiteList] = useState([{ label: "Select ", id: 0 }]);

    const [searchParams] = useSearchParams();
    const url_post_id = searchParams.get('postid');
    const url_site_id = searchParams.get('siteid');
    const navigate = useNavigate();
    const [siteIDSelected, setSiteIDSelected] = useState('');
    const [searchInput, setSearchInput] = useState('');


 
    useEffect(() => {
        fetchSiteList();
    }, []);
    const searchChangeHandler = (event) => {
        setSearchInput(prevState => { return event.target.value; });

    }
    const searchPostHandler = (event) => {
        event.preventDefault();
        getSearchPosts();
    }
    async function fetchSiteList() {
        let response = await fetch('sites/getsites');
        let data = await response.json();

        setSiteList(data);
        
        
    }

 


    async function getSearchPosts() {
        const data = { id: 0, title:searchInput };
        //let response = await fetch('post/draftposts');
        //let post_data = await response.json();

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/search', config)
            const json = await response.json()
            if (response.ok) {

                setDraftPosts(json);

            } else {

            }
        } catch (error) {
            console.log(error);
        }
        //setSiteList(data);
    }

    const selectPostHandler = (id) => {


        
        const post_selected = draftPosts.filter(post => post.id == id);
        setPostSelected(draftPosts.filter(post => post.id == id));
        const status = post_selected[0].status;
        if (status > 2) {
            if (status == 3) {
                if (ReactSession.get("RoleID") == 1) {
                    navigate('/AddPostImages?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&viewid=5');
                }
                else {
                    navigate('/AddPostImages?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&viewid=4');
                }
            }
            else
                navigate('/AddPostImages?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&viewid=4');
        }
        else {
            //navigate('/EditPostContent?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&is_google_doc=' + post_selected[0].is_google_doc);
            if (status == 1) {
                if (ReactSession.get("RoleID") == 1) {
                    navigate('/EditPostContent?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&is_google_doc=' + post_selected[0].is_google_doc + '&viewid=5');
                }
                else {
                    navigate('/EditPostContent?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&is_google_doc=' + post_selected[0].is_google_doc + '&viewid=4');
                }
            }
            else
                navigate('/EditPostContent?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id + '&is_google_doc=' + post_selected[0].is_google_doc + '&viewid=4');
        }
        
        //navigate('/AddPostImages?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id);

    }

    const modalCloseHandler = () => {
        setAddPostImages(false);
        if (roleId == 5 || viewID == 5) {
            navigate('/ApproveDraftPosts');
        }
        else {
            navigate('/DraftPosts');
        }
        setSiteId(siteId);
        console.log(siteId);
    }

    
 
 
    const onPostListUpdate = () => {
        return;
    }

    const siteOptions = siteList.map(site => <option value={site.id} >{site.name}</option>);

    return (
        <>
            {addPostImages == false &&
                <>
                    <Card>
                        <div className="container-fluid">
                            <h3>Search Posts</h3>
                           
                            <div className="row">
                                <div className="col">
                                    <input className="form-control" onChange={searchChangeHandler} name="searchBy" placeholder="Search By Title" ></input>
                                </div>
                            </div>
                        <button type="button" className="btn btn-primary" onClick={searchPostHandler} >Search </button>&nbsp;
                        </div>
                    </Card>
                    <br></br>

                <DraftPostColumns data={draftPosts} onSelectPost={selectPostHandler} updatePostList={onPostListUpdate} showSiteColumn={true} SiteList={siteList} />
                </>
            }

            

        </>
    );
}

export default SearchPosts;
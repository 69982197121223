import React from 'react';
import { useState, useEffect } from 'react';
import { render } from "react-dom";
import { ReactGrid, Column, Row, CellChange, TextCell } from '@silevis/reactgrid';
import "@silevis/reactgrid/styles.css";

 


const SpreadSheet = (props) => {
    const DropdownOptions = [
        { label: "Replied", value: "Replied" },
        { label: "Not Replied", value: "Not Replied" },
    ];
    /*
    const rowData = props.data.map((contact, i) =>
        [{
            rowId: i + 1,
            cells: [
                { type: "text", text: contact.name },
                { type: "text", text: contact.email },
                { type: "text", text: contact.subject },
                { type: "text", text: contact.message },
                { type: "text", text: contact.created_at },
                { type: "checkbox", checked: false, checkedText: "", uncheckedText: "" },
                { type: "dropdown", selectedValue: "Not Replied", values: DropdownOptions, isDisabled: false, isOpen: false },
            ]

        }]
    );
    
    const emptyRow = [{
        rowId: rowData.length + 1,
        cells: [
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "text", text: "" },
            { type: "checkbox", checked: false, checkedText: "", uncheckedText: "" },
            { type: "dropdown", selectedValue: "Not Replied", values: DropdownOptions, isDisabled: false, isOpen: false },
        ]
    }];
    const headerRow = [{
        rowId: 0,
        cells: [
            { type: "header", text: "Name" },
            { type: "header", text: "Email" },
            { type: "header", text: "Subject" },
            { type: "header", text: "Message" },
            { type: "header", text: "Created At" },
            { type: "header", text: "Action" },
            { type: "header", text: "Status" }
        ]
    }];
    const [rows, setRows] = useState([
        ...headerRow,
        ...rowData.map(singleRow => singleRow[0]),
        ...emptyRow
    ]);
    
    useEffect(() => {
        
        setRows([
            ...headerRow,
            ...rowData.map(singleRow => singleRow[0]),
            ...emptyRow
        ]);
    }, [props.data]);

    */

    //console.log(Rows_temp);
    const [columns, setColumns] = useState( [
        { columnId: "name", width: 100, resizable: true },
        { columnId: "email", width: 150, resizable: true },
        { columnId: "subject", width: 250, resizable: true },
        { columnId: "message", width: 300, resizable: true },
        { columnId: "created_at", width: 100, resizable: true },
        { columnId: "action", width: 100 },
        { columnId: "status", width: 100 },
        { columnId: "reply", width: 100 }

    ]);
    
    console.log(props.data);
    //console.log(rows[1].cells[6].isOpen);
    const handleChanges = (changes) => {
        
        props.onSpreadSheetEventHandler(changes);
    };

    const handleColumnResize = (ci, width) => {

        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }
     
    return (
        
        <ReactGrid
            rows={props.data}
            columns={columns}
            onCellsChanged={handleChanges}
            onColumnResized={handleColumnResize}
            enableRangeSelection
        />
    );
};


export default SpreadSheet;
import * as React from 'react';
import ReplyIcon from '@mui/icons-material/Reply';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DataGridUI from '../UI/DataGridUI';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const ContactUSGrid = (props) => {

    const onHandleEdit = (row) => {
        props.onGridRowEdit(row);
    }
    const columns = [
         
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1
        },
        {
            field: 'email',
            headerName: 'Email',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.15
        },
        {
            field: 'subject',
            headerName: 'Subject',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.2,
            renderCell: (params) => {
                return (
                   
                    <React.Fragment>
                        <p data-html={true} data-tip={ReactDOMServer.renderToString(<div ><h6>Subject</h6>{params.formattedValue}</div>)}>
                            {params.formattedValue}
                        </p>
                        <ReactTooltip place="bottom" className="tooltip" multiline={true} />
                    </React.Fragment>
                );
            },
        },
        {
            field: 'message',
            headerName: 'Message',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.35,
            renderCell: (params) => {
                return (
                     
                    <React.Fragment>
                        <p data-html={true} data-tip={ReactDOMServer.renderToString(<div ><h6>Message</h6>{params.formattedValue }</div>)}>
                             {params.formattedValue}
                    </p>
                      
                        </React.Fragment>
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
            renderCell: (params) => {

                let ms = Date.parse(params.formattedValue);
                return (new Date(ms)).toLocaleDateString("en-US");
            },
        },

        {
            field: 'select_option',
            headerName: 'Status',
            type: 'singleSelect',
            valueOptions: ["Replied", "Spam", "Not Seen"],
            headerClassName: 'DataGridHeader',
            editable: true,
            flex: 0.1,
            renderCell: (params) => {
                let showIsSpam = true;
                let showDelete = true;
                let showReply = true;


                 
                if (params.row.is_replied)
                    return "Replied";
                else if (params.row.is_spam)
                    return "Spam";
                else
                    return "Not Seen";
                    
                
            }
        },
        {
            field: "action",
            headerName: "Actions",
            headerClassName: 'DataGridHeader',
            sortable: false,
            flex: 0.15,
            renderCell: (params) => {
                
                const onReplyClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();
                    props.onEditClick(params.id);
                    return "";

                };

                const onMarkAsSpam = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();

                    
                    props.onMarkAsSpam(params.id);
                    return "";

                };

                const onDelete = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    e.preventDefault();

                    
                    props.onDelete(params.id);
                    return "";
                }
                let showIsSpam = true;
                let showDelete = true;
                let showReply = true;


                return <div>
                    {!params.row.is_replied &&
                        <Tooltip title="Spam" placement="top-start">
                            <IconButton onClick={onMarkAsSpam}>
                                <DoNotDisturbAltIcon className="cursor" />&nbsp;
                            </IconButton>
                        </Tooltip>}
                    {!params.row.is_replied &&
                    <Tooltip title="Delete" placement="top-start">
                        <IconButton onClick={onDelete}>
                            <DeleteForeverIcon  />
                        </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Reply" placement="top-start">
                        <IconButton onClick={onReplyClick}>
                            <ReplyIcon  className="cursor" />
                        </IconButton>
                    </Tooltip>
                </div>;
            },

        },

    ];

    const onRowSelectionChanged = (ids) => {

        props.onRowSelection(ids);
    }
    return (
        <DataGridUI columns={columns} data={props.data} onRowSelection={onRowSelectionChanged} onHandleEditClick={onHandleEdit} />
       
    );
};

export default ContactUSGrid;